import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppDispatchProps, AppStateProps } from './types';
import AppC from '../../components/App';
import { AppState } from '../../types';
import {
  cartFailed as cartFailedAc,
  checkConnection as checkConnectionAC,
  fetchCart as fetchCartAc,
  toggleModalConnection as toggleModalConnectionAC,
  fetchGlobalCountries,
} from '../../actions';
import { getIsConnected, getAuthLoading } from '../../selectors';
import { getCartError } from '../../selectors/cart';
import Message from '../../components/common/Message';

interface AppProps extends AppDispatchProps, AppStateProps {}

const App: FunctionComponent<AppProps> = ({
  isConnected,
  authLoading,
  checkConnection,
  fetchCart,
  cartError,
  cartFailed,
  toggleModalConnection,
  fetchCountries,
  countryIsLoading,
}: AppProps) => {
  useEffect(() => {
    fetchCart();

    checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Global Cart Modal */}
      <Message
        error={
          cartError
            ? {
                errorMessage: cartError.error
                  ? cartError.error
                  : cartError.data?.message,
                resetError: () => cartFailed(null),
              }
            : null
        }
      />
      <AppC
        fetchCountries={fetchCountries}
        isConnected={isConnected}
        authLoading={authLoading}
        toggleModalConnection={toggleModalConnection}
        countryIsLoading={countryIsLoading}
      />
    </>
  );
};

/**
* App map state to props
*
* @param {Dispatch} dispatch
@returns {AppDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): AppDispatchProps => {
  return bindActionCreators(
    {
      fetchCart: fetchCartAc,
      checkConnection: checkConnectionAC,
      cartFailed: cartFailedAc,
      toggleModalConnection: toggleModalConnectionAC,
      fetchCountries: fetchGlobalCountries,
    },
    dispatch,
  );
};

/**
 * App map state to props
 *
 * @param {AppState} appState
 * @returns {AppStateProps}
 */
const mapStateToProps = (appState: AppState): AppStateProps => {
  return {
    isConnected: getIsConnected(appState),
    authLoading: getAuthLoading(appState),
    cartError: getCartError(appState),
    countryIsLoading: appState.globalCountries?.get('loading'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
