/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  DynamicComponentDispatchProps,
  DynamicComponentStateProps,
} from './types';
import { AppState } from '../../types';
import {
  addToCart as addToCartAc,
  fetchDynamicComponent as fetchDynamicComponentAC,
  resetDynamicComponent as resetDynamicComponentAC,
  resetDynamicComponentMessages as resetDynamicComponentMessagesAC,
} from '../../actions';
import {
  getDynamicComponentLoading,
  getDynamicComponentError,
  getEventComponent,
  getCategoryComponent,
  getProductComponent,
  getCampaignComponent,
  getThemeComponent,
  getTermComponent,
  getTopicComponent,
  getArticleComponent,
  getPageComponent,
} from '../../selectors/dynamic-component';
import Sport from '../../components/pages/Sport';
import Catalog from '../../components/pages/Catalog';
import Categorie from '../../components/pages/Categorie';
import SingleProduct from '../../components/pages/SingleProduct';
import { EventDetails } from '../event';
import MagazinePage from '../../components/pages/MagazinePage';
import MagazineDetailsPage from '../../components/pages/MagazineDetailsPage';
import LoadingPage from '../../components/common/LoadingPage';
import NoMatchedRoute from '../../components/pages/NoMatchedRoute';
import Page from '../../components/pages/Page';

interface DynamicComponentProps
  extends DynamicComponentDispatchProps,
    DynamicComponentStateProps {}

const DynamicComponent: FunctionComponent<DynamicComponentProps> = ({
  fetchDynamicComponent,
  resetDynamicComponent,
  event,
  category,
  product,
  campaign,
  theme,
  term,
  topic,
  page,
  article,
  addToCart,
  loading,
}: DynamicComponentProps) => {
  // const { url } = useParams<DynamicComponentParams>();
  const { pathname } = useLocation();

  useEffect(() => {
    const isStore = pathname.startsWith('/store/');
    const isMagazine = pathname.startsWith('/magazine/');
    const isPage =
      pathname.startsWith('/qui-sommes-nous') ||
      pathname.startsWith('/mentions-legales') ||
      pathname.startsWith('/privacy-policy');
    const subStringIndex = isStore ? 7 : 1;

    let type;
    if (isStore) type = 'store';
    else if (isMagazine) type = 'magazine';
    else if (isPage) type = 'page';
    else type = 'event';

    fetchDynamicComponent(pathname.substring(subStringIndex), type);
    return () => {
      resetDynamicComponent();
    };
  }, [pathname]);

  const renderedPage = () => {
    if (loading) return null;
    if (category) return <Sport category={category} />;
    if (event) return <EventDetails event={event} />;
    if (term) return <Catalog campaign={campaign} term={term} />;
    if (campaign) return <Catalog campaign={campaign} />;
    if (theme) return <Categorie theme={theme} />;
    if (product)
      return <SingleProduct product={product} addToCart={addToCart} />;
    if (topic) return <MagazinePage topic={topic} />;
    if (article) return <MagazineDetailsPage article={article} />;
    if (page) return <Page page={page} />;
    return <NoMatchedRoute />;
  };

  return (
    <>
      <LoadingPage loading={loading} />
      {renderedPage()}
    </>
  );
};

/**
* DynamicComponent map state to props
*
* @param {Dispatch} dispatch
@returns {DynamicComponentDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): DynamicComponentDispatchProps => {
  return bindActionCreators(
    {
      fetchDynamicComponent: fetchDynamicComponentAC,
      resetDynamicComponent: resetDynamicComponentAC,
      resetDynamicComponentMessages: resetDynamicComponentMessagesAC,
      addToCart: addToCartAc,
    },
    dispatch,
  );
};

/**
 * DynamicComponent map state to props
 *
 * @param {AppState} appState
 * @returns {DynamicComponentStateProps}
 */
const mapStateToProps = (appState: AppState): DynamicComponentStateProps => {
  return {
    loading: getDynamicComponentLoading(appState),
    error: getDynamicComponentError(appState),
    event: getEventComponent(appState),
    category: getCategoryComponent(appState),
    product: getProductComponent(appState),
    campaign: getCampaignComponent(appState),
    theme: getThemeComponent(appState),
    term: getTermComponent(appState),
    topic: getTopicComponent(appState),
    article: getArticleComponent(appState),
    page: getPageComponent(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicComponent);
