/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SignInForm, SignInFormData } from '../forms';
import { SocialButtons } from '../../containers';

interface LoginProps {
  onSubmit: (data: SignInFormData) => void;
  setIsForgotPassword: any;
}

const Login = ({ onSubmit, setIsForgotPassword }: LoginProps) => {
  return (
    <section>
      <h5 className="g-title mb-4">Connexion</h5>

      <SignInForm
        onSubmit={onSubmit}
        setIsForgotPassword={setIsForgotPassword}
      />

      <h5 className="g-title mt-4 mb-4">Ou essayez avec vos réseaux sociaux</h5>
      <SocialButtons />
    </section>
  );
};

export default Login;
