import { createSelector } from 'reselect';
import { List } from 'immutable';
import { AppState, CategoryState } from '../types';
import { SimpleFetchStateRecord } from '../helpers';
import { CategoriesResponseRecord, CategoryRecord } from '../models';
import { Error } from '../providers';

export const getCategoryState = (appState: AppState) => appState.category;

//* ************************* BEGIN EVENT CATEGORIES *************************** */

export const getEventCatagoriesState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<CategoriesResponseRecord>
>(
  [getCategoryState],
  (categoryState: CategoryState) => categoryState.eventCategoriesState,
);

export const getEventCategoriesLoading = createSelector<AppState, any, boolean>(
  [getEventCatagoriesState],
  (eventCategoriesState: SimpleFetchStateRecord<CategoriesResponseRecord>) =>
    eventCategoriesState.get('loading'),
);

export const getEventCategoriesError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getEventCatagoriesState],
  (eventCategoriesState: SimpleFetchStateRecord<CategoriesResponseRecord>) =>
    eventCategoriesState.get('error'),
);

export const getEventCategoriesList = createSelector<
  AppState,
  any,
  List<CategoryRecord> | undefined | null
>(
  [getEventCatagoriesState],
  (eventCategoriesState: SimpleFetchStateRecord<CategoriesResponseRecord>) =>
    eventCategoriesState.get('data')?.get('categories'),
);

//* ************************* END EVENT CATEGORIES *************************** */
