import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface CartConfirmationProps {
  instruction: string;
}

export default function CartConfirmation({
  instruction,
}: CartConfirmationProps) {
  const history = useHistory();

  useEffect(() => {
    if (!instruction) {
      history.replace('/panier');
    }
  }, [instruction]);

  if (!instruction) return null;

  return (
    <>
      <div className="cart cart-finalized">
        <div className="container">
          <h2 className="mb-5">Confirmation de votre commande</h2>
          <div className="box">
            <div
              className="text-body"
              dangerouslySetInnerHTML={{ __html: instruction }}
            />
            <div className="row mt-4">
              <div className="col-6">
                <Link to="/" className="btn green">
                  Continuer mes achats
                </Link>
              </div>
              <div className="col-6">
                <Link to="/mes-commandes" className="btn blue">
                  Voir mes commandes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
