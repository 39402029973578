import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ProfilDispatchProps, ProfilStateProps } from './types';
import ProfilC from '../../components/pages/Profil';
import { AppState } from '../../types';
import {
  fetchProfil as fetchProfilAC,
  resetProfilMessages as resetProfilMessagesAC,
  resetProfil as resetProfilAC,
} from '../../actions';
import { getCartLoadingPage, getUser } from '../../selectors';
import LoadingPage from '../../components/common/LoadingPage';

interface ProfilProps extends ProfilDispatchProps, ProfilStateProps {}

const Profil: FunctionComponent<ProfilProps> = ({
  fetchProfil,
  resetProfil,
  user,
  loading,
}: ProfilProps) => {
  useEffect(() => {
    fetchProfil();
    return () => {
      resetProfil();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingPage loading={loading} />
      <ProfilC user={user} />
    </>
  );
};

/**
* Profil map state to props
*
* @param {Dispatch} dispatch
@returns {ProfilDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): ProfilDispatchProps => {
  return bindActionCreators(
    {
      fetchProfil: fetchProfilAC,
      resetProfilMessages: resetProfilMessagesAC,
      resetProfil: resetProfilAC,
    },
    dispatch,
  );
};

/**
 * Profil map state to props
 *
 * @param {AppState} appState
 * @returns {ProfilStateProps}
 */
const mapStateToProps = (appState: AppState): ProfilStateProps => {
  return {
    user: getUser(appState),
    loading: getCartLoadingPage(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
