/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ForgotPassForm from '../forms/sign-in/forgot-pass';
import LoadingPopup from '../common/LoadingPopup';

interface ForgotPassCProps {
  onSubmit: (data: any) => void;
  loading: boolean;
}

const ForgotPassC = ({ onSubmit, loading }: ForgotPassCProps) => {
  return (
    <section className="forgot-password-modal">
      <LoadingPopup loading={loading} />

      <h5 className="g-title mb-4">Réinitialiser votre mot de passe</h5>
      <p>
        Saisissez votre adresse e-mail pour que nous puissions vous envoyer un
        lien permettant de réinitialiser votre mot de passe.
      </p>
      <ForgotPassForm onSubmit={onSubmit} />
    </section>
  );
};

export default ForgotPassC;
