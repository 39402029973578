/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useEffect } from 'react';
import Icon from 'react-remixicon';
import { Link } from 'react-router-dom';
import { AnyFielProps } from '../../../types';

interface InputRadioFieldProps extends AnyFielProps {
  data: any[];
  wrapAllClass?: string;
  wrapInputClass?: string;
}

const InputRadioField: FunctionComponent = ({
  input: { onChange, value },
  meta: { error, touched, valid },
  label,
  isRequired,
  className,
  data,
  wrapAllClass = '',
  wrapInputClass = '',
  ...rest
}: InputRadioFieldProps) => {
  useEffect(() => {
    if (data && data.length > 0) onChange(data[0].value);
  }, []);

  return (
    <div className={className || ''}>
      {label && (
        <label>
          {label} {!!isRequired && <span>*</span>}
        </label>
      )}
      <div className={`${wrapAllClass}`}>
        {data.map((dataValue, index) => (
          <div key={index}>
            <div className={`${wrapInputClass}`}>
              <label
                key={`radio-button-${dataValue.value}`}
                className="radio-container mr-3"
              >
                {dataValue.label}
                {dataValue.question && (
                  <Link to={dataValue.question}>
                    <Icon name="question" type="line" />
                  </Link>
                )}
                <input
                  type="radio"
                  id={`radio${index}`}
                  value={dataValue.value}
                  checked={value == dataValue.value}
                  onChange={onChange}
                  {...rest}
                />
                <span className="checkmark" />
              </label>
              {dataValue.secondLabel && <label>{dataValue.secondLabel}</label>}
            </div>
            {dataValue.description && value == dataValue.value && (
              <div
                className="hint"
                dangerouslySetInnerHTML={{ __html: dataValue.description }}
              />
            )}
          </div>
        ))}
      </div>
      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
    </div>
  );
};

export default InputRadioField;
