import { createSelector } from 'reselect';
import { List } from 'immutable';
import { AppState, PaymentMethod } from '../types';
import { SimpleFetchStateRecord } from '../helpers';
import {
  CartRecord,
  CartItemRecord,
  CartManagementRecord,
  CartDataRecord,
} from '../models';
import { Error } from '../providers';

export const getCartState = (appState: AppState) => appState.cart;

//* ************************* BEGIN CART DATA STATE *************************** */

export const getCartDataState = createSelector<AppState, any, CartDataRecord>(
  [getCartState],
  (cart: any) => {
    return cart.cartDataState;
  },
);

export const getCart = createSelector<
  AppState,
  any,
  CartRecord | null | undefined
>([getCartDataState], (cartDataState: CartDataRecord) =>
  cartDataState.get('cart'),
);

export const getCartItems = createSelector<
  AppState,
  any,
  List<CartItemRecord> | null | undefined
>([getCart], (cart?: CartRecord | null) => cart?.get('items'));

export const getDiscount = createSelector<
  AppState,
  any,
  List<CartItemRecord> | null | undefined
>([getCart], (cart?: CartRecord | null) => cart?.get('discount'));

export const getCountries = createSelector<
  AppState,
  any,
  List<any> | null | undefined
>([getCartDataState], (cart?: CartDataRecord | null) => cart?.get('countries'));

export const getCities = createSelector<
  AppState,
  any,
  List<any> | null | undefined
>([getCartDataState], (cart?: CartDataRecord | null) => cart?.get('cities'));

export const getCartLoading = createSelector<AppState, any, boolean>(
  [getCartDataState],
  (cartDataState: CartDataRecord) => {
    return cartDataState.get('loading');
  },
);

export const getCartLoadingPage = createSelector<AppState, any, boolean>(
  [getCartDataState],
  (cartDataState: CartDataRecord) => {
    return cartDataState.get('loadingPage');
  },
);

export const getCartSuccessMessage = createSelector<AppState, any, boolean>(
  [getCartDataState],
  (cartDataState: CartDataRecord) => cartDataState.get('successMessage'),
);

export const getCartError = createSelector<AppState, any, Error | null>(
  [getCartDataState],
  (cartDataState: CartDataRecord) => cartDataState.get('error'),
);

export const getSelectedPaymentMehodById = (id: number) =>
  createSelector<AppState, any, PaymentMethod | null | undefined>(
    [getCart],
    (cart?: CartRecord | null) =>
      cart?.get('paymentMethods')?.find(item => item.id === id),
  );

//* ************************* END CART DATA STATE *************************** */

//* ************************* BEGIN CART MANAGEMENT *************************** */

export const getCartManagement = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<CartManagementRecord>
>([getCartState], (cartState: any) => cartState.cartManagement);

export const getCartSelectedAddressId = createSelector<AppState, any, any>(
  [getCartManagement],
  (cart?: CartManagementRecord | null) => cart?.get('selectedAddressId'),
);

export const getCartPaymentInfo = createSelector<AppState, any, any>(
  [getCartManagement],
  (cart?: CartManagementRecord | null) => cart?.get('paymentInfo'),
);

export const getCartInstruction = createSelector<AppState, any, any>(
  [getCartManagement],
  (cart?: CartManagementRecord | null) => cart?.get('instruction'),
);

export const getCartSelectedCarrierId = createSelector<AppState, any, any>(
  [getCartManagement],
  (cart?: CartManagementRecord | null) => cart?.get('selectedCarrierId'),
);

export const getCartSelectedPaymentId = createSelector<AppState, any, any>(
  [getCartManagement],
  (cart?: CartManagementRecord | null) => cart?.get('selectedPaymentId'),
);

//* ************************* END CART MANAGEMENT *************************** */
