import { ActionCreator } from 'redux';
import { dynamicComponentActionsKeys, DynamicComponentType } from '../types';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes, DynamicComponentParams } from '../providers';

/**
 * Fetch DynamicComponent action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchDynamicComponent: ActionCreator<SimpleActions> = (
  url: string,
  type?: DynamicComponentType,
  params?: any,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.dynamicComponent,
    callGlobalError: false,
    method: 'GET',
    params: {
      url,
      type,
      ...params,
    } as DynamicComponentParams,
  };
  return simpleFetchRequest(
    dynamicComponentActionsKeys.DYNAMIC_COMPONENT,
    confiApi,
  );
};

/**
 * Reset DynamicComponent action creator
 *
 * @returns {SimpleActions}
 */
export const resetDynamicComponent: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchReset(dynamicComponentActionsKeys.DYNAMIC_COMPONENT);

/**
 * Reset DynamicComponent messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetDynamicComponentMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(dynamicComponentActionsKeys.DYNAMIC_COMPONENT);
