import React, { useEffect, useRef, useState } from 'react';
import Icon from 'react-remixicon';

interface SelectBoxI {
  items: any[];
  handleSelect?: (value: any) => void;
  selectedIndex?: number;
}

const SelectBox = ({ items, handleSelect, selectedIndex }: SelectBoxI) => {
  const selectBoxRef = useRef(null);
  const [showItems, setShowItems] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(items[0]);

  useEffect(() => {
    if (selectedIndex) setSelectedItem(items[selectedIndex]);
  }, [selectedIndex, items]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target))
        setShowItems(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectBoxRef]);

  if (!items) return null;

  const dropDown = () => {
    setShowItems(!showItems);
  };

  const selectItem = item => {
    if (!item.value) return;
    if (handleSelect) handleSelect(item);
    setSelectedItem(item);
    setShowItems(false);
  };

  return (
    <div ref={selectBoxRef} className="select-box--box" onClick={dropDown}>
      <div className="select-box--container">
        <div className="select-box--selected-item">
          {selectedItem?.label}
          {selectedItem?.label2 && (
            <span>
              {selectedItem?.oldPrice && (
                <span className="offer-old-price">
                  {selectedItem?.oldPrice}
                </span>
              )}
              {selectedItem?.label2}
            </span>
          )}
        </div>
        <div className="select-box--arrow">
          <span>
            {showItems && <Icon name="arrow-up-s" type="line" />}
            {!showItems && <Icon name="arrow-down-s" type="line" />}
          </span>
        </div>

        <div className={`select-box--items ${showItems ? 'shown' : 'hidden'}`}>
          {items.map((item, i) => (
            <div
              key={i}
              onClick={() => selectItem(item)}
              className={`${selectedItem === item ? 'selected' : ''} ${
                !item.value ? 'disabled' : ''
              }`}
            >
              {item.label}
              {item.label2 && (
                <span>
                  {item?.oldPrice && (
                    <span className="offer-old-price">{item?.oldPrice}</span>
                  )}
                  {item.label2}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
