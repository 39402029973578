import { reduxForm, FormErrors } from 'redux-form';
import { connect } from 'react-redux';
import { ContactFormData } from '../types';
import { formsNames, fieldErrors } from '../constants';
import FormView from './view';
import { regularExpresions } from '../../../helpers';
import { AppState } from '../../../types';
import { getContactLoading, getIsConnected, getUser } from '../../../selectors';

const validate = ({
  email,
  firstName,
  lastName,
  message,
  subject,
}: ContactFormData): FormErrors<ContactFormData> => {
  const errors: FormErrors<ContactFormData> = {};

  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  if (!firstName || firstName.trim() === '') {
    errors.firstName = fieldErrors.REQUIRED;
  }
  if (!lastName || lastName.trim() === '') {
    errors.lastName = fieldErrors.REQUIRED;
  }
  if (!message || message.trim() === '') {
    errors.message = fieldErrors.REQUIRED;
  }
  if (!subject || subject.trim() === '') {
    errors.subject = fieldErrors.REQUIRED;
  }

  return errors;
};

const mapStateToProps = (appState: AppState) => {
  return {
    user: getUser(appState),
    isConnected: getIsConnected(appState),
    loading: getContactLoading(appState),
  };
};

const ContactForm = reduxForm<ContactFormData>({
  form: formsNames.CONTACT,
  validate,
})(connect(mapStateToProps)(FormView));

export default ContactForm;
