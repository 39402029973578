/* eslint-disable react/no-danger */
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getStorageData } from '../../helpers';
import { CartRecord } from '../../models/cart';
import LoadingPopup from '../common/LoadingPopup';
import ComposedForm from '../forms/cart/index';
import { PaymentMethod } from '../../types';

interface CartProps {
  cart: CartRecord | null;
  checkoutAc: any;
  itemsField: any;
  payment_method_id: any;
  orderCheck: (responseCode: number, orderId: string) => void;
  submitForm: (formName: string) => void;
  paymentInfo: any;
  paymentMethod: PaymentMethod;
  loading: boolean;
  loadingPage: boolean;
  isConnected: boolean;
}

const Cart = ({
  cart,
  checkoutAc,
  itemsField,
  payment_method_id,
  orderCheck,
  submitForm,
  paymentInfo,
  paymentMethod,
  loading,
  loadingPage,

  isConnected,
}: CartProps) => {
  const { search } = useLocation();

  const constructCheckoutItems = (fieldItems, cartItems) => {
    // benificiaries
    let items = {};
    if (fieldItems && cartItems) {
      fieldItems.forEach((item, index) => {
        items = {
          ...items,
          [cartItems[index].itemId]: {
            beneficiaries:
              item.apply === 'yes' && item.benefits.length > 0
                ? [item.benefits[0]]
                : item.benefits,
            apply: item.apply === 'yes',
          },
        };
      });
    }
    return items;
  };

  const checkoutOrders = () => {
    // checkout
    checkoutAc(
      constructCheckoutItems(itemsField, cart?.items),
      Number.parseFloat(payment_method_id),
      getStorageData('checkoutReference') || null,
      isConnected,
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const RESPONSE_CODE = Number(params.get('RESPONSE_CODE'));
    const ORDER_ID = params.get('ORDER_ID');

    if (RESPONSE_CODE !== null && ORDER_ID !== null) {
      orderCheck(RESPONSE_CODE, ORDER_ID);
    }
  }, [orderCheck, search]);

  if (loadingPage) return null;

  if (cart?.count === 0)
    return (
      <>
        <div className="cart">
          <div className="container">
            <div className="box">
              <div className="heading">
                <h4>Panier est vide</h4>
              </div>
              <p>
                Votre panier est vide. <Link to="/">Continuer mes achats</Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="cart">
        <LoadingPopup loading={loading} />
        {/* FPay checkout */}
        {/* <form
          id="redirectForm"
          method="post"
          name="myform"
          action={paymentInfo?.fpayUrl ? atob(paymentInfo?.fpayUrl) : null}
        >
          <input type="hidden" name="ORDER_ID" value={paymentInfo?.orderId} />
          <input
            type="hidden"
            name="REFERENCE_ID"
            value={paymentInfo?.referenceId}
          />
          <input type="hidden" name="TRACK_ID" value={paymentInfo?.trackId} />
        </form> */}
        {paymentInfo && paymentMethod?.module === 'fpay' && (
          <FPayPaymentForm paymentInfo={paymentInfo} />
        )}
        {paymentInfo && paymentMethod?.module === 'cmi' && (
          <CMIPaymentForm paymentInfo={paymentInfo} />
        )}
        {paymentInfo && paymentMethod?.module === 'touch' && (
          <TouchPayForm paymentInfo={paymentInfo} />
        )}

        <ComposedForm onSubmit={() => checkoutOrders()} />
      </div>
    </>
  );
};

export default Cart;

interface PaymentFormProps {
  paymentInfo?: any;
}

const FPayPaymentForm = (props: PaymentFormProps) => {
  const { paymentInfo } = props;

  return (
    <form
      id="redirectForm"
      method="post"
      name="myform"
      action={paymentInfo?.fpayUrl ? atob(paymentInfo?.fpayUrl) : null}
    >
      <input type="hidden" name="ORDER_ID" value={paymentInfo?.orderId} />
      <input
        type="hidden"
        name="REFERENCE_ID"
        value={paymentInfo?.referenceId}
      />
      <input type="hidden" name="TRACK_ID" value={paymentInfo?.trackId} />
    </form>
  );
};

const CMIPaymentForm = (props: PaymentFormProps) => {
  const { paymentInfo } = props;

  const fieldsObj = useMemo(() => {
    const keys = Object.keys(paymentInfo);
    const values = Object.values(paymentInfo);
    const arr: any = values.map((value, index) => ({
      name: keys[index],
      value,
    }));
    return {
      fields: arr.filter(field => field.name !== 'gateway'),
      action: arr.find(field => field.name === 'gateway').value,
    };
  }, [paymentInfo]);

  return (
    <form
      id="redirectForm"
      method="post"
      name="myform"
      action={fieldsObj?.action}
    >
      {fieldsObj?.fields?.map(field => (
        <input
          type="hidden"
          name={field.name}
          value={field.value}
          key={`payment-form-field-${field.name}`}
        />
      ))}
    </form>
  );
};

const TouchPayForm = (props: PaymentFormProps) => {
  const { paymentInfo } = props;
  const scriptElement = document.createElement('script');

  useEffect(() => {
    if (paymentInfo) {
      const paymentScript = `<script src="${paymentInfo?.gateway}" type="text/javascript"></script><script type="text/javascript">function calltouchpay(){ sendPaymentInfos("${paymentInfo?.reference}","${paymentInfo?.agencyCode}","${paymentInfo?.secureCode}","${paymentInfo?.domainName}","${paymentInfo?.urlRedirectionSuccess}","${paymentInfo?.urlRedirectionFailed}","${paymentInfo?.amount}","${paymentInfo?.city}","${paymentInfo?.email}","${paymentInfo?.clientFirstName}","${paymentInfo?.clientLastName}","${paymentInfo?.clientPhone}"); } calltouchpay();</script>`;
      scriptElement.innerHTML = paymentScript;
      document.head.appendChild(scriptElement);
      // const paymentCdnScript = `<script src=${paymentInfo?.gateway} type="text/javascript"></script>`;
    }

    return () => {
      if (scriptElement) document.head.removeChild(scriptElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo]);

  return <div />;
};
