import { createSelector } from 'reselect';
import { AppState, AuthenticationState, ConnectionStateRecord } from '../types';
import { UserRecord } from '../models';
import { SimpleFetchStateRecord, GlobalResponseRecord } from '../helpers';
import { Error } from '../providers';

/**
 * Get Authentication state
 *
 * @param {AppState} state
 */
export const getAuthentication = (state: AppState): AuthenticationState =>
  state.authentication;

//* *********************  BEGIN CONNECTION STATE  ****************** */

export const getConnection = createSelector<
  AppState,
  any,
  ConnectionStateRecord
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.connection,
);

export const getToken = createSelector<
  AppState,
  any,
  string | undefined | null
>([getConnection], (connection: ConnectionStateRecord) =>
  connection.get('token'),
);

export const getUser = createSelector<AppState, any, UserRecord | null>(
  [getConnection],
  (connection: ConnectionStateRecord) => connection.get('user'),
);

export const getIsConnected = createSelector<AppState, any, boolean>(
  [getConnection],
  (connection: ConnectionStateRecord) => connection.get('isConnected'),
);

export const getAuthLoading = createSelector<AppState, any, boolean>(
  [getConnection],
  (connection: ConnectionStateRecord) => connection.get('authLoading'),
);

export const getConnectionModalIsVisible = createSelector<
  AppState,
  any,
  boolean
>([getConnection], (connection: ConnectionStateRecord) =>
  connection.get('modalIsVisible'),
);

//* *********************  END CONNECTION STATE  ****************** */

//* *********************  BEGIN SIGN STATE  ****************** */

export const getSignInState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.signIn,
);

export const getSignInSuccess = createSelector<
  AppState,
  any,
  boolean | undefined
>([getSignInState], (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
  signIn.get('data')?.get('success'),
);

export const getSignInError = createSelector<AppState, any, Error | null>(
  [getSignInState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('error') || null,
);

export const getSignInLoading = createSelector<AppState, any, boolean>(
  [getSignInState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('loading'),
);

//* ********************* END SIGN IN STATE  ****************** */

//* *********************  BEGIN FORGOT PASSWORD STATE  ****************** */

export const getForgotPasswordState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.forgotPassword,
);

export const getForgotPasswordSuccess = createSelector<
  AppState,
  any,
  string | undefined
>(
  [getForgotPasswordState],
  (forgotPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    forgotPass.get('successMessage'),
);

export const getForgotPasswordError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getForgotPasswordState],
  (forgotPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    forgotPass.get('error') || null,
);

export const getForgotPasswordLoading = createSelector<AppState, any, boolean>(
  [getForgotPasswordState],
  (forgotPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    forgotPass.get('loading'),
);

//* ********************* END FORGOT PASSWORD STATE  ****************** */

//* *********************  BEGIN RECOVER PASSWORD STATE  ****************** */

export const getRecoverPasswordState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.recoverPassword,
);

export const getRecoverPasswordSuccess = createSelector<
  AppState,
  any,
  string | undefined
>(
  [getRecoverPasswordState],
  (recoverPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    recoverPass.get('successMessage'),
);

export const getRecoverPasswordError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getRecoverPasswordState],
  (recoverPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    recoverPass.get('error') || null,
);

export const getRecoverPasswordLoading = createSelector<AppState, any, boolean>(
  [getRecoverPasswordState],
  (recoverPass: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    recoverPass.get('loading'),
);

//* ********************* END RECOVER PASSWORD STATE  ****************** */

//* *********************  BEGIN SIGN UP IN STATE  ****************** */

export const getSignUpState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.signUp,
);

export const getSignUpSuccess = createSelector<
  AppState,
  any,
  boolean | undefined
>([getSignUpState], (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
  signIn.get('data')?.get('success'),
);

export const getSignUpError = createSelector<AppState, any, Error | null>(
  [getSignUpState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('error') || null,
);

export const getSignUpLoading = createSelector<AppState, any, boolean>(
  [getSignUpState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('loading'),
);

//* *********************  END SIGN UP STATE  ****************** */

//* *********************  BEGIN SOCIAL SIGN IN  STATE  ****************** */

export const getSocialSignInState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>(
  [getAuthentication],
  (authentication: AuthenticationState) => authentication.socialSignIn,
);

export const getSocialSignInSuccess = createSelector<
  AppState,
  any,
  boolean | undefined
>(
  [getSocialSignInState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('data')?.get('success'),
);

export const getSocialSignInError = createSelector<AppState, any, Error | null>(
  [getSocialSignInState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('error') || null,
);

export const getSocialSignInLoading = createSelector<AppState, any, boolean>(
  [getSocialSignInState],
  (signIn: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    signIn.get('loading'),
);

//* *********************  END SIGN IN STATE  ****************** */

export const getProfilUpdateError = createSelector<AppState, any, Error | null>(
  [getSocialSignInState],
  (profilUpdate: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    profilUpdate.get('error') || null,
);

export const getProfilUpdateLoading = createSelector<AppState, any, boolean>(
  [getSocialSignInState],
  (profilUpdate: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    profilUpdate.get('loading'),
);
