import { reduxForm, FormErrors } from 'redux-form';
import { ProfilUpdateFormData, ProfilUpdateFormProps } from '../types';
import { formsNames, fieldErrors } from '../constants';
import FormView from './view';
import { regularExpresions } from '../../../helpers';

const validate = ({
  email,
  phone,
  firstName,
  lastName,
}: ProfilUpdateFormData): FormErrors<ProfilUpdateFormData> => {
  const errors: FormErrors<ProfilUpdateFormData> = {};
  if (!email || email.trim() === '') {
    errors.email = fieldErrors.REQUIRED;
  } else if (!regularExpresions.EMAIL.test(email)) {
    errors.email = fieldErrors.EMAIL;
  }
  if (!phone || phone.trim() === '') {
    errors.phone = fieldErrors.REQUIRED;
  } else if (!regularExpresions.PHONE.test(phone)) {
    errors.phone = fieldErrors.PHONE;
  }
  if (!firstName || firstName.trim() === '') {
    errors.firstName = fieldErrors.REQUIRED;
  }
  if (!lastName || lastName.trim() === '') {
    errors.lastName = fieldErrors.REQUIRED;
  }

  return errors;
};

const ProfilUpdateForm = reduxForm<ProfilUpdateFormData, ProfilUpdateFormProps>(
  {
    form: formsNames.PROFIL_UPDATE,
    validate,
  },
)(FormView);

export default ProfilUpdateForm;
