import React from 'react';
import { Field } from 'redux-form';
import { InputTextField } from '../../common/fields';
import LoadingPopup from '../../common/LoadingPopup';
import { RepaymentFormProps } from '../types';

interface RepaymentViewProps extends RepaymentFormProps {
  handleSubmit: (formData: any) => void;
}

const RepaymentFormView = ({
  handleSubmit,
  isVisible,
  onClose,
  loading,
}: RepaymentViewProps) => {
  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit}
        className={`address-modal modal ${isVisible}`}
      >
        <div className="back" onClick={onClose} />
        <div className="my-container small-container">
          <section>
            <LoadingPopup loading={loading} />
            <p>
              Veuillez prévoir au moins 20 jours pour que votre remboursement
              soit traité. Aucun prélèvement des frais bancaires ne sera retenu
              sur votre remboursement, Vous recevrez le montant qui correspond à
              la valeur nominale de vos billets. Si vous avez d'autres questions
              concernant votre commande, veuillez contacter{' '}
              <span style={{ color: '#57a8dc', fontWeight: 'bold' }}>
                sav@guichet.ma
              </span>
            </p>
            <div className="row">
              <Field
                name="amount"
                component={InputTextField}
                type="text"
                placeholder="Montant"
                className="col-sm-12 col-12 mt-2"
              />
              <Field
                name="fullName"
                component={InputTextField}
                type="text"
                placeholder="Nom complet"
                className="col-sm-12 col-12 mt-3"
              />
              {/* <Field
                name="rib"
                component={InputTextField}
                type="text"
                placeholder="RIB"
                className="col-sm-12 col-12 mt-3"
              /> */}
              <Field
                name="phone"
                component={InputTextField}
                type="text"
                placeholder="Numero de téléphone"
                className="col-sm-12 col-12 mt-3"
              />
              <Field
                name="comment"
                component={InputTextField}
                type="text"
                placeholder="Votre commentaire"
                className="col-sm-12 col-12 mt-3"
              />
            </div>
            <div className="mt-4">
              <button type="submit" className="green">
                Envoyer ma demande
              </button>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default RepaymentFormView;
