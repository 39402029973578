import { ActionCreator } from 'redux';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ContactFormData } from '../components/forms';
import { ConfigCallApi, ApiRoutes, ContactPostData } from '../providers';
import { appActionKeys } from '../types';

export const submitContact: ActionCreator<SimpleActions> = ({
  firstName,
  lastName,
  email,
  phone,
  message,
  subject,
}: ContactFormData): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.cantact,
    callGlobalError: false,
    method: 'post',
    postData: {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
      message,
      subject,
    } as ContactPostData,
    successCallback: () => {
      window.fbq('track', 'Contact');
    },
  };
  return simpleFetchRequest(appActionKeys.CONTACT, confiApi);
};

export const resetContact: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(appActionKeys.CONTACT);

export const resetContactMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(appActionKeys.CONTACT);
