import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'react-remixicon';
import { CampaignRecord } from '../models';

interface StorePrProps {
  campaign: CampaignRecord;
}

const StorePr = ({ campaign }: StorePrProps) => {
  const [isDetailsCollapsed, setDetailsCollapsed] = useState(false);

  const url = `/store/${campaign.get('slug')}`;

  return (
    <div className="store-pr">
      <div className={`details ${isDetailsCollapsed}`}>
        <div className="top">
          <div>
            <Link className="date" to={url}>
              Jusqu'au <b>{campaign.get('startAt')}</b>
            </Link>
            <Link className="sub" to={url}>
              {campaign.get('title')}
            </Link>
          </div>
          <button
            className={`btn close-btn ${isDetailsCollapsed}`}
            onClick={() => setDetailsCollapsed(!isDetailsCollapsed)}
          >
            <Icon name="add" type="line" />
          </button>
        </div>
        {!!campaign.get('description') && (
          <Link className="desc" to={url}>
            {campaign.get('description')}
          </Link>
        )}

        <div className="bottom">
          <Link to={url} className="btn">
            <Icon name="arrow-right" type="line" />
          </Link>
        </div>
      </div>

      <Link to={url}>
        <img src={campaign.get('visual')} alt={campaign.get('title')} />
      </Link>
    </div>
  );
};

export default StorePr;
