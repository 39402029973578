/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../types';
import { PreviewEventDispatchProps, PreviewEventStateProps } from './types';
import EventDetailsC from '../../components/pages/Event';
import {
  addToCart as addToCartAc,
  fetchPreviewEvent as fetchPreviewEventAc,
  destroyCart as destroyCartAC,
} from '../../actions';
import { getEventData, getEventError, getEventLoading } from '../../selectors';
import LoadingPage from '../../components/common/LoadingPage';
import AppMeta from '../../components/common/AppMeta';

interface PreviewEventProps
  extends PreviewEventDispatchProps,
    PreviewEventStateProps {}

const PreviewEvent: FunctionComponent<PreviewEventProps> = ({
  addToCart,
  fetchPreviewEvent,
  eventDetails,
  loading,
  error,
  destroyCart,
}: PreviewEventProps) => {
  const { id: eventId } = useParams<any>();

  useEffect(() => {
    fetchPreviewEvent(eventId);
  }, []);

  return (
    <>
      <LoadingPage loading={loading} />
      <AppMeta
        title={eventDetails?.get('metaTitle')}
        description={eventDetails?.get('metaDescription')}
        keywords={eventDetails?.get('metaKeywords')}
        hideFromRobots
      />
      <EventDetailsC
        addToCart={addToCart}
        event={eventDetails}
        deleteSeat={null}
        loading={loading}
        error={error}
        destroyCart={destroyCart}
      />
    </>
  );
};

/**
* PreviewEvent map state to props
*
* @param {Dispatch} dispatch
@returns {PreviewEventDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): PreviewEventDispatchProps => {
  return bindActionCreators(
    {
      addToCart: addToCartAc,
      fetchPreviewEvent: fetchPreviewEventAc,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

/**
 * PreviewEvent map state to props
 *
 * @param {AppState} appState
 * @returns {PreviewEventStateProps}
 */
const mapStateToProps = (appState: AppState): PreviewEventStateProps => {
  return {
    eventDetails: getEventData(appState),
    loading: getEventLoading(appState),
    error: getEventError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewEvent);
