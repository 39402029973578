import React from 'react';
import Icon from 'react-remixicon';

interface ShareBlockProps {
  title: string;
  blockTitle: string;
  light?: boolean;
}

export default function ShareBlock({
  title,
  blockTitle,
  light = false,
}: ShareBlockProps) {
  // share links
  const shareFacebook = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&title=${title}`;
  const shareTwitter = `https://twitter.com/intent/tweet?url=${window.location.href}&text=${title}`;
  const sharePinterest = `https://pinterest.com/pin/create/button/?url=${window.location.href}&description=${title}`;
  const shareLinkedin = `https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${title}&mini=true`;
  const shareGoogle = `https://plus.google.com/share?url=${window.location.href}`;

  return (
    <>
      <h6 className="text-center mb-3">{blockTitle}</h6>

      <div
        className={`sm-links d-flex justify-content-center ${
          light ? 'light' : null
        }`}
      >
        <a
          href={shareFacebook}
          className="facebook"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="facebook" type="fill" />
        </a>
        <a
          href={shareGoogle}
          className="google"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="google" type="fill" />
        </a>
        <a
          href={sharePinterest}
          className="pinterest"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="pinterest" type="fill" />
        </a>
        <a
          href={shareLinkedin}
          className="linkedin"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="linkedin" type="fill" />
        </a>
        <a
          href={shareTwitter}
          className="twitter"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="twitter" type="line" />
        </a>
      </div>
    </>
  );
}
