/* eslint-disable quotes */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { EventRecord } from '../models';
import { formatPrice, getCurrentCurrency } from '../helpers';
import CountDown from './CountDown';

interface CardProps {
  event: EventRecord;
}

const Card = ({ event }: CardProps) => {
  const category = event.get('category');
  const place = event.get('place');

  const url = event.get('isPrivate')
    ? `events/private/${event.get('token')}`
    : event.get('slug');

  return (
    <div className="g-card">
      <div className="img-container">
        {event.get('sticker') && (
          <span className="g-card-status">{event.get('sticker')}</span>
        )}
        <Link to={`/${url}`}>
          <img src={event.get('cover')} alt={event.get('title')} />
        </Link>
      </div>
      <div className="g-card-desc">
        {!!category && (
          <div className="mb-3">
            <span className="g-category">{category.get('title')}</span>
          </div>
        )}
        <h3 className="g-title">
          <Link to={`/${url}`}>{event.get('title')}</Link>
        </h3>
        <p className="g-address">
          <strong>{place?.address?.city}</strong>, {place?.address?.address}
        </p>

        <CountDown
          date={moment(event.get('expiredAt'), 'DD/MM/YYYY HH:mm:ss').format(
            'YYYY-MM-DD HH:mm:ss',
          )}
          isSmall
          handleSoldOut={() => {
            //
          }}
        />

        <div className="d-flex justify-content-between g-price-container">
          <div className="g-price">
            <label>À partir de :</label>
            <p>
              {formatPrice(event.get('price'), true)}
              <span>{getCurrentCurrency()}</span>
            </p>
            {!!event.get('oldPrice') && (
              <span className="event-item-old-price">
                {formatPrice(event.get('oldPrice'))}
              </span>
            )}
          </div>
          <Link
            to={`/${url}`}
            className={!event.get('soldOut') ? 'g-btn-orange' : 'g-btn-grey'}
          >
            {(event.get('isSubscription') && 'Inscription') ||
              (event.get('soldOut') && 'Guichet Fermé') ||
              "J'achète"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
