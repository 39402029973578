/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { connect } from 'react-redux';
import Icon from 'react-remixicon';
import moment from 'moment';
import { formatPrice } from '../helpers';
import { UserRecord } from '../models';
import { ApiRoutes, callApi, Error } from '../providers';
import { getUser } from '../selectors';
import { AppState, Transaction } from '../types';
import AppMeta from './common/AppMeta';
import LoadingPage from './common/LoadingPage';
import Message from './common/Message';
import { RepaymentForm, RepaymentFormData } from './forms';

export const SOLDE_QUERY_KEY = 'solde';

export const REPAYMENT_QUERY_KEY = 'repayment';

interface SoldePageProps {
  user?: UserRecord | null;
}

const SoldePage: FunctionComponent<SoldePageProps> = (
  props: SoldePageProps,
) => {
  const { user } = props;

  const [repaymentIsVisible, setRepaymentVisibility] = useState<boolean>(false);

  const { data, isLoading, refetch } = useQuery<
    { transactions: Transaction[]; balance: number },
    Error
  >(SOLDE_QUERY_KEY, async () => {
    const response: any = await callApi({
      url: ApiRoutes.solde,
      secure: true,
    });
    if (response.status) {
      throw response;
    }
    return response;
  });

  const callRepayment = async (values: RepaymentFormData) => {
    const response: any = await callApi({
      url: ApiRoutes.repayment,
      secure: true,
      method: 'post',
      postData: {
        amount: values.amount,
        full_name: values.fullName,
        comment: values.comment,
        phone: values.phone,
      },
    });
    if (response.status) {
      throw response;
    }
    return response;
  };

  const mutation = useMutation<{ message: string }, Error, RepaymentFormData>(
    REPAYMENT_QUERY_KEY,
    callRepayment,
    {
      onSuccess: () => {
        setRepaymentVisibility(false);
        refetch();
      },
    },
  );

  return (
    <>
      <AppMeta title="Solde" prefix="" />
      <LoadingPage loading={isLoading} />
      <Message
        success={
          mutation.data?.message
            ? {
                successMessage: mutation.data?.message,
                resetSuccess: () => mutation.reset(),
              }
            : null
        }
        error={
          mutation.isError
            ? {
                errorMessage: mutation.error?.error,
                resetError: () => mutation.reset(),
              }
            : null
        }
      />

      <RepaymentForm
        isVisible={repaymentIsVisible}
        onClose={() => setRepaymentVisibility(false)}
        onSubmit={formValues => mutation.mutate(formValues)}
        loading={mutation.isLoading}
      />
      <section className="mes-commandes-page">
        <div className="container">
          <div className="box mb-3">
            {data?.transactions && (
              <>
                <div className="heading mb-0 d-flex justify-content-between align-items-center">
                  <h4>{formatPrice(data?.balance)}</h4>

                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      borderRadius: 5,
                    }}
                    onClick={() => setRepaymentVisibility(true)}
                  >
                    Demander un remboursement
                  </button>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" />
                      <th scope="col">Référence</th>
                      <th scope="col">Date</th>
                      <th scope="col">Total</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {data?.transactions.map(transaction => (
                      <tr key={transaction.id}>
                        <td>{transaction.title}</td>
                        <td>{transaction.reference}</td>
                        <td>
                          {moment(
                            transaction.paymentTransactionDate,
                            'DD/MM/YYYY HH:mm',
                          ).format('ddd DD MMMM YYYY \n HH:mm')}
                        </td>
                        <td>{formatPrice(transaction.amount)}</td>
                        <td>
                          <Icon
                            name={`arrow-${
                              transaction.type === 0 ? 'up' : 'down'
                            }`}
                            type="line"
                            style={{
                              color: transaction.type === 0 ? 'red' : 'green',
                              fontSize: 20,
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (appState: AppState): SoldePageProps => {
  return {
    user: getUser(appState),
  };
};

export default connect(mapStateToProps, null)(SoldePage);
