/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'redux';
import { Record } from 'immutable';
import { handleActions, Action } from 'redux-actions';
import {
  SimpleListingStateRecord,
  SerializeSimpleFetchCallBack,
  SimpleListingState,
  SimpleListWithPaginationRecord,
  SimpleFetchStore,
} from './types';
import { createSimpleListingAction } from './utils';
import { SimpleListingSuccessPayload } from './actions-types';

const KEY_REGEX = /^[a-z]+(_[a-z]+)*$/i;

/**
 * Create simple listing stores
 *
 * @template LISTITEM
 * @param {string} key
 * @param {SerializeSimpleDataCallBack} [serializeData]
 * @returns {SimpleFetchStore}
 */
const simpleListingStore = <LISTITEM>(
  key: string,
  serializeData?: SerializeSimpleFetchCallBack,
): SimpleFetchStore => {
  if (!(typeof key === 'string' && KEY_REGEX.test(key))) {
    throw new Error(`Cannot use invalid key: '${key}'.`);
  }

  const KEY = key.toUpperCase();

  const reset = createSimpleListingAction<void>(KEY, 'RESET');
  const resetMessage = createSimpleListingAction<void>(KEY, 'RESET_MESSAGES');
  const loadingStart = createSimpleListingAction<void>(KEY, 'LOADING_START');
  const loadingEnd = createSimpleListingAction<void>(KEY, 'LOADING_END');
  const refreshingStart = createSimpleListingAction<void>(
    KEY,
    'REFRESHING_START',
  );
  const refreshingEnd = createSimpleListingAction<void>(KEY, 'REFRESHING_END');
  const infinitLoadingStart = createSimpleListingAction<void>(
    KEY,
    'INFINIT_LOADING_START',
  );
  const infinitLoadingEnd = createSimpleListingAction<void>(
    KEY,
    'INFINIT_LOADING_END',
  );
  const successResponse = createSimpleListingAction<void>(
    KEY,
    'SUCCESS_RESPONSE',
  );
  const failedResponse = createSimpleListingAction<void>(
    KEY,
    'FAILED_RESPONSE',
  );

  const initData: any = {
    list: null,
    error: null,
    loading: false,
    infinitLoading: false,
    pagination: null,
    suppData: null,
    refreshing: false,
    successMessage: null,
    provider: null,
  };

  const SimpleListing = Record<SimpleListingState<LISTITEM>>(initData);
  const newSimpleListing: SimpleListingStateRecord<LISTITEM> =
    new SimpleListing(initData);

  const actionsHandled = {
    [reset]: () => newSimpleListing,
    [resetMessage]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('successMessage', null).set('error', null),
    [loadingStart]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('loading', true),
    [loadingEnd]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('loading', false),
    [refreshingStart]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('refreshing', true),
    [refreshingEnd]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('refreshing', false),
    [infinitLoadingStart]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('infinitLoading', true),
    [infinitLoadingEnd]: (state: SimpleListingStateRecord<LISTITEM>) =>
      state.set('infinitLoading', false),
    [failedResponse]: (
      state: SimpleListingStateRecord<LISTITEM>,
      { payload }: Action<any>,
    ) => state.set('error', payload),
    [successResponse]: (state: any, { payload }: any) => {
      let newList: any = null;
      let newPagination: any = null;
      let newLoadMore: any = null;
      let newSuppData: any;
      let providerResult: any = null;

      if (serializeData) {
        const newData: any = serializeData(payload);
        newList = newData.get('list');
        newPagination = newData.get('pagination');
        newLoadMore = newData.get('loadMore');
        newSuppData = newData.get('suppData');
        providerResult = newData.get('provider');
      } else {
        const { loadMore, list, pagination, suppData, provider } = payload;
        newList = list;
        newPagination = pagination;
        newLoadMore = loadMore;
        newSuppData = suppData;
        providerResult = provider;
      }

      if (newLoadMore) {
        return state
          .mergeIn(['list'], newList)
          .set('pagination', newPagination)
          .set('error', null)
          .set('suppData', newSuppData)
          .set('provider', providerResult)
          .set('successMessage', payload?.message || null);
      }
      return state
        .set('list', newList)
        .set('pagination', newPagination)
        .set('error', null)
        .set('suppData', newSuppData)
        .set('provider', providerResult)
        .set('successMessage', payload?.message || null);
    },
  };

  const simpleListingReducer: Reducer<
    SimpleListingStateRecord<LISTITEM>,
    any
  > = handleActions<SimpleListingStateRecord<LISTITEM>, any>(
    actionsHandled,
    newSimpleListing,
  );

  return {
    reducer: simpleListingReducer,
    state: newSimpleListing,
    actionsHandled,
  };
};

export default simpleListingStore;
