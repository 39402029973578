import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SignUpDispatchProps, SignUpStateProps } from './types';
import SignUpC from '../../components/pages/Registration';
import { AppState } from '../../types';
import {
  signUp as signUpAC,
  resetSignUpMessages as resetSignUpMessagesAC,
  resetSignUp as resetSignUpAC,
} from '../../actions';
import { getSignUpLoading, getSignUpError } from '../../selectors';
import Message from '../../components/common/Message';

interface SignUpProps extends SignUpDispatchProps, SignUpStateProps {}

const SignUp: FunctionComponent<SignUpProps> = ({
  // loading,
  signUp,
  error,
  resetSignUpMessages,
}: SignUpProps) => {
  return (
    <>
      <Message
        error={
          error
            ? {
                errorMessage: error.error,
                resetError: () => resetSignUpMessages(),
              }
            : null
        }
      />
      <SignUpC onSubmit={signUp} />
    </>
  );
};

/**
* SignUp map state to props
*
* @param {Dispatch} dispatch
@returns {SignUpDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): SignUpDispatchProps => {
  return bindActionCreators(
    {
      signUp: signUpAC,
      resetSignUpMessages: resetSignUpMessagesAC,
      resetSignUp: resetSignUpAC,
    },
    dispatch,
  );
};

/**
 * SignUp map state to props
 *
 * @param {AppState} appState
 * @returns {SignUpStateProps}
 */
const mapStateToProps = (appState: AppState): SignUpStateProps => {
  return {
    loading: getSignUpLoading(appState),
    error: getSignUpError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
