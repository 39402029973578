import React, { useEffect, useState } from 'react';
import Icon from 'react-remixicon';

export default function CookieReminder() {
  const [cookieVisible, setCookieVisible] = useState(false);
  useEffect(() => {
    const acceptedCookie =
      localStorage.getItem('acceptedCookie') === 'accepted';
    if (acceptedCookie) setCookieVisible(false);
    else setCookieVisible(true);
  }, []);

  const acceptCookie = () => {
    localStorage.setItem('acceptedCookie', 'accepted');
    setCookieVisible(false);
  };

  if (cookieVisible)
    return (
      <div className="cookie-reminder">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              Nous utilisons des cookies sur notre site pour vous proposer une
              navigation optimale. En poursuivant votre navigation sur notre
              site, vous acceptez l'utilisation de cookies.
            </div>
            <div>
              <button onClick={acceptCookie}>
                <Icon name="close" type="line" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return null;
}
