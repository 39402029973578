import { List } from 'immutable';
import { createSelector } from 'reselect';
import {
  GlobalResponseRecord,
  SimpleFetchStateRecord,
  SimpleListingStateRecord,
} from '../helpers';
import { AddressDataRecord, OrderRecord } from '../models';
import { AppState, UserState } from '../types';

export const getUserState = (state: AppState): UserState => state.user;

//* *********************  START ORDERS STATE  ****************** */
export const getOrdersState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<OrderRecord>
>([getUserState], (user: UserState) => user.myOrders);

export const getMyOrders = createSelector<
  AppState,
  any,
  List<OrderRecord> | undefined | null
>([getOrdersState], (ordersState: SimpleListingStateRecord<OrderRecord>) =>
  ordersState.get('list'),
);

export const getMyOrdersError = createSelector<
  AppState,
  any,
  any | undefined | null
>([getOrdersState], (ordersState: SimpleListingStateRecord<OrderRecord>) =>
  ordersState.get('error'),
);

export const getMyOrdersLoading = createSelector<
  AppState,
  any,
  any | undefined | null
>([getOrdersState], (ordersState: SimpleListingStateRecord<OrderRecord>) =>
  ordersState.get('loading'),
);

export const getOrderDetailsState = createSelector<
  AppState,
  any,
  OrderRecord | undefined | null
>([getUserState], (user: UserState) => user.orderDetailsState);

export const getOrderDetailsLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getOrderDetailsState],
  (orderDetailsState: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    orderDetailsState.get('loading'),
);

//* *********************  END ORDERS STATE  ****************** */

//* *********************  BEGIN PROFIL UPDATE STATE  ****************** */

export const getProfilUpdateState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>([getUserState], (user: UserState) => user.profilUpdate);

export const getProfilUpdateSuccessMessage = createSelector<
  AppState,
  any,
  string | undefined | null
>(
  [getProfilUpdateState],
  (profilUpdate: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    profilUpdate.get('successMessage'),
);

export const getProfilError = createSelector<
  AppState,
  any,
  any | undefined | null
>(
  [getProfilUpdateState],
  (profilUpdate: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    profilUpdate.get('error'),
);

export const getProfilLoading = createSelector<
  AppState,
  any,
  boolean | undefined | null
>(
  [getProfilUpdateState],
  (profilUpdate: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    profilUpdate.get('loading'),
);

//* *********************  END PROFIL UPDATE STATE  ****************** */

//* *********************  BEGIN MY ADDRESSES STATE  ****************** */
export const getAddressesState = createSelector<
  AppState,
  any,
  AddressDataRecord
>([getUserState], (user: UserState) => user.myAddresses);

export const getAdresses = createSelector<
  AppState,
  any,
  any | null | undefined
>([getAddressesState], (myAddresses?: AddressDataRecord | null) =>
  myAddresses?.get('addresses'),
);

export const getAdressesLoading = createSelector<
  AppState,
  any,
  boolean | null | undefined
>([getAddressesState], (myAddresses?: AddressDataRecord | null) =>
  myAddresses?.get('loading'),
);

export const getAdressesError = createSelector<
  AppState,
  any,
  any | null | undefined
>([getAddressesState], (myAddresses?: AddressDataRecord | null) =>
  myAddresses?.get('error'),
);

export const getAdressesSuccessMessage = createSelector<
  AppState,
  any,
  string | null | undefined
>([getAddressesState], (myAddresses?: AddressDataRecord | null) =>
  myAddresses?.get('successMessage'),
);

//* *********************  END MY ADDRESSES STATE  ****************** */

//* *********************  BEGIN NEWSLETTERS STATE  ****************** */
export const getNewslettersState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<GlobalResponseRecord>
>([getUserState], (user: UserState) => user.newsletters);

export const getNewslettersLoading = createSelector<
  AppState,
  any,
  boolean | null | undefined
>(
  [getNewslettersState],
  (newsletters?: SimpleFetchStateRecord<GlobalResponseRecord> | null) =>
    newsletters?.get('loading'),
);

export const getNewslettersError = createSelector<
  AppState,
  any,
  any | null | undefined
>(
  [getNewslettersState],
  (newsletters?: SimpleFetchStateRecord<GlobalResponseRecord> | null) =>
    newsletters?.get('error'),
);

export const getNewslettersSuccessMessage = createSelector<
  AppState,
  any,
  string | null | undefined
>(
  [getNewslettersState],
  (newsletters?: SimpleFetchStateRecord<GlobalResponseRecord> | null) =>
    newsletters?.get('successMessage'),
);

//* *********************  END NEWSLETTERS STATE  ****************** */
