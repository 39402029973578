import { createSelector } from 'reselect';
import { List } from 'immutable';
import { AppState, EventState } from '../types';
import {
  SimpleListingStateRecord,
  SimpleFetchStateRecord,
  PaginationRecord,
} from '../helpers';
import {
  EventRecord,
  EventResponseRecord,
  EventProviderRecord,
} from '../models';
import { Error } from '../providers';

export const getEventState = (appState: AppState) => appState.event;

//* ************************* BEGIN EVENTS  *************************** */

export const getEventsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<EventRecord>
>([getEventState], (eventState: EventState) => eventState.eventsState);

export const getEventsLoading = createSelector<AppState, any, boolean>(
  [getEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('loading'),
);

export const getEventsRefreshing = createSelector<AppState, any, boolean>(
  [getEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('refreshing'),
);

export const getEventsError = createSelector<AppState, any, Error | null>(
  [getEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('error'),
);

export const getEventsList = createSelector<
  AppState,
  any,
  List<EventRecord> | undefined | null
>([getEventsState], (eventsState: SimpleListingStateRecord<EventRecord>) =>
  eventsState.get('list'),
);

export const getEventsPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>([getEventsState], (eventsState: SimpleListingStateRecord<EventRecord>) =>
  eventsState.get('pagination'),
);

export const getEventsInfinitLoading = createSelector<AppState, any, boolean>(
  [getEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('infinitLoading'),
);

//* ************************* END EVENTS *************************** */

//* ************************* BEGIN PAST EVENTS *************************** */

export const getPastEventsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<EventRecord>
>([getEventState], (eventState: EventState) => eventState.pastEventsState);

export const getPastEventsLoading = createSelector<AppState, any, boolean>(
  [getPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('loading'),
);

export const getPastEventsRefreshing = createSelector<AppState, any, boolean>(
  [getPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('refreshing'),
);

export const getPastEventsError = createSelector<AppState, any, Error | null>(
  [getPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('error'),
);

export const getPastEventsList = createSelector<
  AppState,
  any,
  List<EventRecord> | undefined | null
>([getPastEventsState], (eventsState: SimpleListingStateRecord<EventRecord>) =>
  eventsState.get('list'),
);

export const getPastEventsPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>([getPastEventsState], (eventsState: SimpleListingStateRecord<EventRecord>) =>
  eventsState.get('pagination'),
);

//* ************************* END PAST EVENTS *************************** */

//* ************************* BEGIN EVENT DETAILS *************************** */

export const getEventDetailsState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<EventResponseRecord>
>([getEventState], (eventState: EventState) => eventState.eventDetailsState);

export const getEventLoading = createSelector<AppState, any, boolean>(
  [getEventDetailsState],
  (eventDetailsState: SimpleFetchStateRecord<EventResponseRecord>) =>
    eventDetailsState.get('loading'),
);

export const getEventRefreshing = createSelector<AppState, any, boolean>(
  [getEventDetailsState],
  (eventDetailsState: SimpleFetchStateRecord<EventResponseRecord>) =>
    eventDetailsState.get('refreshing'),
);

export const getEventError = createSelector<AppState, any, Error | null>(
  [getEventDetailsState],
  (eventDetailsState: SimpleFetchStateRecord<EventResponseRecord>) =>
    eventDetailsState.get('error'),
);

export const getEventData = createSelector<
  AppState,
  any,
  EventRecord | undefined | null
>(
  [getEventDetailsState],
  (eventDetailsState: SimpleFetchStateRecord<EventResponseRecord>) =>
    eventDetailsState.get('data')?.get('event'),
);

//* ************************* END EVENT DETAILS *************************** */

//* ************************* BEGIN PROVIDER EVENTS *************************** */

export const getProviderEventsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<EventRecord>
>([getEventState], (eventState: EventState) => eventState.providerEventsState);

export const getProviderEventsLoading = createSelector<AppState, any, boolean>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('loading'),
);

export const getProviderEventsRefreshing = createSelector<
  AppState,
  any,
  boolean
>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('refreshing'),
);

export const getProviderEventsError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('error'),
);

export const getProviderEventsList = createSelector<
  AppState,
  any,
  List<EventRecord> | undefined | null
>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('list'),
);

export const getProviderEventsPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('pagination'),
);

export const getProviderEventsInfinitLoading = createSelector<
  AppState,
  any,
  boolean
>(
  [getProviderEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('infinitLoading'),
);

export const getProvider = createSelector<
  AppState,
  any,
  EventProviderRecord | undefined | null
>([getProviderEventsState], (eventsState: any) => {
  return eventsState.get('provider');
});

export const getProviderPastEventsState = createSelector<
  AppState,
  any,
  SimpleListingStateRecord<EventRecord>
>(
  [getEventState],
  (eventState: EventState) => eventState.providerPastEventsState,
);

export const getProviderPastEventsLoading = createSelector<
  AppState,
  any,
  boolean
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('loading'),
);

export const getProviderPastEventsRefreshing = createSelector<
  AppState,
  any,
  boolean
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('refreshing'),
);

export const getProviderPastEventsError = createSelector<
  AppState,
  any,
  Error | null
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('error'),
);

export const getProviderPastEventsList = createSelector<
  AppState,
  any,
  List<EventRecord> | undefined | null
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('list'),
);

export const getProviderPastEventsPagination = createSelector<
  AppState,
  any,
  PaginationRecord | undefined | null
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('pagination'),
);

export const getProviderPastEventsInfinitLoading = createSelector<
  AppState,
  any,
  boolean
>(
  [getProviderPastEventsState],
  (eventsState: SimpleListingStateRecord<EventRecord>) =>
    eventsState.get('infinitLoading'),
);

//* ************************* END PROVIDER EVENTS *************************** */
