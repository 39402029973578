import { createAction } from 'redux-actions';
import { SimpleActionKeys, SimpleListingActionKeys } from './actions-types';
import { getStringBetween } from '../utils';

/**
 * Create simple &ction type for all type data
 *
 * @export
 * @param {string} key
 * @param {SimpleActionKeys} type
 * @returns {string}
 */
export function createSimpleActionType(
  key: string,
  type: SimpleActionKeys,
): string {
  return `@@simple_fetch/${key.toUpperCase()}_${type}`;
}

/**
 * Get key from simple action type
 *
 * @export
 * @param {string} actionType
 * @param {TypesOfAction} type
 * @returns {string}
 */
export function getKeyFromSimpleActionType(
  actionType: string,
  type: SimpleActionKeys,
): string {
  return getStringBetween(actionType, '@@simple_fetch/', `_${type}`, false);
}

/**
 * Create simple action of any type of data
 *
 * @export
 * @template PAYLOAD
 * @param {string} key
 * @param {SimpleActionKeys} type
 * @returns {string}
 */
export function createSimpleAction<PAYLOAD>(
  key: string,
  type: SimpleActionKeys,
): string {
  return createAction<PAYLOAD>(createSimpleActionType(key, type)).toString();
}

/**
 * Create simple listing action type
 *
 * @export
 * @param {string} key
 * @param {TypesOfSimpleListingAction} type
 * @returns {string}
 */
export function createSimpleListingActionType(
  key: string,
  type: SimpleListingActionKeys,
): string {
  return `@@simple_listing/${key.toUpperCase()}_${type}`;
}

export function getKeyFromSimpleListingActionType(
  actionType: string,
  type: SimpleListingActionKeys,
): string {
  return getStringBetween(actionType, '@@simple_listing/', `_${type}`, false);
}

/**
 * Create simple listing action
 *
 * @export
 * @template PAYLOAD
 * @param {string} key
 * @param {TypesOfSimpleListingAction} type
 * @returns {string}
 */
export function createSimpleListingAction<PAYLOAD>(
  key: string,
  type: SimpleListingActionKeys,
): string {
  return createAction<PAYLOAD>(
    createSimpleListingActionType(key, type),
  ).toString();
}
