import { Reducer, combineReducers } from 'redux';
import { articleActionKeys, ArticleState } from '../types';
import { createSimpleListingStore, createSimpleFetchStore } from '../helpers';
import { ArticleRecord, ArticleResponseRecord, TopicRecord } from '../models';
import {
  serializeArticleResponse,
  serializeArticlesListing,
  serializeTopicsListing,
} from '../providers/magazine';

const magazineReducer: Reducer<ArticleState> = combineReducers<ArticleState>({
  articlesState: createSimpleListingStore<ArticleRecord>(
    articleActionKeys.ARTICLES,
    serializeArticlesListing,
  ).reducer,
  articleDetailsState: createSimpleFetchStore<ArticleResponseRecord>(
    articleActionKeys.DETAIL_ARTICLE,
    serializeArticleResponse,
  ).reducer,
  topicsState: createSimpleListingStore<TopicRecord>(
    articleActionKeys.TOPICS,
    serializeTopicsListing,
  ).reducer,
});

export default magazineReducer;
