import { Action } from 'redux';
import { User } from '../user';
import { GlobalSuccessPayload } from '../../../helpers';

export const authActionKeys = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  SIGN_OUT: 'SIGN_OUT',
  SOCIAL_SIGN_IN: 'SOCIAL_SIGN_IN',
};

export const authActions = {
  CONNECTION_UPDATED: '@@authentication/CONNECTION_UPDATED',
  CHECK_CONNECTION: '@@authentication/CHECK_CONNECTION',
  USER_UPDATED: '@@authentication/USER_UPDATED',
  SET_TOKEN_TO_STORE: '@@authentication/SET_TOKEN_TO_STORE',
  SIGN_OUT: '@@authentication/SIGN_OUT',
  CREATE_PLAYER_ID: '@@authentication/CREATE_PLAYER_ID',
  AUTH_LOADING_START: '@@authentication/AUTH_LOADING_START',
  AUTH_LOADING_END: '@@authentication/AUTH_LOADING_END',
  TOGGLE_MODAL_CONNECTION: '@@authentication/TOGGLE_MODAL_CONNECTION',
};

export type AuthActionTypes =
  | '@@authentication/CONNECTION_UPDATED'
  | '@@authentication/CHECK_CONNECTION'
  | '@@authentication/USER_UPDATED'
  | '@@authentication/SET_TOKEN_TO_STORE'
  | '@@authentication/SIGN_OUT'
  | '@@authentication/CREATE_PLAYER_ID'
  | '@@authentication/AUTH_LOADING_START'
  | '@@authentication/AUTH_LOADING_END'
  | '@@authentication/TOGGLE_MODAL_CONNECTION';

/**
 * Set token to store payload
 *
 * @export
 * @interface SetTokenToStorePaylod
 */
export interface SetTokenToStorePaylod {
  token: string | undefined | null;
}

/**
 * Set token to store Action
 *
 * @export
 * @interface SetTokenToStoreAction
 * @extends {Action<AuthActionTypes>}
 */
export interface SetTokenToStoreAction extends Action<AuthActionTypes> {
  type: '@@authentication/SET_TOKEN_TO_STORE';
  payload: SetTokenToStorePaylod;
}

/**
 * Set user to store Payload
 *
 * @export
 * @interface SetUserToStorePayload
 */

export interface CheckConnectionPayload {
  isConnected: boolean;
  user?: User | null;
}

/**
 * Check connection Action
 *
 * @export
 * @interface CheckConnectionAction
 * @extends {Action<AuthActionTypes>}
 */
export interface CheckConnectionAction extends Action<AuthActionTypes> {
  type: '@@authentication/CHECK_CONNECTION';
}

/**
 * Connection Update Payload
 *
 * @export
 * @interface ConnectionUpdatePayload
 */
export interface ConnectionUpdatedPayload {
  isConnected: boolean;
  user?: User | null;
}

/**
 * Is connected state of authentication Action
 *
 * @export
 * @interface ConnectionUpdatedAction
 * @extends {Action<AuthActionTypes>}
 */
export interface ConnectionUpdatedAction extends Action<AuthActionTypes> {
  type: '@@authentication/CONNECTION_UPDATED';
  payload: ConnectionUpdatedPayload;
}

/**
 * User updated Payload
 *
 * @export
 * @interface UserUpdatedPayload
 */
export interface UserUpdatedPayload {
  user?: User | null;
}

/**
 * User updated Action
 *
 * @export
 * @interface UserUpdatedAction
 * @extends {Action<AuthActionTypes>}
 */
export interface UserUpdatedAction extends Action<AuthActionTypes> {
  type: '@@authentication/USER_UPDATED';
  payload: UserUpdatedPayload;
}

/**
 = Sign in success paylod 
 *
 * @export
 * @interface SignInSuccessPayload
 * @extends {GlobalSuccessPayload}
 */
export interface SignInSuccessPayload extends GlobalSuccessPayload {
  user: User;
  token: string;
}

/**
 = Edit profil success paylod 
 *
 * @export
 * @interface EditProfilSuccessPayload
 * @extends {GlobalSuccessPayload}
 */
export interface EditProfilSuccessPayload extends GlobalSuccessPayload {
  user: User;
}

/**
 * Sign out Actions
 *
 * @export
 * @interface SignOutAction
 * @extends {Action<AuthActionTypes>}
 */
export interface SignOutAction extends Action<AuthActionTypes> {
  type: '@@authentication/SIGN_OUT';
  callback: any;
}

/**
 * Create player id of oneSignal to api
 *
 * @export
 * @interface CreatePlayerIdPayload
 */
export interface CreatePlayerIdPayload {
  playerId: string;
}

/**
 * Create player id action
 *
 * @export
 * @interface CreatePlayerIdAction
 * @extends {Action<AuthActionTypes>}
 */
export interface CreatePlayerIdAction extends Action<AuthActionTypes> {
  type: '@@authentication/CREATE_PLAYER_ID';
  payload: CreatePlayerIdPayload;
}

/**
 * Auth loading start action
 *
 * @export
 * @interface AuthLoadingStartAction
 * @extends {Action<AuthActionTypes>}
 */
export interface AuthLoadingStartAction extends Action<AuthActionTypes> {
  type: '@@authentication/AUTH_LOADING_START';
}

/**
 * Auth loading end action
 *
 * @export
 * @interface AuthLoadingEndAction
 * @extends {Action<AuthActionTypes>}
 */
export interface AuthLoadingEndAction extends Action<AuthActionTypes> {
  type: '@@authentication/AUTH_LOADING_END';
}

export interface ToggleModalConnectionPayload {
  isVisible: boolean;
}

export interface ToggleModalConnecionAction extends Action<AuthActionTypes> {
  type: '@@authentication/TOGGLE_MODAL_CONNECTION';
  payload?: ToggleModalConnectionPayload;
}

export type AuthenticationActions = ConnectionUpdatedAction &
  CheckConnectionAction &
  UserUpdatedAction &
  SetTokenToStoreAction &
  SignOutAction &
  CreatePlayerIdAction &
  ConnectionActions;

export type AuthenticationPayloads = ConnectionUpdatedPayload &
  UserUpdatedPayload &
  SetTokenToStorePaylod &
  CreatePlayerIdPayload &
  SignInSuccessPayload &
  EditProfilSuccessPayload &
  ConnectionPayloads;

export type ConnectionActions = ConnectionUpdatedAction &
  CheckConnectionAction &
  UserUpdatedAction &
  SetTokenToStoreAction &
  SignOutAction &
  AuthLoadingStartAction &
  AuthLoadingEndAction &
  ToggleModalConnecionAction;

export type ConnectionPayloads = ConnectionUpdatedPayload &
  UserUpdatedPayload &
  SetTokenToStorePaylod &
  ToggleModalConnectionPayload;
