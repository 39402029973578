import { ActionCreator } from 'redux';
import { themeActionsKeys } from '../types';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes } from '../providers';

/**
 * Fetch Themes action creator
 *
 * @param {boolean} [refresh]
 * returns {SimpleActions}
 */
export const fetchThemes: ActionCreator<SimpleActions> = (
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.themes,
    callGlobalError: false,
    refresh,
  };
  return simpleFetchRequest(themeActionsKeys.THEMES, confiApi);
};

/**
 * Reset Themes action creator
 *
 * returns {SimpleActions}
 */
export const resetThemes: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(themeActionsKeys.THEMES);

/**
 * Reset Themes messages action creator
 *
 * returns {SimpleActions}
 */
export const resetThemesMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(themeActionsKeys.THEMES);
