import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ProviderPastEventsGridDispatchProps,
  ProviderPastEventsGridStateProps,
} from './types';
import { AppState } from '../../types';
import {
  fetchProviderPastEvents as fetchEventsAC,
  resetProviderPastEventsMessages as resetEventsMessagesAC,
  resetProviderPastEvents as resetEventsAC,
} from '../../actions';
import {
  getProviderPastEventsInfinitLoading,
  getProviderPastEventsList,
  getProviderPastEventsLoading,
  getProviderPastEventsError,
  getProviderPastEventsRefreshing,
  getProviderPastEventsPagination,
} from '../../selectors';
import PastEventsGridC from '../../components/PastEventsGrid';

interface EventsGridProps
  extends ProviderPastEventsGridDispatchProps,
    ProviderPastEventsGridStateProps {
  providerSlug?: string;
}

const EventsGrid: FunctionComponent<EventsGridProps> = ({
  fetchEvents,
  resetEvents,
  events,

  providerSlug,
  pagination,
}: EventsGridProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page')) || 1;

  useEffect(() => {
    fetchEvents(providerSlug, page);
    return () => {
      resetEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <PastEventsGridC events={events} pagination={pagination} page={page || 1} />
  );
};

/**
* EventsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {EventsGridDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): ProviderPastEventsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchEvents: fetchEventsAC,
      resetEventsMessages: resetEventsMessagesAC,
      resetEvents: resetEventsAC,
    },
    dispatch,
  );
};

/**
 * EventsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {EventsGridStateProps}
 */
const mapStateToProps = (
  appState: AppState,
): ProviderPastEventsGridStateProps => {
  return {
    events: getProviderPastEventsList(appState),
    loading: getProviderPastEventsLoading(appState),
    error: getProviderPastEventsError(appState),
    refreshing: getProviderPastEventsRefreshing(appState),
    pagination: getProviderPastEventsPagination(appState),
    infinitLoading: getProviderPastEventsInfinitLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsGrid);
