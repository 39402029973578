import React, { useLayoutEffect, useMemo, useRef } from 'react';

interface PaymentFormProps {
  paymentParams?: any;
}

export const PaymentForm = (props: PaymentFormProps) => {
  const { paymentParams } = props;

  const ref = useRef<any>();

  const fieldsObj = useMemo(() => {
    const keys = Object.keys(paymentParams);
    const values = Object.values(paymentParams);
    const arr: any = values.map((value, index) => ({
      name: keys[index],
      value,
    }));
    return {
      fields: arr.filter(
        (field: any) => field.name !== 'gateway' && field.name !== 'method',
      ),
      action: arr.find((field: any) => field.name === 'gateway').value,
      method: 'post',
    };
  }, [paymentParams]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    ref.current.requestSubmit();
  }, []);

  return (
    <form
      ref={ref}
      id="cartRedirectForm"
      method={fieldsObj?.method}
      name="cartRedirectForm"
      action={fieldsObj?.action}
    >
      {fieldsObj?.fields?.map((field: any) => (
        <input
          type="hidden"
          name={field.name}
          value={field.value}
          key={`payment-form-field-${field.name}`}
        />
      ))}
    </form>
  );
};
