import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EventDetailsDispatchProps, EventDetailsStateProps } from './types';
import EventDetailsC from '../../components/pages/Event';
import {
  resetEventMessages as resetEventMessagesAC,
  resetEvent as resetEventAC,
  addToCart as addToCartAc,
  deleteSeat as deleteSeatAC,
  destroyCart as destroyCartAC,
} from '../../actions';
import {
  getEventLoading,
  getEventRefreshing,
  getEventError,
  getCart,
  getCartLoading,
} from '../../selectors';
import { AppState, EventPageParams } from '../../types';
import AppMeta from '../../components/common/AppMeta';

interface EventDetailsProps
  extends EventDetailsDispatchProps,
    EventDetailsStateProps {}

const EventDetails: FunctionComponent<EventDetailsProps> = ({
  addToCart,
  eventDetails,
  deleteSeat,
  cartHasPlan,
  destroyCart,
  cartLoading,
  cartSubTotal,
}: EventDetailsProps) => {
  const { event: eventParam } = useParams<EventPageParams>();

  useEffect(() => {
    return () => {
      // resetEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventParam]);

  useEffect(() => {
    const sElement = document.createElement('script');
    sElement.setAttribute('id', 'fbScript');
    const noSElement = document.createElement('noscript');
    sElement.setAttribute('id', 'fbNoScript');

    const sGoogle1 = document.createElement('script');
    sGoogle1.setAttribute('id', 'sGoogle1');
    const sGoogle2 = document.createElement('script');
    sGoogle2.setAttribute('id', 'sGoogle2');

    const sTiktok = document.createElement('script');
    sTiktok.setAttribute('id', 'sTiktok');

    if (eventDetails) {
      const fbPixelId = eventDetails.get('fbPixelId');

      if (fbPixelId) {
        sElement.type = 'text/javascript';
        sElement.async = true;
        sElement.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "${fbPixelId}");fbq("track", "PageView");`;

        noSElement.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"/>`;

        document.head.appendChild(sElement);
        document.head.appendChild(noSElement);
      }

      const gtmId = eventDetails.get('gtmId');

      if (gtmId) {
        sGoogle1.async = true;
        sGoogle1.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;

        sGoogle2.type = 'text/javascript';
        sGoogle2.async = true;
        sGoogle2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "${gtmId}");`;

        document.head.appendChild(sGoogle1);
        document.head.appendChild(sGoogle2);
      }

      const tiktokId = eventDetails.get('tiktokId');

      if (tiktokId) {
        sTiktok.type = 'text/javascript';
        sTiktok.async = true;
        sTiktok.innerHTML = `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};ttq.load("${tiktokId}");ttq.page();}(window, document, "ttq");`;

        document.head.appendChild(sTiktok);
      }
    }

    return () => {
      if (eventDetails) {
        const scriptEl = document.getElementById('fbScript');
        const noScriptEl = document.getElementById('fbNoScript');
        const sGoogle1El = document.getElementById('sGoogle1');
        const sGoogle2El = document.getElementById('sGoogle2');
        const sTiktokEl = document.getElementById('sTiktok');
        if (scriptEl) document.head.removeChild(scriptEl);
        if (scriptEl) document.head.removeChild(noScriptEl);
        if (sGoogle1El) document.head.removeChild(sGoogle1El);
        if (sGoogle2El) document.head.removeChild(sGoogle2El);
        if (sTiktokEl) document.head.removeChild(sTiktokEl);
      }
    };
  }, [eventDetails]);

  return (
    <>
      <AppMeta
        title={eventDetails.get('metaTitle')}
        description={eventDetails.get('metaDescription')}
        keywords={eventDetails.get('metaKeywords')}
      />
      <EventDetailsC
        event={eventDetails}
        addToCart={addToCart}
        deleteSeat={deleteSeat}
        cartHasPlan={cartHasPlan}
        destroyCart={destroyCart}
        cartLoading={cartLoading}
        cartSubTotal={cartSubTotal}
      />
    </>
  );
};

/**
* EventDetails map state to props
*
* @param {Dispatch} dispatch
@returns {EventDetailsDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): EventDetailsDispatchProps => {
  return bindActionCreators(
    {
      resetEventMessages: resetEventMessagesAC,
      resetEvent: resetEventAC,
      addToCart: addToCartAc,
      deleteSeat: deleteSeatAC,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

/**
 * EventDetails map state to props
 *
 * @param {AppState} appState
 * @returns {EventDetailsStateProps}
 */
const mapStateToProps = (
  appState: AppState,
  ownProps,
): EventDetailsStateProps => {
  return {
    // event: getEventData(appState),
    loading: getEventLoading(appState),
    refreshing: getEventRefreshing(appState),
    error: getEventError(appState),
    eventDetails: ownProps.event,
    cartHasPlan: getCart(appState)?.get('hasPlan'),
    cartLoading: getCartLoading(appState),
    cartSubTotal: getCart(appState).subTotal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
