import { DynamicComponentRecord } from '../../models';
import { DynamicComponent } from '../../types';
import { serializeEvent } from '../event';
import { serializeCategory } from '../category';
import { serializeCampaign, serializeTerm } from '../campaign';
import { serializeTheme } from '../theme';
import { serializeProduct } from '../product';
import { serializeArticle, serializeTopic } from '../magazine';
import { serializePage } from '../../models/page';

/**
 * Serialize dynamic component response
 *
 * @param {DynamicComponent} {
 *   event,
 *   campaign,
 *   product,
 *   category,
 *   theme,
 * }
 * @returns {DynamicComponentRecord}
 */
export const serializeDynamicComponent = ({
  event,
  campaign,
  product,
  category,
  theme,
  term,
  topic,
  article,
  page,
}: DynamicComponent): DynamicComponentRecord => {
  const newComponent = new DynamicComponentRecord({
    campaign: campaign ? serializeCampaign(campaign) : null,
    product: product ? serializeProduct(product) : null,
    theme: theme ? serializeTheme(theme) : null,
    event: event ? serializeEvent(event) : null,
    category: category ? serializeCategory(category) : null,
    term: term ? serializeTerm(term) : null,
    topic: topic ? serializeTopic(topic) : null,
    article: article ? serializeArticle(article) : null,
    page: page ? serializePage(page) : null,
  });

  return newComponent;
};
