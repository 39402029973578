/* eslint-disable eqeqeq */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as QueryString from 'query-string';
import { store } from '../../index';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigCallApi, Error } from './types';
import {
  connectionUpdated,
  setTokenToStore,
  authloadingStart,
  authloadingEnd,
  toggleModalConnection,
} from '../../actions';
import {
  getStorageData,
  removeStorageData,
  STORAGE_TOKEN,
  appendFormdata,
  STORAGE_COUNTRY,
  STORAGE_COUNTRIES,
  setStorageData,
} from '../../helpers';
import { Country } from '../../types';
// import { removeStorageData, STORAGE_TOKEN } from '../../helper';
const { REACT_APP_API_BASE_URL } = process.env;

const BASE_URL = REACT_APP_API_BASE_URL;

const TIMEOUT_ERROR =
  'Le service est momentanément indisponible.\nVeuillez réessayer ultérieurement.';

/**
 * Fetch tirehub api
 *
 * @param {ConfigCallApi} {
 *   url,
 *   method = 'get',
 *   params = null,
 *   postData = null,
 *   secure = false,
 *   token
 * }
 * @returns {Promise<any>}
 */
async function fetch({
  url,
  method = 'get',
  params = null,
  postData = null,
  secure = false,
  token,
  headers,
  hasFile,
}: ConfigCallApi): Promise<any> {
  try {
    const formData = new FormData();
    if (hasFile) {
      appendFormdata(formData, postData);
    }

    let newHeaders: any = {
      ...headers,
      'Content-Type': hasFile ? 'multipart/form-data' : 'application/json',
    };

    let country: Country = getStorageData(STORAGE_COUNTRY, true);
    const countries: Country[] = getStorageData(STORAGE_COUNTRIES, true);

    const querySearch = window.location.search;

    const { country: countryParam }: any = QueryString.parse(querySearch);

    if (countries && countries.length > 0 && countryParam) {
      country =
        countries.find(
          co =>
            co.id == countryParam ||
            co.currencyCode == countryParam ||
            co.name == countryParam,
        ) ||
        countries.find(co => co.isDefault) ||
        country;

      if (country) {
        setStorageData(STORAGE_COUNTRY, country);
      }
    }

    const newCountry = countryParam || country?.id || null;

    if (newCountry) {
      newHeaders = {
        ...newHeaders,
        country: newCountry,
      };
    }

    if (secure) {
      newHeaders = {
        ...newHeaders,
        Authorization: `Bearer ${token}`,
      };
    }

    const resp: AxiosResponse = await axios.request<any, AxiosResponse>({
      headers: newHeaders,
      url,
      baseURL: BASE_URL,
      method,
      params,
      data: hasFile ? formData : postData,
      withCredentials: true,
    } as AxiosRequestConfig);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * Call tirehub api
 *
 * @export
 * @param {ConfigCallApi} {
 *   url,
 *   method = 'get',
 *   params = null,
 *   postData = null,
 *   secure = false,
 *   callGlobalError = true,
 *   headers,
 *   token
 * }
 * @returns {Promise<any>}
 */
export default async function callApi({
  url,
  method = 'get',
  params = null,
  postData = null,
  secure = false,
  callGlobalError = true,
  headers,
  token,
  hasFile,
  isCheckConnection,
}: ConfigCallApi): Promise<any> {
  let errorStatus = -1;
  try {
    let tokenfromSelector;
    if (token) tokenfromSelector = token;
    else tokenfromSelector = getStorageData(STORAGE_TOKEN);

    if (secure) {
      store.dispatch(authloadingStart());
    }
    const data: any = await fetch({
      url,
      method,
      params,
      postData,
      secure,
      callGlobalError,
      token: tokenfromSelector,
      headers,
      hasFile,
    });
    if (secure) {
      store.dispatch(authloadingEnd());
    }
    return Promise.resolve(data);
  } catch (err) {
    if (secure) {
      store.dispatch(authloadingEnd());
    }
    let errorMessage: any;
    let errorData: any;
    const { response } = err;
    // console.error(response);
    // if (!response.data.error) {
    //   error = TIMEOUT_ERROR;
    // } else
    if (response) {
      if (response.status === 403 || response.status === 401) {
        store.dispatch(connectionUpdated(false, null));
        store.dispatch(setTokenToStore(null));
        if (!isCheckConnection) {
          store.dispatch(toggleModalConnection({ isVisible: true }));
        }
        removeStorageData(STORAGE_TOKEN);
      } else if (response.status !== 401) {
        if (response.data.error) {
          errorMessage = response.data.error;
        } else if (response.data.message) {
          errorMessage = response.data.message;
        } else {
          errorMessage = TIMEOUT_ERROR;
        }
      }
      if (response.status === 500) {
        errorMessage = TIMEOUT_ERROR;
      }
      errorData = response.data;
      errorStatus = response.status;
    } else {
      errorMessage = TIMEOUT_ERROR;
    }

    const error: Error = {
      status: errorStatus,
      error: errorMessage,
      data: errorData,
    };
    return Promise.reject(error);
  }
}
