import { ActionCreator } from 'redux';
import {
  ApiRoutes,
  ConfigCallApi,
  SignInPostData,
  SignUpPostData,
  SocialSignInPostData,
} from '../providers';
import { SignInFormData, SignUpFormData } from '../components/forms';
import {
  ConnectionUpdatedAction,
  SetTokenToStoreAction,
  UserUpdatedAction,
  authActionKeys,
  CheckConnectionAction,
  SignOutAction,
  AuthLoadingStartAction,
  AuthLoadingEndAction,
  ToggleModalConnecionAction,
  ToggleModalConnectionPayload,
  SocialPlatform,
} from '../types';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchResetMessages,
  simpleFetchReset,
} from '../helpers';

/**
 * Check connection=
 *
 * @export
 * @returns {CheckConnectionAction}
 */
export const checkConnection: ActionCreator<
  CheckConnectionAction
> = (): CheckConnectionAction => ({
  type: '@@authentication/CHECK_CONNECTION',
});

/**
 * Set Token to store ActionCreator
 *
 * @export
 * @param {string} token
 * @returns {SetTokenToStoreAction}
 */
export const setTokenToStore: ActionCreator<SetTokenToStoreAction> = (
  token: string | undefined | null,
): SetTokenToStoreAction => ({
  type: '@@authentication/SET_TOKEN_TO_STORE',
  payload: {
    token,
  },
});

/**
 * Connexion updated ActionCreator
 *
 * @export
 * @param {boolean} isConnected
 * @param {*} user
 * @returns {ConnectionUpdatedAction}
 */
export const connectionUpdated: ActionCreator<ConnectionUpdatedAction> = (
  isConnected: boolean,
  user?: any,
): ConnectionUpdatedAction => ({
  type: '@@authentication/CONNECTION_UPDATED',
  payload: {
    isConnected,
    user,
  },
});

/**
 * User updated
 *
 * @export
 * @param {*} user
 * @returns {UserUpdatedAction}
 */
export const userUpdated: ActionCreator<UserUpdatedAction> = (
  user: any,
): UserUpdatedAction => ({
  type: '@@authentication/USER_UPDATED',
  payload: { user },
});

/**
 * Auth loading start ActionCreator
 *
 * @returns {AuthLoadingStartAction}
 */
export const authloadingStart: ActionCreator<
  AuthLoadingStartAction
> = (): AuthLoadingStartAction => ({
  type: '@@authentication/AUTH_LOADING_START',
});

/**
 * Auth loading end ActionCreator
 *
 * @returns {AuthLoadingEndAction}
 */
export const authloadingEnd: ActionCreator<
  AuthLoadingEndAction
> = (): AuthLoadingEndAction => ({
  type: '@@authentication/AUTH_LOADING_END',
});

export const toggleModalConnection: ActionCreator<
  ToggleModalConnecionAction
> = (payload?: ToggleModalConnectionPayload): ToggleModalConnecionAction => ({
  type: '@@authentication/TOGGLE_MODAL_CONNECTION',
  payload,
});

/**
 * Sign out
 *
 * @returns {SimpleActions}
 */
export const signOut: ActionCreator<SignOutAction> = (
  callback: any,
): SignOutAction => ({
  type: '@@authentication/SIGN_OUT',
  callback,
});

/**
 * Sign up action creator
 *
 * @param {SignUpFormData} formData
 * @returns {SimpleActions}
 */
export const signUp: ActionCreator<SimpleActions> = ({
  firstName,
  lastName,
  phone,
  email,
  password,
}: SignUpFormData): SimpleActions => {
  const postData: SignUpPostData = {
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    password,
    source: 'guichet',
  };
  const meta: ConfigCallApi = {
    url: ApiRoutes.signUp,
    method: 'POST',
    postData,
    callGlobalError: false,
    successCallback: () => {
      window.fbq('track', 'CompleteRegistration', {
        status: true,
      });
    },
  };
  return simpleFetchRequest(authActionKeys.SIGN_UP, meta);
};

/**
 * Reset sign up messages
 *
 * @returns {SimpleActions}
 */
export const resetSignUpMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(authActionKeys.SIGN_UP);

/**
 * Reset Sign up states
 *
 * @returns {SimpleActions}
 */
export const resetSignUp: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(authActionKeys.SIGN_UP);

/**
 * Sign in action creator
 *
 * @param {SignInFormData} {
 *   email,
 *   password
 * }
 * @returns {SimpleActions}
 */
export const signIn: ActionCreator<SimpleActions> = ({
  email,
  password,
}: SignInFormData): SimpleActions => {
  const postData: SignInPostData = {
    email,
    password,
    source: 'guichet',
  };
  const meta: ConfigCallApi = {
    url: ApiRoutes.signIn,
    method: 'POST',
    postData,
    callGlobalError: false,
  };
  return simpleFetchRequest(authActionKeys.SIGN_IN, meta);
};

/**
 * Forgot password action creator
 *
 * @param {ForgotPasswordFormData} {
 *   email,
 *   password
 * }
 * @returns {SimpleActions}
 */
export const forgotPassword: ActionCreator<SimpleActions> = ({
  email,
}: SignInFormData): SimpleActions => {
  const postData: any = {
    email,
  };
  const meta: ConfigCallApi = {
    url: ApiRoutes.forgotPassword,
    method: 'POST',
    postData,
    callGlobalError: false,
  };
  return simpleFetchRequest(authActionKeys.FORGOT_PASSWORD, meta);
};

/**
 * Recover password action creator
 *
 * @param {RecoverPasswordFormData} {
 *   email,
 *   password
 * }
 * @returns {SimpleActions}
 */
export const recoverPassword: ActionCreator<SimpleActions> = ({
  email,
  password,
  confirmPassword,
  token,
}: any): SimpleActions => {
  const postData: any = {
    token,
    email,
    password,
    password_confirmation: confirmPassword,
  };
  const meta: ConfigCallApi = {
    url: ApiRoutes.recoverPassword,
    method: 'POST',
    postData,
    callGlobalError: false,
  };
  return simpleFetchRequest(authActionKeys.RECOVER_PASSWORD, meta);
};

/**
 * Reset recover password messages
 *
 * @returns {SimpleActions}
 */
export const resetRecoverPasswordMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(authActionKeys.RECOVER_PASSWORD);

/**
 * Reset sign in messages
 *
 * @returns {SimpleActions}
 */
export const resetSignInMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(authActionKeys.SIGN_IN);

/**
 * Reset sign in state
 *
 * @returns {SimpleActions}
 */
export const resetSignIn: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(authActionKeys.SIGN_IN);

/** ****************** */

/**
 * Reset forgot password messages
 *
 * @returns {SimpleActions}
 */
export const resetForgotPasswordMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(authActionKeys.FORGOT_PASSWORD);

/**
 * Reset forgot passwordstate
 *
 * @returns {SimpleActions}
 */
export const resetForgotPassword: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchReset(authActionKeys.FORGOT_PASSWORD);

/**
 * Social social sign in
 *
 * @param {(string | null)} token
 * @param {SocialMediaPlatform} platform
 * @param {string} [secretToken]
 * @returns {SimpleActions}
 */
export const socialSignIn: ActionCreator<SimpleActions> = (
  token: string | null,
  platform: SocialPlatform,
  secretToken?: string,
): SimpleActions => {
  const postData: SocialSignInPostData = {
    platform,
    secretToken,
  };
  const meta: ConfigCallApi = {
    token: token || undefined,
    url: ApiRoutes.socialSignIn,
    method: 'POST',
    postData,
    callGlobalError: false,
    secure: true,
  };
  return simpleFetchRequest(authActionKeys.SOCIAL_SIGN_IN, meta);
};

/**
 * Reset social sign in messages
 *
 * @returns {SimpleActions}
 */
export const resetSocialSignInMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(authActionKeys.SOCIAL_SIGN_IN);

/**
 * Reset social sign in state
 *
 * @returns {SimpleActions}
 */
export const resetSocialSignIn: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchReset(authActionKeys.SOCIAL_SIGN_IN);
