import React from 'react';
import { Order } from '../../../types';
import { downloadFile } from '../../../helpers';

interface ConfirmationProps {
  instruction: string;
  order: Order;
}

export default function YoucanConfirmation({
  instruction,
  order,
}: ConfirmationProps) {
  return (
    <>
      <div className="cart cart-finalized">
        <div className="container">
          <h2 className="mb-5">Confirmation de votre commande</h2>
          <div className="box">
            <div
              className="text-body"
              dangerouslySetInnerHTML={{ __html: instruction }}
            />

            {order.isValid && (
              <div className="row mt-4">
                <div className="col-6">
                  <button
                    onClick={() =>
                      downloadFile(
                        order.reference,
                        `orders/${order.id}/download`,
                      )
                    }
                    className="btn green"
                  >
                    Télécharger mes tickets
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
