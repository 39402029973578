import React, { FunctionComponent, useEffect } from 'react';
import Swal, { SweetAlertIcon } from 'sweetalert2';

interface AlertProps {
  title?: string;
  message?: string;
  icon?: SweetAlertIcon;
  onClose?: () => void;
}

const Alert: FunctionComponent<AlertProps> = ({
  title,
  message,
  icon = 'info',
  onClose,
}: AlertProps) => {
  const show = async () => {
    Swal.fire({
      title: title ? `<strong>${title}</strong>` : undefined,
      icon,
      html: message,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      didClose: onClose,
    });
  };

  useEffect(() => {
    show();
    return () => {
      Swal.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default Alert;
