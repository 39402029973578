import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';

interface SearchCProps {
  fetchSearch: any;
  searchList: any;
  infinitLoading: boolean;
  pagination: any;
  onLoadMore: any;
  loading: boolean;
}

export default function SearchC({
  fetchSearch,
  searchList,
  infinitLoading,
  pagination,
  onLoadMore,
  loading,
}: SearchCProps) {
  const { search } = useLocation();
  const { q: searchQuery } = QueryString.parse(search);

  useEffect(() => {
    fetchSearch(searchQuery);
    window.fbq('track', 'Search', {
      search_string: searchQuery,
    });
  }, [searchQuery, fetchSearch]);

  if (loading) return null;

  return (
    <div className="search-page magazine-page">
      <div className="container">
        <div className="text-center">
          <h1>
            Résultats de recherche
            {searchQuery ? (
              <>
                {' '}
                de: <strong>{searchQuery}</strong>
              </>
            ) : null}
          </h1>
          {(!searchList || searchList.size <= 0) && (
            <>
              <p className="mb-4">Aucun résultat n'a été trouvé</p>
              <Link to="/" className="btn">
                Page d'accueil
              </Link>
            </>
          )}
        </div>

        {searchList && searchList.size > 0 && (
          <div className="articles">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                if (!infinitLoading) {
                  onLoadMore(searchQuery);
                }
              }}
              hasMore={
                !infinitLoading &&
                pagination &&
                pagination.get('nextPage') &&
                pagination.get('nextPage') !== -1
              }
            >
              <div className="row">
                {searchList?.map(searchItem => {
                  return (
                    <div
                      key={searchItem.get('id')}
                      className="col-lg-4 col-sm-6"
                    >
                      <Link
                        to={`/${searchItem.get('url')}`}
                        className="article-item"
                      >
                        <div className="article-img">
                          <span className="article-topic">
                            {searchItem.get('type')}
                          </span>
                          <img
                            src={searchItem.get('image')}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="article-info">
                          <h4 className="article-title">
                            {searchItem.get('title')}
                          </h4>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
}
