import { Slider, SliderResponse } from '../../types';
import { SliderRecord, SliderResponseRecord } from '../../models';
import { serializeList } from '../../helpers';
import { serializeEvent } from '../event';
import { serializeProduct } from '../product';
import { serializeCampaign } from '../campaign';

/**
 * Serialize slider
 *
 * @param {Slider} slider
 * @returns {SliderRecord}
 */
export const serializeSlider = ({
  campaign,
  event,
  product,
  ...rest
}: Slider): SliderRecord => {
  return new SliderRecord({
    campaign: campaign ? serializeCampaign(campaign) : null,
    event: event ? serializeEvent(event) : null,
    product: product ? serializeProduct(product) : null,
    ...rest,
  });
};

/**
 * Serialize slider response
 *
 * @param {SliderResponse} {
 *   data,
 *   ...rest
 * }
 * @returns {SliderResponseRecord}
 */
export const serializeSliderResponse = ({
  slides,
  ...rest
}: SliderResponse): SliderResponseRecord => {
  return new SliderResponseRecord({
    slides: serializeList(slides || null, serializeSlider),
    ...rest,
  });
};
