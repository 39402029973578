import { Reducer, combineReducers } from 'redux';
import { EventState, eventActionsKeys } from '../types';
import { createSimpleListingStore, createSimpleFetchStore } from '../helpers';
import { EventRecord, EventResponseRecord } from '../models';
import {
  serializeEventsListing,
  serializeEventResponse,
} from '../providers/event';

const eventReducer: Reducer<EventState> = combineReducers<EventState>({
  eventsState: createSimpleListingStore<EventRecord>(
    eventActionsKeys.EVENTS,
    serializeEventsListing,
  ).reducer,
  pastEventsState: createSimpleListingStore<EventRecord>(
    eventActionsKeys.PAST_EVENTS,
    serializeEventsListing,
  ).reducer,
  eventDetailsState: createSimpleFetchStore<EventResponseRecord>(
    eventActionsKeys.EVENT,
    serializeEventResponse,
  ).reducer,
  providerEventsState: createSimpleListingStore<EventRecord>(
    eventActionsKeys.PROVIDER_EVENTS,
    serializeEventsListing,
  ).reducer,
  providerPastEventsState: createSimpleListingStore<EventRecord>(
    eventActionsKeys.PROVIDER_PAST_EVENTS,
    serializeEventsListing,
  ).reducer,
});

export default eventReducer;
