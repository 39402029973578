import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import {
  MagazineDetailsDispatchProps,
  MagazineDetailsStateProps,
} from './types';
import MagazineDetailsC from '../../components/MagazineDetailsC';
import { fetchTopics as fetchTopicsAc } from '../../actions';

interface MagazineDetailsProps
  extends MagazineDetailsDispatchProps,
    MagazineDetailsStateProps {}

const MagazineDetails: FunctionComponent<MagazineDetailsProps> = ({
  article,
  fetchTopics,
}: MagazineDetailsProps) => {
  return <MagazineDetailsC article={article} fetchTopics={fetchTopics} />;
};

/**
* MagazineDetails map state to props
*
* @param {Dispatch} dispatch
@returns {MagazineDetailsDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): MagazineDetailsDispatchProps => {
  return bindActionCreators(
    {
      fetchTopics: fetchTopicsAc,
    },
    dispatch,
  );
};

/**
 * MagazineDetails map state to props
 *
 * @param {AppState} appState
 * @returns {MagazineDetailsStateProps}
 */
const mapStateToProps = (
  appState: AppState,
  { article },
): MagazineDetailsStateProps => {
  return {
    article,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MagazineDetails);
