/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatPrice } from '../../helpers';

interface MyOrdersCProps {
  loading?: boolean;
  getSingleOrder: (id: number) => void;
  orderDetails: any;
}

const MyOrdersC: FunctionComponent<MyOrdersCProps> = ({
  loading,
  getSingleOrder,
  orderDetails,
}: MyOrdersCProps) => {
  const history = useHistory();
  const { id: orderId } = useParams<any>();
  useEffect(() => {
    getSingleOrder(orderId);
  }, [getSingleOrder, orderId]);

  useEffect(() => {
    if (orderDetails?.get('error')?.status === 404) history.replace('/404');
  }, [orderDetails, history]);

  if (loading) return null;

  return (
    <section className="mes-commandes-page">
      <div className="container">
        <div className="box">
          <div className="heading mb-0">
            <h4>Détails de la commande</h4>
          </div>

          {orderDetails?.data?.order && (
            <>
              <ul>
                <li>
                  <strong>Référence :</strong>
                  {orderDetails?.data?.order.reference}
                </li>
                <li>
                  <strong>Etat de la commande : </strong>
                  {orderDetails?.data?.order.status.name}
                </li>
                <li>
                  <strong>Moyen de paiement : </strong>
                  {orderDetails?.data?.order.paymentMethod}
                </li>
                {orderDetails?.data?.order.address && (
                  <li>
                    <strong>Adresse :</strong>
                    {orderDetails?.data?.order.address}
                  </li>
                )}
              </ul>

              {/* Products */}
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Produit </th>
                    <th>Prix unitaire</th>
                    <th>Quantité </th>
                    <th>Total </th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.data?.order?.lines?.map(line => (
                    <tr>
                      <td className="details">
                        <div className="clearfix">
                          <div className="pull-left product-details">
                            <span className="title">{line.productName}</span>
                          </div>
                        </div>
                      </td>
                      <td className="unit-price">
                        {formatPrice(line.productPrice)}
                      </td>
                      <td className="qty text-center">
                        <strong>x{line.quantity}</strong>
                      </td>
                      <td className="total-price">
                        {formatPrice(line.totalPrice)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3}>Sous-total </td>
                    <td className="price">
                      {formatPrice(orderDetails?.data?.order.totalProducts)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Frais de livraison</td>
                    <td className="price">
                      {formatPrice(orderDetails?.data?.order.totalShipping)}
                    </td>
                  </tr>
                  <tr>
                    <td className="cart-total" colSpan={3}>
                      Montant global
                    </td>
                    <td className="cart-total price">
                      {formatPrice(orderDetails?.data?.order.totalPaid)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default MyOrdersC;
