import { Reducer } from 'redux';
import { handleActions, Action } from 'redux-actions';
import { Record } from 'immutable';
import {
  ConnectionStateRecord,
  ConnectionState,
  ConnectionActions,
  ConnectionUpdatedPayload,
  SetTokenToStorePaylod,
  ConnectionPayloads,
  UserUpdatedPayload,
  ToggleModalConnectionPayload,
} from '../types';
import { createAuthAction } from '../helpers';
import { serializeUser } from '../providers';

/**
 * Create Connection state reducer
 *
 * @returns {Reducer<
 *   ConnectionStateRecord,
 *   ConnectionActions
 * >}
 */
const createReducer = (): Reducer<ConnectionStateRecord, ConnectionActions> => {
  const connectionUpdated = createAuthAction<ConnectionUpdatedPayload>(
    '@@authentication/CONNECTION_UPDATED',
  );
  const setTokenToStore = createAuthAction<SetTokenToStorePaylod>(
    '@@authentication/SET_TOKEN_TO_STORE',
  );

  const userUpdated = createAuthAction<UserUpdatedPayload>(
    '@@authentication/USER_UPDATED',
  );

  const authLoadingStart = createAuthAction<void>(
    '@@authentication/AUTH_LOADING_START',
  );

  const authLoadingEnd = createAuthAction<void>(
    '@@authentication/AUTH_LOADING_END',
  );

  const toggleConnectionModal = createAuthAction<ToggleModalConnectionPayload>(
    '@@authentication/TOGGLE_MODAL_CONNECTION',
  );

  const initData: ConnectionState = {
    isConnected: false,
    user: null,
    token: undefined,
    authLoading: true,
    modalIsVisible: false,
  };

  const Connection = Record<ConnectionState>(initData);
  const newConnection: ConnectionStateRecord = new Connection(initData);

  const reducer: Reducer<ConnectionStateRecord, ConnectionActions> =
    handleActions<ConnectionStateRecord, ConnectionPayloads>(
      {
        [connectionUpdated]: (
          state: ConnectionStateRecord,
          { payload }: Action<ConnectionUpdatedPayload>,
        ) => state.set('isConnected', payload.isConnected),
        [setTokenToStore]: (
          state: ConnectionStateRecord,
          { payload }: Action<SetTokenToStorePaylod>,
        ) => state.set('token', payload.token),
        [userUpdated]: (
          state: ConnectionStateRecord,
          { payload }: Action<UserUpdatedPayload>,
        ) => {
          const { user } = payload;
          return state.set('user', user ? serializeUser(user) : null);
        },
        [authLoadingStart]: (state: ConnectionStateRecord) =>
          state.set('authLoading', true),
        [authLoadingEnd]: (state: ConnectionStateRecord) =>
          state.set('authLoading', false),
        [toggleConnectionModal]: (
          state: ConnectionStateRecord,
          { payload }: Action<ToggleModalConnectionPayload>,
        ) => {
          const { modalIsVisible } = state;
          return state.set(
            'modalIsVisible',
            payload ? payload.isVisible : !modalIsVisible,
          );
        },
      },
      newConnection,
    );

  return reducer;
};

export default createReducer();
