import { Reducer, combineReducers } from 'redux';
import { searchActionKeys, SearchState } from '../types';
import { createSimpleListingStore } from '../helpers';
import { SearchRecord } from '../models';
import { serializeSearchsListing } from '../providers/search';

const searchReducer: Reducer<SearchState> = combineReducers<SearchState>({
  searchListingState: createSimpleListingStore<SearchRecord>(
    searchActionKeys.SEARCH,
    serializeSearchsListing,
  ).reducer,
});

export default searchReducer;
