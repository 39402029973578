/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import CartItem from '../../CartItem';

interface CartItemsFieldArrayProps {
  items?: any[] | null;
  fields: any[] | null;
}

const CartItemsFieldArray: FunctionComponent<any> = ({
  items,
  fields,
}: CartItemsFieldArrayProps) => {
  return (
    <>
      {fields.map((field, index) => (
        <CartItem
          field={field}
          item={items[index]}
          key={`cartItem${index}`}
          index={index}
        />
      ))}
    </>
  );
};

export default CartItemsFieldArray;
