/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { call, put, takeEvery } from 'redux-saga/effects';
import { SimpleActions, SimpleListingRequestPayload } from './actions-types';
import {
  getKeyFromSimpleActionType,
  getKeyFromSimpleListingActionType,
} from './utils';
import {
  simpleFetchRefreshingStart,
  simpleFetchLoadingStart,
  simpleFetchSuccess,
  simpleFetchRefreshingEnd,
  simpleFetchLoadingEnd,
  simpleFetchFailed,
  simpleListingInfinitLoadingStart,
  simpleListingRefreshingStart,
  simpleListingLoadingStart,
  simpleListingSuccess,
  simpleListingInfinitLoadingEnd,
  simpleListingRefreshingEnd,
  simpleListingLoadingEnd,
  simpleListingFailed,
} from './actions';
import { callApi } from '../../providers';

/**
 * Simple fetch Get api data
 *
 * @param {SimpleActions<string, any>} {
 *   type,
 *   meta,
 *   suppData
 * }
 */
function* simpleFetchGetApiData({
  type,
  meta,
  suppData,
}: SimpleActions<string, any>) {
  const key: string = getKeyFromSimpleActionType(type, 'REQUEST');
  try {
    if (meta?.refresh) {
      yield put(simpleFetchRefreshingStart(key));
    } else {
      yield put(simpleFetchLoadingStart(key));
    }

    if (meta) {
      const data: any = yield call(callApi, meta);
      yield put(simpleFetchSuccess(key, data, suppData));
      if (meta.refresh) {
        yield put(simpleFetchRefreshingEnd(key));
      } else {
        yield put(simpleFetchLoadingEnd(key));
      }

      if (meta.successCallback) {
        meta.successCallback();
      }
    }
  } catch (error) {
    yield put(simpleFetchFailed(key, error, suppData));
    if (meta?.refresh) {
      yield put(simpleFetchRefreshingEnd(key));
    } else {
      yield put(simpleFetchLoadingEnd(key));
    }
    if (meta) {
      if (meta.failedCallback) {
        meta.failedCallback();
      }
    }
  }
}

/**
 * Simple listing Get api data
 *
 * @param {SimpleActions<string, SimpleListingRequestPayload>} {
 *   type,
 *   meta,
 *   payload
 * }
 */
function* simpleListingGetApiData({
  type,
  meta,
  payload,
}: SimpleActions<string, SimpleListingRequestPayload>) {
  const key: string = getKeyFromSimpleListingActionType(type, 'REQUEST');
  const { loadMore }: SimpleListingRequestPayload = payload;
  try {
    if (meta) {
      if (loadMore) {
        yield put(simpleListingInfinitLoadingStart(key));
      } else if (meta.refresh) {
        yield put(simpleListingRefreshingStart(key));
      } else {
        yield put(simpleListingLoadingStart(key));
      }
      const data: any = yield call(callApi, meta);
      const newData: any = {
        ...data,
        loadMore,
      };
      yield put(simpleListingSuccess(key, newData));
      if (loadMore) {
        yield put(simpleListingInfinitLoadingEnd(key));
      } else if (meta.refresh) {
        yield put(simpleListingRefreshingEnd(key));
      } else {
        yield put(simpleListingLoadingEnd(key));
      }
      if (meta.successCallback) {
        meta.successCallback();
      }
    }
  } catch (error) {
    yield put(simpleListingFailed(key, error));
    if (loadMore) {
      yield put(simpleListingInfinitLoadingEnd(key));
    } else if (meta?.refresh) {
      yield put(simpleListingRefreshingEnd(key));
    } else {
      yield put(simpleListingLoadingEnd(key));
    }

    if (meta?.failedCallback) {
      meta.failedCallback();
    }
  }
}

export function* simpleFetchSaga() {
  yield takeEvery(
    (action: SimpleActions<string, any>) =>
      /@@simple_fetch\/(.*)_REQUEST$/.test(action.type),
    simpleFetchGetApiData,
  );

  yield takeEvery(
    (action: SimpleActions<string, any>) =>
      /@@simple_listing\/(.*)_REQUEST$/.test(action.type),
    simpleListingGetApiData,
  );
}
