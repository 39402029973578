import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';
import { InputTextField } from '../../common/fields';
import { SignInFormProps } from '../types';

interface NewsletterViewProps extends SignInFormProps {
  handleSubmit: (formData: any) => void;
}

const NewsletterView: FunctionComponent<NewsletterViewProps> = ({
  handleSubmit,
}: NewsletterViewProps) => {
  return (
    <div className="newsletter">
      <h3>Inscrivez-vous à la newsletter Guichet</h3>
      <p>
        Vous soyez alertés en amont des meilleurs plans, spectacles, concerts,
        festivals, etc
      </p>
      <form className="d-flex" noValidate onSubmit={handleSubmit}>
        <Field
          component={InputTextField}
          name="email"
          isRequired
          id="in-email-newsletter"
          type="email"
          placeholder="Adresse email"
          className="email"
        />
        <input type="submit" value="S'INSCRIRE" />
      </form>
    </div>
  );
};
export default NewsletterView;
