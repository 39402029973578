import { Record } from 'immutable';
import {
  ArticleForRec,
  ArticleResponseForRec,
  ArticlesListingForRec,
  TopicForRec,
  TopicsListingForRec,
} from '../types/app/magazine';

/**
 * Article record class
 *
 * @export
 * @class ArticleRecord
 * @extends {Record<ArticleForRec>()}
 */
export class ArticleRecord extends Record<ArticleForRec>(
  {
    id: 0,
    title: null,
    content: null,
    image: null,
    createdAt: null,
    slug: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
    tags: null,
    topic: null,
  },
  'article',
) {}

/**
 * Articles list record
 *
 * @export
 * @class ArticlesListRecord
 * @extends {Record<ArticlesListingForRec>()}
 */
export class ArticlesListRecord extends Record<ArticlesListingForRec>(
  {
    list: null,
    success: false,
    pagination: null,
    loadMore: false,
  },
  'articles-list',
) {}

/**
 * Article details record details response
 *
 * @export
 * @class ArticleResponseRecord
 * @extends {Record<ArticleResponseForRec>()}
 */
export class ArticleResponseRecord extends Record<ArticleResponseForRec>(
  {
    success: false,
    article: null,
  },
  'article-response',
) {}

/**
 * Topic record class
 *
 * @export
 * @class TopicRecord
 * @extends {Record<TopicForRec>()}
 */
export class TopicRecord extends Record<TopicForRec>(
  {
    id: 0,
    title: null,
    createdAt: null,
    slug: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
    articles: null,
  },
  'topic',
) {}

/**
 * Topics list record
 *
 * @export
 * @class TopicsListRecord
 * @extends {Record<TopicsListingForRec>()}
 */
export class TopicsListRecord extends Record<TopicsListingForRec>(
  {
    list: null,
    success: false,
    pagination: null,
  },
  'topics-list',
) {}
