import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';
// import Icon from 'react-remixicon';
// import { Link } from 'react-router-dom';
import { InputTextField } from '../../common/fields';

const PromoFormView: FunctionComponent<any> = ({
  codePromo,
  applyDiscount,
  clearDiscount,
  discount,
}: any) => {
  return (
    <div className="box code-promo mb-3">
      <h4>
        Code promotionnel
        {/* <Link to="/">
          <Icon name="question" type="line" />
        </Link> */}
      </h4>
      <div className="d-flex">
        <Field
          name="code_promo"
          component={InputTextField}
          className="ps-0 pe-0"
          type="text"
          placeholder="Saisisser votre code"
        />
        {!discount ? (
          <button
            onClick={() => {
              applyDiscount(codePromo);
            }}
          >
            ok
          </button>
        ) : (
          <button
            onClick={() => {
              clearDiscount();
            }}
          >
            effacer
          </button>
        )}
      </div>
    </div>
  );
};

export default PromoFormView;
