/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import CartBenefitFields from './cart-benefit-fields';

interface CartBenefitsFieldArrayProps {
  item: any;
  fields: any[] | null;
  apply?: string;
}

const CartBenefitsFieldArray: FunctionComponent<any> = ({
  fields,
  item,
  apply,
}: CartBenefitsFieldArrayProps) => {
  return (
    <>
      {!!fields &&
        item?.fields?.length > 0 &&
        fields
          .map((field, index) => (
            <div className="mb-2" key={index}>
              <label>Bénéficiaire - {index + 1}</label>
              <CartBenefitFields
                benefitField={field}
                fields={item.fields}
                key={`benefits-field-array-${index}`}
              />
            </div>
          ))
          .filter((_, index) => (apply === 'yes' ? index === 0 : index !== -1))}
    </>
  );
};

export default CartBenefitsFieldArray;
