/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'react-remixicon';
import AccountModal from '../AccountModal';
import { ToggleModalConnectionPayload } from '../../types';
import Alert from './Alert';

interface MenuProps {
  isConnected: boolean;
  connectionModalIsVisible: boolean;
  toggleModalConnection: (payload?: ToggleModalConnectionPayload) => void;
  onSignOutPressed: (callback: any) => void;
  loadingAuth?: boolean;
}

const Menu: FunctionComponent<MenuProps> = ({
  isConnected,
  connectionModalIsVisible,
  toggleModalConnection,
  onSignOutPressed,
  loadingAuth,
}: MenuProps) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const toggleAccountModal = () => {
    toggleModalConnection();
    setIsMenuVisible(false);
    document.body.style.overflow = !toggleModalConnection ? 'hidden' : 'auto';
  };

  const showMenu = () => {
    setIsMenuVisible(true);
  };
  const hideMenu = () => {
    setIsMenuVisible(false);
  };

  const logout = () => {
    setIsMenuVisible(false);
    onSignOutPressed(() => history.push('/'));
  };

  return (
    <>
      <AccountModal
        isAccountModal={connectionModalIsVisible && !isConnected}
        closeAccountModal={toggleAccountModal}
        loading={loadingAuth}
      />
      <div
        className="hamburger-menu"
        onMouseEnter={showMenu}
        onMouseLeave={hideMenu}
      >
        {isVisible && <Alert />}
        <button className={`menu-btn ${isMenuVisible ? 'opened' : null}`}>
          <svg width="50" height="50" viewBox="0 0 100 100">
            <path
              className="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path className="line line2" d="M 20,50 H 80" />
            <path
              className="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
          <span>Menu</span>
        </button>

        {isMenuVisible && (
          <div className="hamburger-menu-c">
            <ul>
              <li>
                <Link to="/">
                  <Icon name="home-3" type="line" />
                  Accueil
                </Link>
              </li>
            </ul>
            {!isConnected && (
              <ul>
                <li>
                  <button onClick={toggleAccountModal}>
                    <Icon name="account-pin-box" type="line" />
                    Se connecter
                  </button>
                </li>
              </ul>
            )}
            <ul>
              {!!isConnected && (
                <li>
                  <Link to="/profile">
                    <Icon name="account-pin-box" type="line" />
                    Mon compte
                  </Link>
                </li>
              )}
              <li>
                <Link to="/mes-commandes">
                  <Icon name="inbox-archive" type="line" />
                  Mes commandes
                </Link>
              </li>
              <li>
                <Link to="/mon-solde">
                  <Icon name="inbox-archive" type="line" />
                  Mon solde
                </Link>
              </li>
              {/* <li>
                <Link to="/login">
                  <Icon name="notification" type="line" />
                  Mes communications
                </Link>
              </li> */}
            </ul>
            <ul>
              <li>
                <Link to="/contact" onClick={() => setIsVisible(true)}>
                  <Icon name="mail-open" type="line" />
                  Aide et contact
                </Link>
              </li>
            </ul>
            {isConnected && (
              <ul>
                <li>
                  <button onClick={logout}>
                    <Icon name="shut-down" type="line" />
                    Déconnexion
                  </button>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
