import React, { useState, useEffect } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState, Country } from '../types';
import { destroyCart as destroyCartAC, fetchGlobalCountries } from '../actions';
import {
  getStorageData,
  STORAGE_COUNTRY,
  setStorageData,
  STORAGE_COUNTRIES,
  removeStorageData,
  STORAGE_TOKEN,
  STORAGE_USER,
} from '../helpers';

interface CountryDropdownProps {
  countries?: Country[];
  destroyCart?: (successCallback?: () => void) => void;
  // fetchCountries?: (successCallback?: () => void) => void;
}

const CountryDropdown = (props: CountryDropdownProps) => {
  const { countries, destroyCart } = props;

  const [stabelCountries, setStableCountries] = useState<Country[] | undefined>(
    [],
  );

  const [selectedCountry, setSelectedCountry] = useState<
    Country | undefined | null
  >();
  const [gridVisiblity, setGridVisibility] = useState<boolean>(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setGridVisibility(false),
  });

  useEffect(() => {
    const localCountries = getStorageData(STORAGE_COUNTRIES, true);
    const localCountry = getStorageData(STORAGE_COUNTRY, true);
    if (localCountry) {
      setSelectedCountry(localCountry);
    }
    if (localCountries) {
      setStableCountries(localCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countries) {
      setStableCountries(countries);
      const localCountry = getStorageData(STORAGE_COUNTRY, true);
      setStorageData(STORAGE_COUNTRIES, countries);
      if (!localCountry) {
        const foundCountry = countries.find(country => country.isDefault);
        setSelectedCountry(foundCountry);
        setStorageData(STORAGE_COUNTRY, foundCountry);
      } else {
        setSelectedCountry(localCountry);
      }
    }
  }, [countries]);

  const resetOnCountryChanged = () => {
    destroyCart(() => {
      removeStorageData(STORAGE_USER);
      window.location.href = '/';
    });
  };

  const onSelectedCountry = (index: number) => {
    setGridVisibility(false);
    if (selectedCountry?.id !== stabelCountries[index]?.id) {
      setSelectedCountry(stabelCountries[index]);
      setStorageData(STORAGE_COUNTRY, stabelCountries[index]);
      resetOnCountryChanged();
    }
  };

  return (
    <div className="country-dropdown" ref={ref}>
      <label>Vous êtes à l’étranger ?</label>
      <div />
      <button
        className="d-flex flex-row align-items-center justify-content-between"
        onClick={() => setGridVisibility(!gridVisiblity)}
      >
        <div className="d-flex flex-row align-items-center">
          <img src={selectedCountry?.flag} alt="country-select" />
          <span className="pl-3">{selectedCountry?.name}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="32"
          height="32"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
      </button>
      {gridVisiblity && (
        <div className="country-grid mr-0 ml-0">
          {stabelCountries?.map((ctry, index) => (
            <button
              className="d-flex flex-row align-items-center col-6 col-lg-6"
              onClick={() => onSelectedCountry(index)}
              key={`global-country-item-${ctry.id}`}
            >
              <img src={ctry.flag} alt="country-select" />
              <span className="pl-3">{ctry.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      fetchCountries: fetchGlobalCountries,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

const mapStateToProps = ({ globalCountries }: AppState): any => {
  return {
    countries: globalCountries?.get('data')?.countries,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryDropdown);
