import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getCartInstruction } from '../../selectors';
import { setInstruction as setInstructionAc } from '../../actions';
import CartConfirmationC from '../../components/pages/CartConfirmation';

const CartConfirmation: FunctionComponent<any> = ({
  setInstruction,
  instruction,
}: any) => {
  useEffect(() => {
    return () => {
      setInstruction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CartConfirmationC instruction={instruction} />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      setInstruction: setInstructionAc,
    },
    dispatch,
  );
};

const mapStateToProps = (appState: any): any => {
  return {
    instruction: getCartInstruction(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartConfirmation);
