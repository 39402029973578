/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../helpers';

const { REACT_APP_API_BASE_URL } = process.env;

interface MyOrdersCProps {
  loading?: boolean;
  orders?: any;
  getMyOrders?: () => void;
  token?: string;
}

const MyOrdersC: FunctionComponent<MyOrdersCProps> = ({
  loading,
  orders,
  getMyOrders,
  token,
}: MyOrdersCProps) => {
  useEffect(() => {
    getMyOrders();
  }, [getMyOrders]);

  const downloadOrder = (orderId: number) => {
    if (token)
      window.location.href = `${REACT_APP_API_BASE_URL}orders/${orderId}/download?token=${token}`;
  };

  if (loading) return null;

  return (
    <section className="mes-commandes-page">
      <div className="container">
        <div className="box mb-3">
          {orders && orders.size > 0 ? (
            <>
              <div className="heading mb-0">
                <h4>Mes commandes</h4>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Référence</th>
                    <th scope="col">Date</th>
                    <th scope="col">Etat</th>
                    <th scope="col">Mode de paiement</th>
                    <th scope="col">Total</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {orders.map(o => (
                    <tr key={o.id}>
                      <td>{o.reference}</td>
                      <td>{o.createdAt}</td>
                      <td>{o.status.name}</td>
                      <td>{o.paymentMethod}</td>
                      <td>{formatPrice(o.totalPaid)}</td>
                      <td className="text-right">
                        {o.isValid && (
                          <button
                            onClick={() => downloadOrder(o.id)}
                            className="blue"
                          >
                            Télécharger mes tickets
                          </button>
                        )}
                        <Link to={`mes-commandes/${o.id}`} className="green">
                          Voir détails
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <div className="heading mb-0">
                <h4>Aucune commande n'a été trouvée</h4>
              </div>
              <div className="mt-5 text-center">
                <Link to="/" className="btn">
                  Retourner à la page d'accueil
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default MyOrdersC;
