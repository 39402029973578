import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RecoverPassword } from '../../containers';
import { SECURE_ROUTES_PATHS } from '../../helpers';

export default function RecoverPasswordPage({ isConnected }: any) {
  const history = useHistory();

  useEffect(() => {
    if (isConnected) history.replace(SECURE_ROUTES_PATHS.profil);
  }, [isConnected]);

  return (
    <div className="cart">
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6">
            <RecoverPassword />
          </div>
        </div>
      </div>
    </div>
  );
}
