import React, { FunctionComponent, useEffect } from 'react';
import { Field } from 'redux-form';
import { required } from '../../../helpers';
import { InputTextField } from '../../common/fields';
import LoadingPopup from '../../common/LoadingPopup';
import { ProfilUpdateFormProps } from '../types';

interface ProfilUpdateViewProps extends ProfilUpdateFormProps {
  handleSubmit: (data: any) => void;
  initialize: (data: any) => void;
  user?: any;
  loading: boolean;
}

const ProfilUpdate: FunctionComponent<ProfilUpdateViewProps> = ({
  handleSubmit,
  initialize,
  user,
  loading,
}: any) => {
  useEffect(() => {
    if (user) {
      initialize({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
      });
    }
  }, [user, initialize]);
  return (
    <>
      <LoadingPopup loading={loading} />
      <form className="updateProfileForm" noValidate onSubmit={handleSubmit}>
        <div className="row">
          <Field
            name="lastName"
            component={InputTextField}
            type="text"
            placeholder="Nom"
            className="col-md-3 col-sm-6 mt-2"
            validate={required}
          />
          <Field
            name="firstName"
            component={InputTextField}
            type="text"
            placeholder="Prénom"
            className="col-md-3 col-sm-6 mt-2"
            validate={required}
          />
          <Field
            name="email"
            component={InputTextField}
            type="email"
            placeholder="Email"
            className="col-md-3 col-sm-6 mt-2"
            validate={required}
          />
          <Field
            name="phone"
            component={InputTextField}
            type="text"
            placeholder="Téléphone"
            className="col-md-3 col-sm-6 mt-2"
            validate={required}
          />
        </div>
        <div>
          <button type="submit">Modifier</button>
        </div>
      </form>
    </>
  );
};

export default ProfilUpdate;
