import React from 'react';
import { Controller } from 'react-hook-form';
import { FormField } from './types';

export function Field({ id, type, placeholder, ...rest }: FormField) {
  return (
    <Controller
      {...rest}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <div className="g-input mb-0 text-left">
          <input
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            style={{ height: 50 }}
            type={type}
            placeholder={placeholder}
            onKeyPress={
              type === 'number'
                ? event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                : undefined
            }
          />
          {error && <span className="text-danger mt-1">{error.message}</span>}
        </div>
      )}
    />
  );
}
