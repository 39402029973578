import { serializeList } from '../../helpers';
import {
  UserRecord,
  MyOrdersResponseRecord,
  OrderRecord,
  AddressRecord,
  OrderResponseRecord,
  AddressesResponseRecord,
} from '../../models';
import {
  Order,
  User,
  Address,
  OrdersListing,
  OrderResponse,
  AddressesListing,
} from '../../types';

/**
 * Serialize user
 *
 * @param {User} user
 * @returns {UserRecord}
 */
export const serializeUser = (user: User): UserRecord => {
  return new UserRecord(user);
};

/**
 * Serialize order
 *
 * @param {Order} order
 * @returns {OrderRecord}
 */
export const serializeOrder = (order: Order): OrderRecord => {
  return new OrderRecord(order);
};

export const serializeOrdersListing = ({
  orders,
}: OrdersListing): MyOrdersResponseRecord => {
  return new MyOrdersResponseRecord({
    list: serializeList(orders, serializeOrder),
  });
};

export const serializeOrderResponse = ({
  order,
  ...rest
}: OrderResponse): OrderResponseRecord => {
  return new OrderResponseRecord({
    order: order ? serializeOrder(order) : null,
    ...rest,
  });
};

/**
 * Serialize address
 *
 * @param {Address} address
 * @returns {AddressRecord}
 */
export const serializeAddress = (address: Address): AddressRecord => {
  return new AddressRecord(address);
};

export const serializeAddressesListing = ({
  addresses,
}: AddressesListing): AddressesResponseRecord => {
  return new AddressesResponseRecord({
    list: serializeList(addresses, serializeAddress),
  });
};
