import { ActionCreator } from 'redux';
import {
  ProfilUpdatePostData,
  ConfigCallApi,
  ApiRoutes,
  NewsletterPostData,
} from '../providers';
import { NewsletterFormData, ProfilUpdateFormData } from '../components/forms';
import { orderActionKeys, userActionKeys } from '../types';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
  simpleListingRequest,
} from '../helpers';

/**
 * Fetch Profil action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchProfil: ActionCreator<SimpleActions> = (
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.user,
    secure: true,
    callGlobalError: false,
    refresh,
  };
  return simpleFetchRequest(userActionKeys.PROFIL, confiApi);
};

/**
 * Reset Profil action creator
 *
 * @returns {SimpleActions}
 */
export const resetProfil: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(userActionKeys.PROFIL);

/**
 * Reset Profil messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetProfilMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(userActionKeys.PROFIL);

export const updateProfil: ActionCreator<SimpleActions> = ({
  firstName,
  lastName,
  email,
  phone,
}: ProfilUpdateFormData): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.updateUser,
    secure: true,
    callGlobalError: false,
    method: 'post',
    postData: {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
    } as ProfilUpdatePostData,
  };
  return simpleFetchRequest(userActionKeys.PROFIL_UPDATE, confiApi);
};

export const resetProfilUpdate: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchReset(userActionKeys.PROFIL_UPDATE);

export const resetProfilUpdateMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(userActionKeys.PROFIL_UPDATE);

/**
 * Get My Orders
 *
 * @returns {SimpleActions}
 */
export const getMyOrders: ActionCreator<SimpleActions> = (): SimpleActions => {
  const meta: ConfigCallApi = {
    url: ApiRoutes.getOrders,
    method: 'GET',
    callGlobalError: false,
    secure: true,
  };
  return simpleListingRequest(orderActionKeys.MY_ORDERS, meta);
};

/**
 * Get Single Order
 *
 * @returns {SimpleActions}
 */
export const getSingleOrder: ActionCreator<SimpleActions> = (
  orderId: number,
): SimpleActions => {
  const meta: ConfigCallApi = {
    url: `${ApiRoutes.getOrders}/${orderId}`,
    method: 'GET',
    callGlobalError: false,
    secure: true,
  };
  return simpleFetchRequest(orderActionKeys.DETAIL_ORDER, meta);
};

/**
 * Newsletter action creator
 */
export const subscribeToNewsletter: ActionCreator<SimpleActions> = ({
  email,
}: NewsletterFormData): SimpleActions => {
  const postData: NewsletterPostData = {
    email,
  };
  const meta: ConfigCallApi = {
    url: ApiRoutes.newsletters,
    method: 'POST',
    postData,
    callGlobalError: false,
  };
  return simpleFetchRequest(userActionKeys.NEWSLETTER_SUBSCRIBE, meta);
};

/**
 * Reset newsletters messages
 *
 * @returns {SimpleActions}
 */
export const resetNewslettersMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(userActionKeys.NEWSLETTER_SUBSCRIBE);
