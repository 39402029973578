import { Record } from 'immutable';
import { ThemesResponseForRec, ThemeForRec } from '../types';

export class ThemeRecord extends Record<ThemeForRec>(
  {
    id: 0,
    title: null,
    image: null,
    slug: null,
    sliderItems: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
  },
  'theme',
) {}

export class ThemesResponseRecord extends Record<ThemesResponseForRec>(
  {
    themes: null,
    success: false,
  },
  'themes-response',
) {}
