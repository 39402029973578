import React, { useEffect } from 'react';
import { PaymentMethod } from '../../../types';

type Props = {
  onSelectedPayment: (payment: PaymentMethod) => void;
  selectedPayment?: PaymentMethod;
  payments: PaymentMethod[];
};

export function PaymentPicker({
  onSelectedPayment,
  selectedPayment,
  payments,
}: Props) {
  useEffect(() => {
    if (payments.length > 0) {
      onSelectedPayment(payments[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments]);

  return (
    <div className="box mode-paiement mb-3 pb-1">
      <div className="heading text-left">
        <h4>Mode de paiement</h4>
        <p>Sélectionez votre moyen de paiement</p>
      </div>

      {payments.map(payment => (
        <div
          key={`payment-${payment.id}`}
          className="mb-4  text-dark text-left"
        >
          <div className="">
            <label className="radio-container mr-3 text-dark text-left">
              <strong>{payment.title}</strong>
              <input
                type="radio"
                id={`radio${payment.id}`}
                value={payment.id}
                name="payment-methods"
                onChange={() => onSelectedPayment(payment)}
                checked={selectedPayment?.id === payment.id}
              />
              <span className="checkmark" />
            </label>
          </div>
          {/* {selectedPayment?.id === payment.id && payment.description && (
            <div
              className="hint mt-2 pl-4"
              dangerouslySetInnerHTML={{
                __html: payment.description,
              }}
            />
          )} */}
        </div>
      ))}
    </div>
  );
}
