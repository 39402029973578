import { Record } from 'immutable';
import { CategoriesResponseForRec, CategoryForRec } from '../types';

/**
 * Categry record
 *
 * @class CategoryRecord
 * @extends {Record<Category>(null, 'category')}
 */
export class CategoryRecord extends Record<CategoryForRec>(
  {
    id: 0,
    title: null,
    image: null,
    slug: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
    sliderItems: null,
  },
  'category',
) {}

/**
 * Caetgories response record
 *
 * @export
 * @class CategoriesResponseRecord
 * @extends {Record<CategoriesResponseForRec>()}
 */
export class CategoriesResponseRecord extends Record<CategoriesResponseForRec>(
  {
    categories: null,
    success: false,
  },
  'categories-response',
) {}
