import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SocialButtonsDispatchProps, SocialButtonsStateProps } from './types';
import {
  getSocialSignInSuccess,
  getSocialSignInError,
  getSocialSignInLoading,
} from '../../selectors';
import {
  socialSignIn as socialSignInAC,
  resetSocialSignIn as resetSocialSignInAC,
  resetSocialSignInMessages as resetSocialSignInMessagesAC,
} from '../../actions';
import SocialButtonsC from '../../components/SocialButtons';
import { AppState } from '../../types';

interface SocialButtonsProps
  extends SocialButtonsDispatchProps,
    SocialButtonsStateProps {}

const SocialButtons: FunctionComponent<SocialButtonsProps> = ({
  socialSignIn,
}: SocialButtonsProps) => {
  return <SocialButtonsC socialSignIn={socialSignIn} />;
};

/**
* SocialButtons map state to props
*
* @param {Dispatch} dispatch
@returns {SocialButtonsDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): SocialButtonsDispatchProps => {
  return bindActionCreators(
    {
      socialSignIn: socialSignInAC,
      resetSocialSignIn: resetSocialSignInAC,
      resetSocialSignInMessages: resetSocialSignInMessagesAC,
    },
    dispatch,
  );
};

/**
 * SocialButtons map state to props
 *
 * @param {AppState} appState
 * @returns {SocialButtonsStateProps}
 */
const mapStateToProps = (appState: AppState): SocialButtonsStateProps => {
  return {
    success: getSocialSignInSuccess(appState),
    error: getSocialSignInError(appState),
    loading: getSocialSignInLoading(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialButtons);
