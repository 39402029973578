import { Record } from 'immutable';
import { TermForRec } from '../types';

export class TermRecord extends Record<TermForRec>(
  {
    id: 0,
    title: null,
    slug: null,
    subTerms: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
  },
  'term',
) {}
