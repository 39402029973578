import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { AppState } from '../../../types';
import AddressesFormView from './addressesView';
import {
  getAdresses as getAdressesAc,
  getCountries as getCountriesAc,
  getCities as getCitiesAc,
  addAddress as addAddressAc,
  deleteAddress as deleteAddressAc,
  updateAddress as updateAddressAc,
  addShippingAddress as addShippingAddressAc,
  addressesResetMessages as addressesResetMessagesAc,
} from '../../../actions';
import {
  getCountries,
  getCities,
  getAdresses,
  getCartSelectedAddressId,
  getCart,
  getIsConnected,
  getUser,
  getAdressesLoading,
  getAdressesSuccessMessage,
  getAdressesError,
} from '../../../selectors';

const selector = getFormValues('AddressesForm');

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      getAdresses: getAdressesAc,
      getCountries: getCountriesAc,
      getCities: getCitiesAc,
      addAddress: addAddressAc,
      deleteAddress: deleteAddressAc,
      updateAddress: updateAddressAc,
      addShippingAddress: addShippingAddressAc,

      addressesResetMessages: addressesResetMessagesAc,
    },
    dispatch,
  );
};

const mapStateToProps = (appState: AppState, { isCart = false }) => {
  return {
    countries: getCountries(appState),
    cities: getCities(appState),
    addressFormData: selector(appState),
    adresses: getAdresses(appState),
    selectedAddressId: getCartSelectedAddressId(appState),
    carriers: getCart(appState).carriers,
    isCart,
    user: getUser(appState),
    isConnected: getIsConnected(appState),

    loading: getAdressesLoading(appState),
    successMessage: getAdressesSuccessMessage(appState),
    errorAd: getAdressesError(appState),
  };
};

const AddressesComposedForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'AddressesForm',
  }),
)(AddressesFormView);

export default AddressesComposedForm;
