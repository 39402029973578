import { ActionCreator } from 'redux';
import { SimpleActions, simpleListingRequest } from '../helpers';
import { ConfigCallApi, ApiRoutes, ArticleParams } from '../providers';
import { articleActionKeys } from '../types';

/**
 * Fetch articles
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchArticles: ActionCreator<SimpleActions> = (
  topicId?: number,
  page?: number,
  loadMore?: boolean,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.getArticles,
    callGlobalError: false,
    refresh,
    params: { topic_id: topicId, page } as ArticleParams,
  };
  return simpleListingRequest(articleActionKeys.ARTICLES, confiApi, loadMore);
};

/**
 * Fetch topics
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchTopics: ActionCreator<SimpleActions> = (): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.getTopics,
    callGlobalError: false,
  };
  return simpleListingRequest(articleActionKeys.TOPICS, confiApi);
};
