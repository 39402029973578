import React, { FunctionComponent, useEffect } from 'react';
import { MagazineDetails } from '../../containers';
import AppMeta from '../common/AppMeta';

interface MagazineDetailsPageProps {
  article: any;
}

const MagazineDetailsPage: FunctionComponent<MagazineDetailsPageProps> = ({
  article,
}: MagazineDetailsPageProps) => {
  useEffect(() => {
    if (article) {
      window.fbq('track', 'ViewContent', {
        content_type: 'article',
        content_name: article.title,
        content_category: article.topic?.title,
      });
    }
  }, [article]);

  return (
    <>
      <AppMeta
        title={article.get('metaTitle')}
        description={article.get('metaDescription')}
        keywords={article.get('metaKeywords')}
        prefix="Guichet Magazine"
      />
      <MagazineDetails article={article} />
    </>
  );
};

export default MagazineDetailsPage;
