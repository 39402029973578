/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SignUpForm, SignUpFormData } from '../forms';
import { SocialButtons } from '../../containers';

interface RegistrationProps {
  onSubmit: (data: SignUpFormData) => void;
}

const Registration = ({ onSubmit }: RegistrationProps) => {
  return (
    <section>
      <h5 className="g-title mb-4">Nouveau compte</h5>
      <SignUpForm onSubmit={onSubmit} />
      <h5 className="g-title mt-4 mb-4">
        Ou inscrivez-vous avec vos réseaux sociaux:
      </h5>
      <SocialButtons />
    </section>
  );
};

export default Registration;
