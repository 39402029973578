import React, { FunctionComponent } from 'react';
import { Profil } from '../../containers';
import AppMeta from '../common/AppMeta';

interface ProfilPageProps {}

const ProfilPage: FunctionComponent<ProfilPageProps> = () => {
  return (
    <>
      <AppMeta title="Mon compte" prefix="" />
      <Profil />
    </>
  );
};

export default ProfilPage;
