import { Reducer, combineReducers } from 'redux';
import { ThemeState, themeActionsKeys } from '../types';
import { createSimpleFetchStore } from '../helpers';
import { ThemesResponseRecord } from '../models';
import { serializeThemesResponse } from '../providers';

const themeReducer: Reducer<ThemeState> = combineReducers<ThemeState>({
  themesState: createSimpleFetchStore<ThemesResponseRecord>(
    themeActionsKeys.THEMES,
    serializeThemesResponse,
  ).reducer,
});

export default themeReducer;
