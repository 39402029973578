import { Campaign, Brand, Term, CampaignsResponse } from '../../types';
import {
  CampaignRecord,
  BrandRecord,
  TermRecord,
  CampaignsRecord,
} from '../../models';
import { serializeList } from '../../helpers';
import { serializeTheme } from '../theme';

export const serializeCampaign = ({
  brand,
  theme,
  terms,
  ...rest
}: Campaign): CampaignRecord => {
  const newCampaign = new CampaignRecord({
    brand: brand ? serializeBrand(brand) : null,
    theme: theme ? serializeTheme(theme) : null,
    terms: terms ? serializeList(terms, serializeTerm) : null,
    ...rest,
  });

  return newCampaign;
};

export const serializeBrand = (brand: Brand): BrandRecord => {
  return new BrandRecord(brand);
};

export const serializeTerm = ({ subTerms, ...rest }: Term): TermRecord => {
  return new TermRecord({
    subTerms: subTerms ? serializeList(subTerms, serializeTerm) : null,
    ...rest,
  });
};

export const serializeCampaigns = ({
  campaigns,
  ...rest
}: CampaignsResponse): CampaignsRecord => {
  const newCampaigns = campaigns
    ? serializeList(campaigns, serializeCampaign)
    : null;

  return new CampaignsRecord({
    currentCampaigns: newCampaigns
      ? newCampaigns.filter(camp => !camp.get('isFuture'))
      : null,
    futureCampaigns: newCampaigns
      ? newCampaigns.filter(camp => camp.get('isFuture'))
      : null,
    ...rest,
  });
};
