import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import { SliderRecord } from '../models';
import Bg from '../images/banner/bg.png';
import BottomLine from '../images/banner/bottom-line.png';

interface BannerSliderProps {
  items?: List<SliderRecord> | null;
  banner?: any;
}

const BannerSlider = ({ items, banner }: BannerSliderProps) => {
  const [isDragging, setIsDragging] = useState(false);

  if (!items || items?.size <= 0) {
    return null;
  }

  const SETTINGS: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const onClickFunction = e => {
    if (isDragging) e.preventDefault();
  };

  const renderItems = () => {
    return items.toArray().map(item => {
      const { type, event, campaign, product } = item;
      let url = '';
      let img = '';
      let buttonText = 'Acheter Maintenant';

      switch (type) {
        case 'event':
          url = event.get('isPrivate')
            ? `events/private/${event.get('token')}`
            : event.get('slug');
          img = event.get('slide');
          if (event.get('isSubscription')) {
            buttonText = 'Inscription';
          }
          break;
        case 'campaign':
          url = `/store/${campaign.get('slug')}`;
          img = campaign.get('visual');
          break;
        case 'product':
          url = `/store/${product.get('slug')}`;
          img = product.get('slide');
          break;
        default:
          url = '';
          img = '';
      }

      return (
        <div className="slide-item" key={`slider-item-${item.get('id')}`}>
          <div>
            <Link to={url} onClick={onClickFunction}>
              <img className="slider-img" src={img} alt={item.get('title')} />
            </Link>
            <Link onClick={onClickFunction} className="buy" to={url}>
              {buttonText}
            </Link>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className="main-banner"
      style={{
        background: `url(${BottomLine}) no-repeat bottom, url(${
          banner || Bg
        }) no-repeat bottom`,
        backgroundSize: 'contain, cover',
      }}
    >
      <div className="container">
        <Slider {...SETTINGS} className="main-banner-slider">
          {renderItems()}
        </Slider>
      </div>
    </div>
  );
};

export default BannerSlider;
