import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FormView from './view';
import { AppState } from '../../../types';
import { getCart, getCartItems } from '../../../selectors/cart';
import {
  addShippingAddress as addShippingAddressAc,
  setSelectedCarrier as setSelectedCarrierAc,
  setSelectedPayment as setSelectedPaymentAc,
  submitReduxForm,
} from '../../../actions';
import {
  getAdresses,
  getCartSelectedAddressId,
  getCartSelectedCarrierId,
  getCartSelectedPaymentId,
  getIsConnected,
} from '../../../selectors';

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      submitForm: submitReduxForm,
      addShippingAddress: addShippingAddressAc,
      setSelectedPayment: setSelectedPaymentAc,
      setSelectedCarrier: setSelectedCarrierAc,
    },
    dispatch,
  );
};

const mapStateToProps = (appState: AppState) => {
  return {
    items: getCartItems(appState),
    cart: getCart(appState),
    isConnected: getIsConnected(appState),

    adresses: getAdresses(appState),
    selectedCarrierId: getCartSelectedCarrierId(appState),
    selectedPaymentId: getCartSelectedPaymentId(appState),
    selectedAddressId: getCartSelectedAddressId(appState),
    carriers: getCart(appState).carriers,
    paymentMethods: getCart(appState).paymentMethods,
  };
};

const ComposedForm = compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'CartForm',
    destroyOnUnmount: false,
  }),
)(FormView);

export default ComposedForm;
