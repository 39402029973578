import { Record } from 'immutable';
import { Offer } from '../types';

/**
 * Offer record
 *
 * @export
 * @class OfferRecord
 * @extends {Record<Offer>()}
 */
export class OfferRecord extends Record<Offer>(
  {
    id: 0,
    title: null,
    price: null,
    oldPrice: null,
    discount: null,
    isDefault: 0,
    qty: 0,
    soldOut: false,
  },
  'offer',
) {}
