import React, { useEffect, useState } from 'react';
import Icon from 'react-remixicon';
import logoStore from '../../images/logo-store.png';

export default function DownloadApp() {
  const [os, setOs] = useState(null);

  const GOOGLE_PLAY_URL =
    'https://play.google.com/store/apps/details?id=com.guichet.app';
  const APP_STORE_URL = 'https://apps.apple.com/fr/app/guichet/id1497007435';

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) setOs('android');
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      setOs('ios');
  }, []);

  if (os === 'android' || os === 'ios')
    return (
      <div className="download-app-menu">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="right d-flex align-items-center">
              <div>
                <img src={logoStore} />
              </div>
              <div>
                <span className="title">Application Guichet</span>
                <span className="subtitle">Gratuite sur votre store</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {os === 'android' && <a href={GOOGLE_PLAY_URL}>Ouvrir</a>}
              {os === 'ios' && <a href={APP_STORE_URL}>Ouvrir</a>}
              <button className="close-btn" onClick={() => setOs(null)}>
                <Icon name="close" type="line" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return null;
}
