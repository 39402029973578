import { Record } from 'immutable';
import { Order, OrdersListingForRec } from '../types';

/**
 * Order record
 *
 * @export
 * @class OrderRecord
 * @extends {Record<User>()}
 */
export class OrderRecord extends Record<Order>(
  {
    id: 0,
    reference: null,
    token: null,
    createdAt: null,
    status: {
      name: null,
      color: null,
    },
    offered: 0,
    offeredBy: null,
    totalProducts: 0,
    totalShipping: 0,
    totalDiscounts: 0,
    totalPaid: 0,
    paymentMethod: null,
    carrier: null,
    paymentReference: null,
    paymentTransaction: null,
    paymentTransactionDate: null,
    isValid: false,
    address: null,
    lines: null,
  },
  'order',
) {}

export class MyOrdersResponseRecord extends Record<OrdersListingForRec>(
  {
    list: null,
  },
  'orders-list',
) {}
