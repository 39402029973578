import { Record } from 'immutable';
import { EventProvider } from '../types';

/**
 * Event provider
 *
 * @export
 * @class EventProviderRecord
 * @extends {Record<EventProvider>()}
 */
export class EventProviderRecord extends Record<EventProvider>(
  {
    id: 0,
    title: null,
    address: null,
    phone: null,
    logo: null,
    logoWhite: null,
    domain: null,
    description: null,
    banner: null,
  },
  'event-provider',
) {}
