/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import ProductSlider from '../ProductSlider';
import ProductsSlider from '../ProductsSlider';
import SelectBox from '../SelectBox';
import { ProductRecord } from '../../models';
import { formatPrice, getCurrentCurrency } from '../../helpers';
import BuyNow from '../BuyNow';
import AppMeta from '../common/AppMeta';

interface ProductProps {
  product?: ProductRecord | null;
  addToCart?: any;
}

const SingleProduct = ({ product, addToCart }: ProductProps) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(1);
  const [combinationId, setCombinationId] = useState(
    product.get('combinations')?.attributes[0].id,
  );

  const combinations = product.get('combinations');

  useEffect(() => {
    if (product) {
      window.fbq('track', 'ViewContent', {
        content_type: 'product',
        content_name: product.get('title'),
        content_brand: product.get('campaign').get('brand').get('title'),
        content_category: product.get('term').get('title'),
      });
    }
  }, [product]);

  const onAddToCartSuccess = () => {
    const attribute = product
      .get('combinations')
      ?.attributes?.find(att => att.id === combinationId);
    toggleModal();
    window.fbq('track', 'AddToCart', {
      content_type: 'product',
      value: quantity * attribute?.price,
      currency: getCurrentCurrency(),
      content_category: product.get('term').get('title'),
      contents: [
        {
          id: product.get('id'),
          name: product.get('title'),
          quantity,
          item_price: attribute?.price || product.get('price'),
        },
      ],
    });
    // window.ttq.track('AddToCart', {
    //   content: [
    //     {
    //       content_id: product.get('id'),
    //       content_name: product.get('title'),
    //       content_type: 'product',
    //     },
    //   ],
    //   value: quantity * attribute?.price,
    //   currency: getCurrentCurrency(),
    // });
  };

  const addToCartFunc = () => {
    addToCart(
      quantity,
      null,
      onAddToCartSuccess,
      product.get('id'),
      combinationId,
    );
  };

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  return (
    <div className="single-product">
      <AppMeta
        title={product.get('metaTitle')}
        description={product.get('metaDescription')}
        keywords={product.get('metaKeywords')}
        prefix="Guichet Store"
      />

      {/* Acheter maintenant */}
      <BuyNow
        title={product.get('title')}
        img={product.get('images').get(0).path}
        price={product.get('price')}
        quantity={quantity}
        isOpened={modalOpened}
        closeFunc={toggleModal}
      />

      <div className="container">
        <div className="row">
          <div className="col-sm-5">
            {!!product.get('images') && (
              <ProductSlider images={product.get('images').toArray()} />
            )}
          </div>

          <div className="col-sm-7">
            <h3 className="title">{product.get('title')}</h3>

            <div className="price-block">
              <div>
                <div className="new-price">
                  {formatPrice(product.get('price'))}
                </div>
                {!!product.get('oldPrice') && (
                  <div className="old-price">
                    {formatPrice(product.get('oldPrice'))}
                  </div>
                )}
              </div>
              {!!product.get('oldPrice') && (
                <span className="per-price">-{product.get('discount')}%</span>
              )}
            </div>

            <p className="mt-3 mb-5">
              {/* Payez cet article en <b>3×</b> ou <b>4×</b> à partir de 100€ */}
            </p>

            {!!combinations && (
              <div className="mb-4">
                <h4 className="mb-3">Choisissez {combinations?.title}</h4>
                <div className="row">
                  <div className="col-5">
                    <SelectBox
                      items={combinations.attributes.map(att => ({
                        value: att.id,
                        label: att.name,
                      }))}
                      handleSelect={item => {
                        setCombinationId(Number.parseFloat(item.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="add-to-cart d-flex">
              <SelectBox
                items={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                  { value: '6', label: '6' },
                  { value: '7', label: '7' },
                  { value: '8', label: '8' },
                  { value: '9', label: '9' },
                  { value: '10', label: '10' },
                  { value: '11', label: '11' },
                  { value: '12', label: '12' },
                  { value: '13', label: '13' },
                  { value: '14', label: '14' },
                  { value: '15', label: '15' },
                ]}
                handleSelect={item =>
                  setQuantity(Number.parseFloat(item.value))
                }
              />
              <button className="ml-3 btn" onClick={addToCartFunc}>
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>

        <div className="description">
          <h3>
            <span>Description</span>
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: product.get('description') }}
          />
        </div>

        {!!product.get('similarProducts') && (
          <div className="description other-products">
            <h3 className="mb-4">
              <span>Autres articles :</span>
            </h3>

            <ProductsSlider products={product.get('similarProducts')} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleProduct;
