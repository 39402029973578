import React, { FunctionComponent } from 'react';
import { List } from 'immutable';
import StorePr from './StorePr';

import BlocHeader from './BlocHeader';
import { CampaignRecord } from '../models';

interface HomeCampaignsGridprops {
  currentHomeCampaigns?: List<CampaignRecord> | null;
}

const HomeCampaignsGrid: FunctionComponent<HomeCampaignsGridprops> = ({
  currentHomeCampaigns,
}: HomeCampaignsGridprops) => {
  if (!currentHomeCampaigns) return null;

  return (
    <section className="container">
      <BlocHeader
        title="Encore plus de nouveautés"
        subtitle="Toutes les ventes d'aujourd'hui"
        iconType="S"
      />
      <div className="row">
        {currentHomeCampaigns.map(camp => (
          <div
            className="col-lg-4 col-sm-12"
            key={`campaigns-item-${camp.get('id')}`}
          >
            <StorePr campaign={camp} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeCampaignsGrid;
