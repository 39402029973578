import React from 'react';
import Alert from './Alert';

interface MessageProps {
  success?: any;
  error?: any;
}

export default function Message({ success, error }: MessageProps) {
  if (error)
    return (
      <Alert
        title=""
        message={error.errorMessage}
        icon="warning"
        onClose={error.resetError}
      />
    );

  if (success)
    return (
      <Alert
        title=""
        message={success.successMessage}
        icon="success"
        onClose={success.resetSuccess}
      />
    );

  return null;
}
