import { bindActionCreators, Dispatch } from 'redux';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import Icon from 'react-remixicon';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CartBenefitsFieldArray, InputRadioField } from './common/fields';
import { AppState } from '../types';
import {
  updateCart as updateCartAc,
  deleteCartItem as deleteCartItemAc,
} from '../actions';
import { formatPrice } from '../helpers';

interface CartItemProps {
  field: any;
  item: any;
  updateCart?: any;
  deleteCartItem?: any;
  apply?: any;
  index: number;
}

const CartItem: FunctionComponent<CartItemProps> = ({
  field,
  item,
  updateCart,
  deleteCartItem,
  apply,
  index,
}: CartItemProps) => {
  const [quantity, setQuantity] = useState(item?.quantity || 1);

  useEffect(() => {
    let timeOutId;
    if (quantity !== item.quantity) {
      timeOutId = setTimeout(() => onChangeQuantity(), 650);
    }
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  if (!item) return null;

  const onChangeQuantity = () => {
    try {
      if (quantity) {
        const newQuantity = Number(quantity);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(newQuantity)) {
          setQuantity(item.quantity);
        } else if (newQuantity !== 0) {
          updateCart(item.itemId, 'up', newQuantity, index);
        }
      }
    } catch {
      setQuantity(item.quantity);
    }
  };

  const onDeleteItem = (itemId: number) => deleteCartItem(itemId, index);

  const renderSuggestionItem = suggItem => (
    <Link
      to={suggItem.type === 'event' ? suggItem.slug : `store/${suggItem.slug}`}
      className="mr-3 d-flex flex-column mt-4"
    >
      <img
        src={suggItem.image}
        style={{
          width: 146,
          height: 109,
          borderRadius: 12,
          objectFit: 'cover',
        }}
      />
      <label
        style={{
          textAlign: 'center',
          width: 146,
          color: '#212529',
          fontSize: 12,
          maxLines: 2,
        }}
        className="mt-2"
      >
        {suggItem.title}
      </label>
    </Link>
  );

  return (
    <div className="item" key={item.itemId}>
      <div className="row">
        <button className="close" onClick={() => onDeleteItem(item.itemId)}>
          <Icon name="close" type="fill" />
        </button>

        <div className="col-sm-3 mb-3">
          <div
            className="item-image"
            style={{
              backgroundImage: `url(${item.product.image})`,
              height: 109,
              backgroundSize: 'cover',
            }}
          />
          {/* <img className="img-fluid" src={item.product.image} /> */}
        </div>
        <div className="col-sm-9">
          <h4 style={{ marginBottom: 4 }}>{item.product.title}</h4>
          {item.attributes?.map(att => (
            <h6 style={{ color: 'grey' }}>{`${att.name} : ${att.value}`}</h6>
          ))}

          <div className="row">
            {/* Quantité */}
            <div className="col-3">
              {!item.product.isPlan && (
                <div className="mr-3 mb-2 g-input">
                  <label>Quantité</label>
                  <input
                    id="cart-quanity"
                    onChange={e => setQuantity(e.target.value)}
                    value={quantity}
                  />
                  {/* <select
                    value={item.quantity}
                    onChange={e => onChangeQuantity(e, item.itemId)}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select> */}
                </div>
              )}
            </div>

            {/* Total */}
            <div className="col-9">
              <div className="d-flex justify-content-end total text-right">
                <div className="mr-4">
                  <p>PPC:</p>
                  <p>Sous-total:</p>
                </div>
                <div>
                  <p>{formatPrice(item.product.price)}</p>
                  <p className="totalN">
                    {formatPrice(item.product.price * item.quantity)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {item.fields && item.fields.length > 0 && (
        <>
          {/* {item.quantity > 1 && (
            <div>
              <div className="mb-2">
                <label>Voulez-vous utiliser les mêmes informations ?</label>
                <Field
                  name={`${field}.apply`}
                  component={InputRadioField}
                  wrapAllClass="d-flex"
                  data={[
                    {
                      value: 'no',
                      label: 'Non',
                    },
                    {
                      value: 'yes',
                      label: 'Oui',
                    },
                  ]}
                />
              </div>
            </div>
          )} */}

          {/* Benefits */}
          <FieldArray
            name={`${field}.benefits`}
            item={item}
            component={CartBenefitsFieldArray}
            apply={apply}
          />
        </>
      )}

      {item.product?.eventId === 2361 && (
        <p className="pt-3 text-danger">
          En soumettant le formulaire d’inscription, vous acceptez le règlement
          de la course et bénéficiez d'un accès gratuit au salon AFRICA SPORTS
          EXPO pour récupérer vos dossards et maillots sur place
        </p>
      )}

      {item.suggestions && item.suggestions?.length !== 0 && (
        <div className="col mt-4 pl-0">
          <h5 className="mb-0 ml-0">RECOMMANDÉ POUR VOUS</h5>
          <div className="col">
            <div className="row">
              {item.suggestions?.map(renderSuggestionItem)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const selector = formValueSelector('CartForm');

const mapStateToProps = (appState: AppState, { field }) => {
  return {
    apply: selector(appState, `${field}.apply`),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return bindActionCreators(
    {
      updateCart: updateCartAc,
      deleteCartItem: deleteCartItemAc,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
