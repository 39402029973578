import React, { FunctionComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ProfilUpdateDispatchProps, ProfilUpdateStateProps } from './types';

import { AppState, userActionKeys } from '../../types';
import {
  updateProfil as updateProfilAC,
  resetProfilUpdate as resetProfilUpdateAC,
  resetProfilUpdateMessages as resetProfilUpdateMessagesAC,
} from '../../actions';
import {
  getProfilError,
  getProfilUpdateSuccessMessage,
  getUser,
  getProfilLoading,
} from '../../selectors';
import { ProfilUpdateForm } from '../../components/forms';
import Message from '../../components/common/Message';
import { simpleFetchResetMessages as simpleFetchResetMessagesAc } from '../../helpers';

interface ProfilUpdateProps
  extends ProfilUpdateDispatchProps,
    ProfilUpdateStateProps {}

const ProfilUpdate: FunctionComponent<ProfilUpdateProps> = ({
  loading,
  updateProfil,
  user,
  error,
  successMessage,
  simpleFetchResetMessages,
}: ProfilUpdateProps) => {
  return (
    <>
      <Message
        success={
          successMessage
            ? {
                successMessage,
                resetSuccess: () => {
                  simpleFetchResetMessages(userActionKeys.PROFIL_UPDATE);
                },
              }
            : null
        }
        error={
          error
            ? {
                errorMessage: error.error,
                resetError: () => {
                  simpleFetchResetMessages(userActionKeys.PROFIL_UPDATE);
                },
              }
            : null
        }
      />
      <ProfilUpdateForm
        user={user}
        onSubmit={data => updateProfil(data)}
        loading={loading}
      />
    </>
  );
};

/**
* ProfilUpdate map state to props
*
* @param {Dispatch} dispatch
@returns {ProfilUpdateDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): ProfilUpdateDispatchProps => {
  return bindActionCreators(
    {
      updateProfil: updateProfilAC,
      resetProfilUpdate: resetProfilUpdateAC,
      resetProfilUpdateMessages: resetProfilUpdateMessagesAC,
      simpleFetchResetMessages: simpleFetchResetMessagesAc,
    },
    dispatch,
  );
};

/**
 * ProfilUpdate map state to props
 *
 * @param {AppState} appState
 * @returns {ProfilUpdateStateProps}
 */
const mapStateToProps = (appState: AppState): ProfilUpdateStateProps => {
  return {
    loading: getProfilLoading(appState),
    error: getProfilError(appState),
    successMessage: getProfilUpdateSuccessMessage(appState),
    user: getUser(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilUpdate);
