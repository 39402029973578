import { createSelector } from 'reselect';
import { List } from 'immutable';
import { AppState, ThemeState } from '../types';
import { SimpleFetchStateRecord } from '../helpers';
import { ThemesResponseRecord, ThemeRecord } from '../models';
import { Error } from '../providers';

export const getThemeState = (appState: AppState) => appState.theme;

//* ************************* BEGIN EVENT CATEGORIES *************************** */

export const getThemesState = createSelector<
  AppState,
  any,
  SimpleFetchStateRecord<ThemesResponseRecord>
>([getThemeState], (themeState: ThemeState) => themeState.themesState);

export const getThemesLoading = createSelector<AppState, any, boolean>(
  [getThemesState],
  (themesState: SimpleFetchStateRecord<ThemesResponseRecord>) =>
    themesState.get('loading'),
);

export const getThemesError = createSelector<AppState, any, Error | null>(
  [getThemesState],
  (themesState: SimpleFetchStateRecord<ThemesResponseRecord>) =>
    themesState.get('error'),
);

export const getThemesList = createSelector<
  AppState,
  any,
  List<ThemeRecord> | undefined | null
>(
  [getThemesState],
  (themesState: SimpleFetchStateRecord<ThemesResponseRecord>) =>
    themesState.get('data')?.get('themes'),
);

//* ************************* END EVENT CATEGORIES *************************** */
