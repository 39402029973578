import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { EventRecord } from '../../../models';
import { fieldErrors, regexes } from '../constants';
import { ApiRoutes, callApi, Error } from '../../../providers';
import { Field } from './field';
import {
  STORAGE_TOKEN,
  getStorageData,
  removeStorageData,
  setStorageData,
} from '../../../helpers';
import { PaymentForm } from './payment';
import { CheckoutSteps, Order, PaymentMethod } from '../../../types';
import Message from '../../common/Message';
import { PaymentPicker } from './payment-picker';

const CHECKOUT_QUERY_KEY = 'youcan_checkout';
const ORDER_CHECK_QUERY_KEY = 'youcan_order_check';
const ORDER_REFERENCE = 'youcan_reference';
const INPUTS_STORAGE = 'youcan-inputs';

type FormValues = {
  quantity: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  promoCode: string;
};

type Props = {
  event: EventRecord;
  soldOut: boolean;
  offerId: number;
  onOrderValidated: (order: Order) => void;
  onInstructionValidated: (instruction: string) => void;
};

export default function Form({
  event,
  soldOut,
  offerId,
  onOrderValidated,
  onInstructionValidated,
}: Props) {
  const { search } = useLocation();

  const [payment, setPayment] = useState<PaymentMethod | null>();

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      quantity: 1,
    },
  });

  const callCheckout = async (values: FormValues) => {
    const reference = getStorageData(ORDER_REFERENCE);

    const response: any = await callApi({
      url: ApiRoutes.youcanCheckout,
      method: 'post',
      postData: {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone: values.phone,
        quantity: values.quantity,
        event_id: event.get('id'),
        offer_id: offerId,
        reference,
        source: 1,
        payment_method_id: payment.id,
        promotional_code: values.promoCode,
      },
      callGlobalError: false,
    });

    if (response.status) {
      throw response;
    }
    return response;
  };

  const checkoutMutation = useMutation<
    {
      order: Order;
      token: string;
      params: any;
      step: string;
      instruction: string;
    },
    Error,
    FormValues
  >(CHECKOUT_QUERY_KEY, callCheckout, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: resp => {
      const { order, token, step, instruction } = resp;
      if (step === CheckoutSteps.FIN) {
        onComleted(order, instruction);
      } else {
        setStorageData(ORDER_REFERENCE, order.reference);
      }
      setStorageData(STORAGE_TOKEN, token);
    },
    onError: err => {
      if (err?.data.order?.reference) {
        setStorageData(ORDER_REFERENCE, err?.data.order?.reference);
        setStorageData(STORAGE_TOKEN, err?.data.token);
      }
    },
  });

  const callCheckOrder = async () => {
    const reference = getStorageData(ORDER_REFERENCE);
    const token = getStorageData(STORAGE_TOKEN);

    const response: any = await callApi({
      url: ApiRoutes.ordersCheck,
      method: 'post',
      token,
      postData: {
        ORDER_ID: reference,
      },
      callGlobalError: false,
      secure: true,
    });

    if (response.status) {
      throw response;
    }
    return response;
  };

  const orderCheckMutation = useMutation<
    { order: Order; instruction: string },
    Error
  >(ORDER_CHECK_QUERY_KEY, callCheckOrder, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: resp => {
      const { order, instruction } = resp;
      onComleted(order, instruction);
    },
  });

  const onComleted = (order: Order, instruction: string) => {
    onOrderValidated(order);
    onInstructionValidated(instruction);
    removeStorageData(ORDER_REFERENCE);
    removeStorageData(INPUTS_STORAGE);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = (values: FormValues) => {
    setStorageData(INPUTS_STORAGE, values);
    checkoutMutation.mutate(values);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const reference = getStorageData(ORDER_REFERENCE);
    const RESPONSE_CODE = Number(params.get('RESPONSE_CODE'));
    const ORDER_ID = params.get('ORDER_ID');
    if (RESPONSE_CODE !== null && ORDER_ID !== null && reference) {
      orderCheckMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const inputsStorage = getStorageData(INPUTS_STORAGE, true);
    if (inputsStorage) {
      const { quantity, ...rest } = inputsStorage;
      resetForm({
        quantity: quantity || 1,
        ...rest,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row mb-3">
      {checkoutMutation.isSuccess && checkoutMutation.data?.params && (
        <PaymentForm paymentParams={checkoutMutation.data?.params} />
      )}
      <Message
        error={
          (checkoutMutation.isError && {
            errorMessage: checkoutMutation.error?.error,
          }) ||
          (orderCheckMutation.isError && {
            errorMessage: orderCheckMutation.error?.error,
          })
        }
      />
      {!soldOut && !event.get('isPlan') && !event.get('isSubscription') && (
        <>
          <div className="col-6 pr-0">
            <Field
              control={control}
              name="firstname"
              placeholder="Nom"
              rules={{
                required: {
                  message: fieldErrors.REQUIRED,
                  value: true,
                },
              }}
            />
          </div>
          <div className="col-6 pl-2">
            <Field
              control={control}
              name="lastname"
              placeholder="Prénom"
              rules={{
                required: {
                  message: fieldErrors.REQUIRED,
                  value: true,
                },
              }}
            />
          </div>
          <div className="col-6 mt-2 pr-0">
            <Field
              control={control}
              name="email"
              type="email"
              placeholder="Adresse e-mail"
              rules={{
                required: {
                  message: fieldErrors.REQUIRED,
                  value: true,
                },
                pattern: {
                  message: fieldErrors.EMAIL,
                  value: regexes.email,
                },
              }}
            />
          </div>
          <div className="col-6 mt-2 pl-2">
            <Field
              control={control}
              name="phone"
              type="tel"
              placeholder="Numéro de téléphone"
              rules={{
                required: {
                  message: fieldErrors.REQUIRED,
                  value: true,
                },
              }}
            />
          </div>

          <div className="col-12 mt-2">
            <div className="box mode-paiement pb-3">
              <div className="heading text-left">
                <h4>Code promo </h4>
              </div>
              <Field
                control={control}
                name="promoCode"
                placeholder="Veuillez sasir votre code promo ici"
              />
            </div>
          </div>

          <div className="col-12 mt-2">
            <PaymentPicker
              selectedPayment={payment}
              onSelectedPayment={setPayment}
              payments={event?.get('paymentMethods') || []}
            />
          </div>

          <div className="col-6 pr-0">
            <Field
              control={control}
              name="quantity"
              type="number"
              rules={{
                required: {
                  message: fieldErrors.REQUIRED,
                  value: true,
                },
              }}
            />
          </div>
          <div
            className={
              soldOut || event.get('isPlan') || event.get('isSubscription')
                ? 'col-12'
                : 'col-6 pl-2'
            }
          >
            <button
              disabled={
                (soldOut && !event.get('isSubscription')) ||
                checkoutMutation.isLoading ||
                orderCheckMutation.isLoading
              }
              className="i-button"
              onClick={handleSubmit(onSubmit)}
            >
              {(event.get('isSubscription') && 'Inscription') ||
                (soldOut && 'Guichet fermé') ||
                (event.get('id') === 2396 && 'Réserver mon invitation') ||
                (event.get('isPlan') && 'Acheter via plan') ||
                'Acheter maintenant'}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
