import React, { FunctionComponent, useEffect } from 'react';
import { Field } from 'redux-form';
import { required } from '../../../helpers';
import { InputTextField } from '../../common/fields';
import InputTextareaField from '../../common/fields/input-textarea-field';
import LoadingPopup from '../../common/LoadingPopup';

interface ContactProps {
  handleSubmit: (data: any) => void;
  initialize: any;
  user: any;
  isConnected: boolean;
  loading: boolean;
}

const Contact: FunctionComponent<ContactProps> = ({
  handleSubmit,
  initialize,
  user,
  isConnected,
  loading,
}: ContactProps) => {
  useEffect(() => {
    if (isConnected && user) {
      const { lastName, firstName, email, phone } = user;
      initialize({
        lastName,
        firstName,
        email,
        phone,
      });
    }
  }, [user, initialize, isConnected]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="form-container position-relative">
        <LoadingPopup loading={loading} />

        <div className="row">
          <Field
            name="lastName"
            component={InputTextField}
            type="text"
            label="Nom"
            placeholder="Votre nom"
            className="col-sm-6 g-input"
            isRequired
            validate={required}
          />

          <Field
            name="firstName"
            component={InputTextField}
            type="text"
            label="Prénom"
            placeholder="Votre prénom"
            className="col-sm-6 g-input"
            isRequired
            validate={required}
          />
        </div>
        <div className="row">
          <Field
            name="email"
            component={InputTextField}
            type="email"
            label="E-mail"
            placeholder="Votre adresse e-mail"
            className="col-sm-6 g-input"
            isRequired
            validate={required}
          />

          <Field
            name="phone"
            component={InputTextField}
            type="text"
            label="Téléphone"
            placeholder="Votre téléphone"
            className="col-sm-6 g-input"
            isRequired
            validate={required}
          />
        </div>

        <div className="row">
          <Field
            name="subject"
            component={InputTextField}
            type="text"
            label="Sujet"
            placeholder="Sujet de votre demande"
            className="col g-input"
            isRequired
            validate={required}
          />
        </div>
        <div className="row">
          <Field
            name="message"
            component={InputTextareaField}
            type="text"
            label="Message"
            placeholder="Votre message"
            cols={30}
            rows={4}
            className="col g-input"
            isRequired
            validate={required}
          />
        </div>

        <div>
          <button className="g-submit" type="submit">
            Envoyer le message
          </button>
        </div>
      </div>
    </form>
  );
};

export default Contact;
