import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { GSTORE_ROUTES, ROUTES_PATHS } from '../helpers';
import { DynamicComponent } from '../containers';

const GStoreApp = () => {
  const { path: storePath } = useRouteMatch();
  return (
    <Switch>
      {GSTORE_ROUTES.map(({ path, component }) => (
        <Route
          path={`${storePath}${path}`}
          key={`gstore-routes-${path}`}
          component={component}
          exact
        />
      ))}
      <Route path={ROUTES_PATHS.dynamicStore} component={DynamicComponent} />
    </Switch>
  );
};

export default GStoreApp;
