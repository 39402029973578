import { ActionCreator } from 'redux';
import {
  SimpleActions,
  simpleListingRequest,
  simpleListingReset,
  simpleListingResetMessages,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes, EventParams } from '../providers';
import {
  eventActionsKeys,
  DynamicEventRequestAction,
  DynamicEventRequestPayload,
  DynamicEventResetAction,
  DynamicEventLoadingStartAction,
  DynamicEventLoadingEndAction,
  DynamicEventRefreshingStartAction,
  DynamicEventRefreshingEndAction,
  DynamicEventFailedAction,
  DynamicEventFailedPayload,
  DynamicEventResetErrorAction,
  DynamicSetEventDetailsAction,
  DynamicSetEventDetailsPayload,
  DynamicSetEventsListAction,
  DynamicSetEventsListPayload,
} from '../types';

/**
 * Fetch events
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchEvents: ActionCreator<SimpleActions> = (
  categoryId?: number,
  loadMore?: boolean,
  page?: number,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.events,
    callGlobalError: false,
    refresh,
    params: { category_id: categoryId, page } as EventParams,
  };
  return simpleListingRequest(eventActionsKeys.EVENTS, confiApi, loadMore);
};

/**
 * Reset Events action creator
 *
 * @returns {SimpleActions}
 */
export const resetEvents: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleListingReset(eventActionsKeys.EVENTS);

/**
 * Reset Events messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetEventsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleListingResetMessages(eventActionsKeys.EVENTS);

/**
 * fetch past events
 *
 * @param {string} [category]
 * @param {number} [page]
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchPastEvents: ActionCreator<SimpleActions> = (
  categoryId?: number,
  page?: number,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.events,
    callGlobalError: false,
    refresh,
    params: { category_id: categoryId, page, status: 'expired' } as EventParams,
  };
  return simpleListingRequest(eventActionsKeys.PAST_EVENTS, confiApi);
};

/**
 * Reset past events
 *
 * @returns {SimpleActions}
 */
export const resetPastEvents: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleListingReset(eventActionsKeys.PAST_EVENTS);

/**
 * Reset past events messages
 *
 * @returns {SimpleActions}
 */
export const resetPastEventsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleListingResetMessages(eventActionsKeys.PAST_EVENTS);

/**
 * Fetch provider events
 *
 * @param {string} [providerSlug]
 * @param {boolean} [loadMore]
 * @param {number} [page]
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchProviderEvents: ActionCreator<SimpleActions> = (
  providerSlug?: string,
  loadMore?: boolean,
  page?: number,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.events,
    callGlobalError: false,
    refresh,
    params: { domain: providerSlug, page } as EventParams,
  };
  return simpleListingRequest(
    eventActionsKeys.PROVIDER_EVENTS,
    confiApi,
    loadMore,
  );
};

/**
 * Reset provider events action creator
 *
 * @returns {SimpleActions}
 */
export const resetProviderEvents: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleListingReset(eventActionsKeys.PROVIDER_EVENTS);

/**
 * Reset provider events messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetProviderEventsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleListingResetMessages(eventActionsKeys.PROVIDER_EVENTS);

/**
 * Fetch provider past events
 *
 * @param {string} [providerSlug]
 * @param {boolean} [loadMore]
 * @param {number} [page]
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchProviderPastEvents: ActionCreator<SimpleActions> = (
  providerSlug?: string,
  page?: number,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.events,
    callGlobalError: false,
    refresh,
    params: { domain: providerSlug, page, status: 'expired' } as EventParams,
  };
  return simpleListingRequest(eventActionsKeys.PROVIDER_PAST_EVENTS, confiApi);
};

/**
 * Reset provider past events action creator
 *
 * @returns {SimpleActions}
 */
export const resetProviderPastEvents: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleListingReset(eventActionsKeys.PROVIDER_PAST_EVENTS);

/**
 * Reset provider past events messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetProviderPastEventsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleListingResetMessages(eventActionsKeys.PROVIDER_PAST_EVENTS);

/**
 * Reset event
 *
 * @returns {SimpleActions}
 */
export const resetEvent: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(eventActionsKeys.EVENT);

/**
 * Reset event messages
 *
 * @returns {SimpleActions}
 */
export const resetEventMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(eventActionsKeys.EVENT);

/** ************************************************************************************* */
/** ******************************  BEGIN DYNAMIC EVENT ********************************* */
/** ************************************************************************************* */

/**
 * Reset dynamic event
 *
 * @returns {DynamicEventResetAction}
 */
export const resetDynamicEvent: ActionCreator<
  DynamicEventResetAction
> = (): DynamicEventResetAction => ({
  type: '@@event/DYANMIC_EVENT_RESET',
});

/**
 * Fetch dynamic event
 *
 * @param {DynamicEventRequestPayload} payload
 * @returns {DynamicEventRequestAction}
 */
export const fetchDynamicEvent: ActionCreator<DynamicEventRequestAction> = (
  payload: DynamicEventRequestPayload,
): DynamicEventRequestAction => ({
  type: '@@event/DYANMIC_EVENT_REQUEST',
  payload,
});

export const fetchPreviewEvent: ActionCreator<SimpleActions> = (
  eventId: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.events}/preview/${eventId}`,
    callGlobalError: false,
  };
  return simpleFetchRequest(eventActionsKeys.EVENT, confiApi);
};

export const fetchPrivateEvent: ActionCreator<SimpleActions> = (
  token: string,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.events}/private/${token}`,
    callGlobalError: false,
  };
  return simpleFetchRequest(eventActionsKeys.EVENT, confiApi);
};

/**
 * Dynamic event loading start
 *
 * @returns {DynamicEventLoadingStartAction}
 */
export const dynamicEventLoadingStart: ActionCreator<
  DynamicEventLoadingStartAction
> = (): DynamicEventLoadingStartAction => ({
  type: '@@event/DYANMIC_EVENT_LOADING_START',
});

/**
 * Dynamic event loading end
 *
 * @returns {DynamicEventLoadingEndAction}
 */
export const dynamicEventLoadingEnd: ActionCreator<
  DynamicEventLoadingEndAction
> = (): DynamicEventLoadingEndAction => ({
  type: '@@event/DYANMIC_EVENT_LOADING_END',
});

/**
 * Dynamic event refreshing start
 *
 * @returns {DynamicEventRefreshingStartAction}
 */
export const dynamicEventRefreshingStart: ActionCreator<
  DynamicEventRefreshingStartAction
> = (): DynamicEventRefreshingStartAction => ({
  type: '@@event/DYANMIC_EVENT_REFRESHING_START',
});

/**
 * Dynamic event refreshing end
 *
 * @returns {DynamicEventRefreshingEndAction}
 */
export const dynamicEventRefreshingEnd: ActionCreator<
  DynamicEventRefreshingEndAction
> = (): DynamicEventRefreshingEndAction => ({
  type: '@@event/DYANMIC_EVENT_REFRESHING_END',
});

/**
 * Dynamic set event details
 *
 * @param {DynamicSetEventDetailsPayload} payload
 * @returns {DynamicSetEventDetailsAction}
 */
export const dynamicSetEventDetails: ActionCreator<
  DynamicSetEventDetailsAction
> = (payload: DynamicSetEventDetailsPayload): DynamicSetEventDetailsAction => ({
  type: '@@event/DYANMIC_SET_EVENT_DETAIL',
  payload,
});

/**
 * Dynamic set events list
 *
 * @param {DynamicSetEventsListPayload} payload
 * @returns {DynamicSetEventsListAction}
 */
export const dynamicSetEventsList: ActionCreator<DynamicSetEventsListAction> = (
  payload: DynamicSetEventsListPayload,
): DynamicSetEventsListAction => ({
  type: '@@event/DYANMIC_SET_EVENTS_LIST',
  payload,
});

/**
 * Dynamic event failed
 *
 * @param {DynamicEventFailedPayload} payload
 * @returns {DynamicEventFailedAction}
 */
export const dynamicEventFailed: ActionCreator<DynamicEventFailedAction> = (
  payload: DynamicEventFailedPayload,
): DynamicEventFailedAction => ({
  type: '@@event/DYANMIC_EVENT_FAILED',
  payload,
});

/**
 * Reset dynamic event error
 *
 * @returns {DynamicEventResetErrorAction}
 */
export const resetDynamicEventError: ActionCreator<
  DynamicEventResetErrorAction
> = (): DynamicEventResetErrorAction => ({
  type: '@@event/DYANMIC_EVENT_RESET_ERROR',
});

/** ************************************************************************************* */
/** ******************************  END DYNAMIC EVENT ********************************* */
/** ************************************************************************************* */
