import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  HomeCampaignsGridDispatchProps,
  HomeCampaignsGridStateProps,
} from './types';
import HomeCampaignsGridC from '../../components/HomeCampaignsGrid';
import { AppState } from '../../types';
import {
  fetchHomeCampaigns as fetchHomeCampaignsAC,
  resetHomeCampaigns as resetHomeCampaignsAC,
  resetHomeCampaignsMessages as resetHomeCampaignsMessagesAC,
} from '../../actions';
import {
  getHomeCampaignsLoading,
  getHomeCampaignsError,
  getHomeCurrentCampaignsList,
} from '../../selectors';
import LoadingPage from '../../components/common/LoadingPage';

interface HomeCampaignsGridProps
  extends HomeCampaignsGridDispatchProps,
    HomeCampaignsGridStateProps {}

const HomeCampaignsGrid: FunctionComponent<HomeCampaignsGridProps> = ({
  loading,
  currentHomeCampaigns,

  fetchHomeCampaigns,
  resetHomeCampaigns,
}: HomeCampaignsGridProps) => {
  useEffect(() => {
    fetchHomeCampaigns();
    return () => {
      resetHomeCampaigns();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingPage loading={loading} />
      <HomeCampaignsGridC currentHomeCampaigns={currentHomeCampaigns} />
    </>
  );
};

/**
* HomeCampaignsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {HomeCampaignsGridDispatchProps}
*/
const mapDispatchToProps = (
  dispatch: Dispatch,
): HomeCampaignsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchHomeCampaigns: fetchHomeCampaignsAC,
      resetHomeCampaigns: resetHomeCampaignsAC,
      resetHomeCampaignsMessages: resetHomeCampaignsMessagesAC,
    },
    dispatch,
  );
};

/**
 * HomeCampaignsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {HomeCampaignsGridStateProps}
 */
const mapStateToProps = (appState: AppState): HomeCampaignsGridStateProps => {
  return {
    loading: getHomeCampaignsLoading(appState),
    error: getHomeCampaignsError(appState),
    currentHomeCampaigns: getHomeCurrentCampaignsList(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeCampaignsGrid);
