import { Record } from 'immutable';
import {
  SearchForRec,
  SearchResponseForRec,
  SearchsListingForRec,
} from '../types';

/**
 * Search record class
 *
 * @export
 * @class SearchRecord
 * @extends {Record<SearchForRec>()}
 */
export class SearchRecord extends Record<SearchForRec>(
  {
    id: 0,
    title: null,
    image: null,
    url: null,
    type: null,
  },
  'search',
) {}

/**
 * Searchs list record
 *
 * @export
 * @class SearchsListRecord
 * @extends {Record<SearchsListingForRec>()}
 */
export class SearchsListRecord extends Record<SearchsListingForRec>(
  {
    list: null,
    success: false,
    pagination: null,
    loadMore: false,
  },
  'searchs-list',
) {}

/**
 * Search details record details response
 *
 * @export
 * @class SearchResponseRecord
 * @extends {Record<SearchResponseForRec>()}
 */
export class SearchResponseRecord extends Record<SearchResponseForRec>(
  {
    success: false,
    result: null,
  },
  'search-response',
) {}
