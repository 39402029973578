/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import Icon from 'react-remixicon';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
// import TwitterLogin from './common/twitter-login';
import { SocialPlatform } from '../types';

interface SocialButtonsProps {
  socialSignIn: (
    token: string | null,
    platform: SocialPlatform,
    secretToken?: string,
  ) => void;
}

const {
  REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_GOOGLE_CLIENT_ID,
  // REACT_APP_TWITTER_CONSUMER_KEY,
  // REACT_APP_TWITTER_CONSUMER_SECRET,
} = process.env;

const SocialButtons: FunctionComponent<SocialButtonsProps> = ({
  socialSignIn,
}: SocialButtonsProps) => {
  const submitAuth = (
    token: string | null,
    platform: SocialPlatform,
    secreToken?: string,
  ) => {
    if (token) {
      socialSignIn(token, platform, secreToken);
    }
  };

  return (
    <ul className="g-social-media">
      <li>
        <FacebookLogin
          appId={REACT_APP_FACEBOOK_APP_ID}
          callback={data => {
            submitAuth(data?.accessToken, 'facebook');
          }}
          render={({ onClick }) => (
            <button className="facebook" onClick={onClick}>
              <Icon name="facebook" type="fill" /> Facebook
            </button>
          )}
        />
      </li>
      <li>
        <GoogleLogin
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          render={({ onClick }) => (
            <button className="google" onClick={onClick}>
              <Icon name="google" type="fill" /> Google+
            </button>
          )}
          onSuccess={(data: any) => {
            submitAuth(data?.accessToken, 'google');
          }}
        />
      </li>
      {/* <li>
        <TwitterLogin
          authCallback={(_, data) => {
            submitAuth(data?.oauth_token, 'twitter', data?.oauth_token_secret);
          }}
          consumerKey={REACT_APP_TWITTER_CONSUMER_KEY}
          consumerSecret={REACT_APP_TWITTER_CONSUMER_SECRET}
        >
          <button className="twitter">
            <Icon name="twitter" type="fill" /> twitter
          </button>
        </TwitterLogin>
      </li> */}
    </ul>
  );
};

export default SocialButtons;
