import React from 'react';
import { useStorageSource } from '../../helpers/hooks';

const WrapperRouter = ({ children }: any) => {
  useStorageSource();

  return <>{children}</>;
};

export default WrapperRouter;
