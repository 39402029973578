import { Record } from 'immutable';

/**
 * Page record class
 *
 * @export
 * @class PageRecord
 * @extends {Record<PageForRec>()}
 */
export class PageRecord extends Record<PageForRec>(
  {
    id: 0,
    title: null,
    content: null,
    createdAt: null,
    slug: null,
    metaTitle: null,
    metaDescription: null,
    metaKeywords: null,
  },
  'page',
) {}

export interface PageForRec {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
}

export interface Page {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
}

export const serializePage = ({
  id,
  title,
  content,
  createdAt,
  slug,
  metaTitle,
  metaDescription,
  metaKeywords,
  ...rest
}: Page): PageRecord => {
  const newPage = new PageRecord({
    id,
    title,
    content,
    createdAt,
    slug,
    metaTitle,
    metaDescription,
    metaKeywords,
    ...rest,
  });
  return newPage;
};
