/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import Icon from 'react-remixicon';
import { AnyFielProps } from '../../../types';

interface InputImageFieldProps extends AnyFielProps {
  data: any[];
}

const InputImageField: FunctionComponent = ({
  input: { onChange, onFocus, onBlur, value },
  meta: { error, touched, valid },
  label,
  className,
  ...rest
}: InputImageFieldProps) => {
  return (
    <div className={className || 'col-12 mt-3'}>
      <button className="custom-image-upload">
        {!value ? (
          <Icon name="image" type="line" />
        ) : (
          <div
            className="img"
            style={{ backgroundImage: `url(${value.uri})` }}
          />
        )}
        {label}
        <input
          type="file"
          accept="image/*"
          onChange={e => {
            const file = e.target.files[0];
            const { type, name } = file;

            const reader = new FileReader();
            reader.onload = r => {
              onChange({
                uri: r.target.result,
                type,
                name,
              });
            };
            reader.readAsDataURL(file);
          }}
          onClick={() => {
            onFocus();
            onBlur();
          }}
          {...rest}
        />
      </button>
      {!valid && touched && !!error && (
        <p className="text-danger mt-1">{error}</p>
      )}
    </div>
  );
};

export default InputImageField;
