/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { Field } from 'redux-form';
import { InputTextField } from '../../common/fields';

interface SignUpFormViewProps {
  handleSubmit: (formData: any) => void;
}

const SignUpFormView: FunctionComponent<SignUpFormViewProps> = ({
  handleSubmit,
}: SignUpFormViewProps) => {
  return (
    <div className="contact-from">
      <form noValidate onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <Field
              component={InputTextField}
              name="lastName"
              isRequired
              id="in-lastName"
              type="text"
              placeholder="Votre nom"
              className="col-sm-6 g-input"
            />
            <Field
              component={InputTextField}
              name="firstName"
              isRequired
              id="in-firstName"
              type="text"
              placeholder="Votre prénom"
              className="col-sm-6 g-input"
            />
          </div>
          <div className="row">
            <Field
              component={InputTextField}
              name="email"
              isRequired
              id="in-email-register"
              type="email"
              placeholder="Votre adresse e-mail"
              className="col-sm-6 g-input"
            />
            <Field
              component={InputTextField}
              name="phone"
              isRequired
              id="in-phone"
              type="tel"
              placeholder="Votre téléphone"
              className="col-sm-6 g-input"
            />
          </div>

          <div className="row mb-2">
            <Field
              component={InputTextField}
              name="password"
              isRequired
              id="in-password"
              type="password"
              placeholder="Mot de passe"
              className="col-sm-6 g-input"
            />
            <Field
              component={InputTextField}
              name="confirmPassword"
              isRequired
              id="in-confirmPassword"
              type="password"
              placeholder="Confirmer le mot de passe"
              className="col-sm-6 g-input"
            />
          </div>

          <input className="g-submit blue" type="submit" value="Inscription" />
        </div>
      </form>
    </div>
  );
};

export default SignUpFormView;
