import { ActionCreator } from 'redux';
import {
  SimpleActions,
  simpleListingRequest,
  simpleListingResetMessages,
} from '../helpers';
import { ApiRoutes, ConfigCallApi, SearchParams } from '../providers';
import { searchActionKeys } from '../types';

/**
 * Fetch search items
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchSearch: ActionCreator<SimpleActions> = (
  searchQuery?: string,
  page?: number,
  loadMore?: boolean,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: `${ApiRoutes.dynamicComponent}/${ApiRoutes.search}`,
    callGlobalError: false,
    refresh,
    method: 'POST',
    params: { q: searchQuery, page } as SearchParams,
  };
  return simpleListingRequest(searchActionKeys.SEARCH, confiApi, loadMore);
};

/**
 * Reset Search messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetSearchMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleListingResetMessages(searchActionKeys.SEARCH);
