import { reduxForm, FormErrors } from 'redux-form';
import FormView from './view';
import { formsNames, fieldErrors } from '../constants';
import { RepaymentFormData, RepaymentFormProps } from '../types';

export const validate = ({
  fullName,
  amount,
  phone,
}: RepaymentFormData): FormErrors<RepaymentFormData> => {
  const errors: FormErrors<RepaymentFormData> = {};
  if (!fullName || fullName.trim() === '') {
    errors.fullName = fieldErrors.REQUIRED;
  }
  if (!amount || amount.trim() === '') {
    errors.amount = fieldErrors.REQUIRED;
  }
  if (!phone || phone.trim() === '') {
    errors.phone = fieldErrors.REQUIRED;
  }
  return errors;
};

const SignInForm = reduxForm<RepaymentFormData, RepaymentFormProps>({
  form: formsNames.SIGN_IN,
  validate,
})(FormView);

export default SignInForm;
