import { ActionCreator } from 'redux';
import { SliderParams, ApiRoutes, ConfigCallApi } from '../providers';

import {
  simpleFetchRequest,
  SimpleActions,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { sliderActionsKeys, sliderType } from '../types';

/**
 * Fetch SliderData action creator
 *
 * @param {boolean} [refresh]
 * @returns {SimpleActions}
 */
export const fetchSliderData: ActionCreator<SimpleActions> = (
  category?: string,
  type?: sliderType,
  refresh?: boolean,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.slider,
    callGlobalError: false,
    refresh,
    params: {
      category,
      type,
    } as SliderParams,
  };
  return simpleFetchRequest(sliderActionsKeys.SLIDER, confiApi);
};

/**
 * Reset SliderData action creator
 *
 * @returns {SimpleActions}
 */
export const resetSliderData: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchReset(sliderActionsKeys.SLIDER);

/**
 * Reset SliderData messages action creator
 *
 * @returns {SimpleActions}
 */
export const resetSliderDataMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchResetMessages(sliderActionsKeys.SLIDER);
