import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { saveStorageSource } from '../utils';

export const useStorageSource = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get('source');

  useEffect(() => {
    if (source) {
      saveStorageSource(source);
    }
  }, [source]);

  return null;
};
