import React, { FunctionComponent, useEffect } from 'react';
import { Field } from 'redux-form';
import * as QueryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { InputTextField } from '../../common/fields';
import { SignInFormProps } from '../types';

interface LoginViewProps extends SignInFormProps {
  handleSubmit: (formData: any) => void;
  initialize: (data: any) => void;
}

const RecoverPassView: FunctionComponent<LoginViewProps> = ({
  handleSubmit,
  initialize,
}: LoginViewProps) => {
  const { token }: any = useParams();
  const { search } = useLocation();
  const { email } = QueryString.parse(search);

  useEffect(() => {
    initialize({
      email,
      token,
    });
  }, [email, token]);

  return (
    <div className="contact-from">
      <form noValidate onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <Field
              component={InputTextField}
              name="email"
              isRequired
              id="in-email-recover"
              type="email"
              placeholder="Votre adresse e-mail"
              className="col-12 g-input"
            />
            <Field
              component={InputTextField}
              name="password"
              isRequired
              id="in-password"
              type="password"
              placeholder="Mot de passe"
              className="col-12 g-input"
            />
            <Field
              component={InputTextField}
              name="confirmPassword"
              isRequired
              id="in-confirmPassword"
              type="password"
              placeholder="Confirmer le mot de passe"
              className="col-12 g-input"
            />
            <Field
              component={InputTextField}
              name="token"
              isRequired
              id="in-token"
              type="hidden"
              className="col-12 g-input"
            />
          </div>

          <input className="g-submit" type="submit" value="Réinitialiser" />
        </div>
      </form>
    </div>
  );
};
export default RecoverPassView;
