import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface NoMatchedRouteProps {
  authLoading?: boolean;
}

const NoMatchedRoute: FunctionComponent<NoMatchedRouteProps> = () => {
  return (
    <div className="not-found-page text-center">
      <div className="container">
        <div className="p404">404</div>
        <h1>Page non trouvée</h1>
        <p>La page que vous avez demandée n'existe pas.</p>
        <Link className="btn" to="/">
          Retourner à la page d'accueil
        </Link>
      </div>
    </div>
  );
};

export default NoMatchedRoute;
