/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../types';
import { PrivateEventDispatchProps, PrivateEventStateProps } from './types';
import EventDetailsC from '../../components/pages/Event';
import LoadingPage from '../../components/common/LoadingPage';
import { getEventData, getEventError, getEventLoading } from '../../selectors';
import {
  addToCart as addToCartAc,
  fetchPrivateEvent as fetchPrivateEventAc,
  destroyCart as destroyCartAC,
} from '../../actions';
import AppMeta from '../../components/common/AppMeta';

const scriptFb =
  '!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "866144620107720");fbq("track", "PageView");';

const noScriptFb =
  '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=866144620107720&ev=PageView&noscript=1"/>';

const scriptFb2 =
  '!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init", "524467412464762");fbq("track", "PageView");';

const noScriptFb2 =
  '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=524467412464762&ev=PageView&noscript=1"/>';

interface PrivateEventProps
  extends PrivateEventDispatchProps,
    PrivateEventStateProps {}

const PrivateEvent: FunctionComponent<PrivateEventProps> = ({
  addToCart,
  fetchPrivateEvent,
  eventDetails,
  loading,
  error,
  destroyCart,
}: PrivateEventProps) => {
  const { token } = useParams<any>();

  useEffect(() => {
    fetchPrivateEvent(token);
  }, []);

  useEffect(() => {
    const sElement = document.createElement('script');
    sElement.setAttribute('id', 'fbScript');
    const noSElement = document.createElement('noscript');
    sElement.setAttribute('id', 'fbNoScript');

    if (eventDetails?.get('id') === 1660 || eventDetails?.get('id') === 1661) {
      sElement.type = 'text/javascript';
      sElement.async = true;
      sElement.innerHTML = scriptFb;

      noSElement.innerHTML = noScriptFb;
      
      document.head.appendChild(sElement);
      document.head.appendChild(noSElement);
    } else if (eventDetails?.get('id') === 1658 || eventDetails?.get('id') === 1752) {
      sElement.type = 'text/javascript';
      sElement.async = true;
      sElement.innerHTML = scriptFb2;

      noSElement.innerHTML = noScriptFb2;
      
      document.head.appendChild(sElement);
      document.head.appendChild(noSElement);
    }

    return () => {
      if (eventDetails) {
        const scriptEl = document.getElementById('fbScript');
        const noScriptEl = document.getElementById('fbNoScript');
        if (scriptEl) document.head.removeChild(scriptEl);
        if (scriptEl) document.head.removeChild(noScriptEl);
      }
    }
  }, [eventDetails]);
  

  return (
    <>
      <LoadingPage loading={loading} />
      <AppMeta
        title={eventDetails?.get('metaTitle')}
        description={eventDetails?.get('metaDescription')}
        keywords={eventDetails?.get('metaKeywords')}
        hideFromRobots
      />
      <EventDetailsC
        addToCart={addToCart}
        event={eventDetails}
        deleteSeat={null}
        loading={loading}
        error={error}
        destroyCart={destroyCart}
      />
    </>
  );
};

/**
* PrivateEvent map state to props
*
* @param {Dispatch} dispatch
@returns {PrivateEventDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): PrivateEventDispatchProps => {
  return bindActionCreators(
    {
      addToCart: addToCartAc,
      fetchPrivateEvent: fetchPrivateEventAc,
      destroyCart: destroyCartAC,
    },
    dispatch,
  );
};

/**
 * PrivateEvent map state to props
 *
 * @param {AppState} appState
 * @returns {PrivateEventStateProps}
 */
const mapStateToProps = (appState: AppState): PrivateEventStateProps => {
  return {
    eventDetails: getEventData(appState),
    loading: getEventLoading(appState),
    error: getEventError(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateEvent);
