import { Reducer, combineReducers } from 'redux';
import { CampaignState } from '../types';
import { createSimpleFetchStore } from '../helpers';
import { CampaignsRecord } from '../models';
import { campaignActionsKeys } from '../types/app/campaign/actions-types';
import { serializeCampaigns } from '../providers';

const eventReducer: Reducer<CampaignState> = combineReducers<CampaignState>({
  campaignsState: createSimpleFetchStore<CampaignsRecord>(
    campaignActionsKeys.CAMPAIGNS,
    serializeCampaigns,
  ).reducer,
  homeCampaignsState: createSimpleFetchStore<CampaignsRecord>(
    campaignActionsKeys.HOME_COMPAIGNS,
    serializeCampaigns,
  ).reducer,
});

export default eventReducer;
