/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BlocHeader from '../BlocHeader';
import { ContactForm } from '../forms';
import AppMeta from '../common/AppMeta';

const Contact = ({ submitContact }: any) => {
  return (
    <div>
      <AppMeta title="Contactez-nous" prefix="" />

      {/* <section className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.0243954375787!2d-7.639025084926174!3d33.57871584998243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2c13c5f3071%3A0xdd36a7b86305194a!2s2%20Boulevard%20Yacoub%20El%20Mansour%2C%20Casablanca%2C%20Morocco!5e0!3m2!1sen!2sus!4v1610885619398!5m2!1sen!2sus"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen={false}
          aria-hidden={false}
          //   tabIndex={0}
        />
      </section> */}
      <section className="container mt-5">
        <BlocHeader title="Formulaire de contact" iconType="G" />
        <div className="contact-from">
          <div className="contact-from-header mb-5">
            <h3>Vos données seront en sécurité !</h3>
            <p>
              N'hésitez pas à nous contacter en envoyant un email via le
              formulaire ci-dessous ou par téléphone sur le +212 6 45 765 765.
              Nos conseillers sont à l'écoute !
            </p>
          </div>

          <ContactForm onSubmit={submitContact} />
        </div>
      </section>
    </div>
  );
};

export default Contact;
