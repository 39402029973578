import { Category, Theme } from '../types';

/**
 * Regular expressions
 */
export const regularExpresions = {
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  FUL_NAME: /^[\w ]{1,20} [\w ]{1,20}$/,
  PHONE: /^[0-9]{1,50}$/,
};

export const STORAGE_TOKEN = '@@guichetToken';
export const STORAGE_COUNTRY = '@@guichetCountry';
export const STORAGE_COUNTRIES = '@@guichetCountries';
export const STORAGE_IS_CONNECTED = '@@guichetIsConnected';
export const STORAGE_USER = '@@guichetUser';

export const SEATSIOS_WORKSPACE = process.env.REACT_APP_SEATSIO_WORKSPACE;
export const SEATSIOS_SECRET_KEY = process.env.REACT_APP_SEATSIO_SECRET_KEY;

export const FAKE_CATEGOIRES: Category[] = [
  {
    id: 1,
    title: 'Concerts & Festivals',
    slug: 'concerts-festivals',
  },
  {
    id: 2,
    title: 'Théâtre & Humour',
    slug: 'theatre-humour',
  },
  {
    id: 3,
    title: 'Formations',
    slug: 'formations',
  },
  /* {
    id: 4,
    title: 'Salons et congrès',
    slug: 'salons-et-congres',
  }, */
  {
    id: 5,
    title: 'Famille & Loisirs',
    slug: 'famille-loisirs',
  },
  {
    id: 6,
    title: 'Sport',
    slug: 'sport',
  },
  {
    id: 10,
    title: 'Cinéma',
    slug: 'cinema',
  },
];

export const FAKE_THEMES: Theme[] = [
  {
    id: 2,
    title: 'Coffrets Cadeaux',
    slug: 'coffrets-cadeaux',
  },
  {
    id: 3,
    title: 'Bon plan',
    slug: 'bon-plan',
  },
  {
    id: 4,
    title: 'Sport',
    slug: 'sport',
  },
  {
    id: 5,
    title: 'Gaming',
    slug: 'gaming',
  },
  {
    id: 6,
    title: 'High-Tech',
    slug: 'high-tech',
  },
  {
    id: 7,
    title: 'Produits dérivées',
    slug: 'produits-derivees',
  },
];

export const YOUCAN_EVENT_IDS = [2886, 2909];
export const YOUCAN_PATHNAMES = [
  '/formations/17-janvier-youcan-event-casablanca',
  '/events/private/62fd44d14de11cb0b4f41cd2719464e1a81dfd24',
  '/events/private/396c72bdaebd64932d51a767e6f02a9d52c37e35',
  '/formations/17-janvier-youcan-event-casablanca-vip',
];
