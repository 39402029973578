/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LoadingPopup from '../common/LoadingPopup';
import RecoverPassForm from '../forms/sign-in/recover-password';

interface RecoverPassCProps {
  onSubmit: (data: any) => void;
  loading: boolean;
}

const RecoverPassC = ({ onSubmit, loading }: RecoverPassCProps) => {
  return (
    <div className="box">
      <LoadingPopup loading={loading} />
      <div className="heading mb-4">
        <h4>Réinitialiser le mot de passe</h4>
        <p>Veuillez renseigner les informations ci-dessous</p>
      </div>

      <RecoverPassForm onSubmit={onSubmit} />
    </div>
  );
};

export default RecoverPassC;
