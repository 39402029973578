import React, { FunctionComponent, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CampaignsGridDispatchProps, CampaignsGridStateProps } from './types';
import CampaignsGridC from '../../components/CampaignsGrid';
import { AppState } from '../../types';
import {
  fetchCampaigns as fetchCampaignsAC,
  resetCampaigns as resetCampaignsAC,
  resetCampaignsMessages as resetCampaignsMessagesAC,
} from '../../actions';
import {
  getCampaignsLoading,
  getCampaignsError,
  getCurrentCampaignsList,
  getFutureCampaignsList,
} from '../../selectors';

interface CampaignsGridProps
  extends CampaignsGridDispatchProps,
    CampaignsGridStateProps {
  themeId?: number;
}

const CampaignsGrid: FunctionComponent<CampaignsGridProps> = ({
  themeId,
  currentCampaigns,
  futureCampaigns,

  fetchCampaigns,
  resetCampaigns,
}: CampaignsGridProps) => {
  useEffect(() => {
    fetchCampaigns(themeId);
    return () => {
      resetCampaigns();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CampaignsGridC
      currentCampaigns={currentCampaigns}
      futureCampaigns={futureCampaigns}
    />
  );
};

/**
* CampaignsGrid map state to props
*
* @param {Dispatch} dispatch
@returns {CampaignsGridDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): CampaignsGridDispatchProps => {
  return bindActionCreators(
    {
      fetchCampaigns: fetchCampaignsAC,
      resetCampaigns: resetCampaignsAC,
      resetCampaignsMessages: resetCampaignsMessagesAC,
    },
    dispatch,
  );
};

/**
 * CampaignsGrid map state to props
 *
 * @param {AppState} appState
 * @returns {CampaignsGridStateProps}
 */
const mapStateToProps = (appState: AppState): CampaignsGridStateProps => {
  return {
    loading: getCampaignsLoading(appState),
    error: getCampaignsError(appState),
    currentCampaigns: getCurrentCampaignsList(appState),
    futureCampaigns: getFutureCampaignsList(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsGrid);
