import { Action } from 'redux';

export const cartActions = {
  CART_RESET: '@@cart/CART_RESET',
  CART_REQUEST: '@@cart/CART_REQUEST',
  CART_LOADING_START: '@@cart/CART_LOADING_START',
  CART_LOADING_END: '@@cart/CART_LOADING_END',
  CART_LOADING_PAGE_START: '@@cart/CART_LOADING_PAGE_START',
  CART_LOADING_PAGE_END: '@@cart/CART_LOADING_PAGE_END',
  CART_REFRESHING_START: '@@cart/CART_REFRESHING_START',
  CART_REFRESHING_END: '@@cart/CART_REFRESHING_END',
  SET_CART_DATA: '@@cart/SET_CART_DATA',
  CART_FAILED: '@@cart/CART_FAILED',
  CART_SUCCESS: '@@cart/CART_SUCCESS',
  CART_RESET_ERROR: '@@cart/CART_RESET_ERROR',
  CART_ITEM_ADDED: '@@cart/CART_ITEM_ADDED',
  CART_ITEM_UPDATED: '@@cart/CART_ITEM_UPDATED',
  CART_ITEM_DELETED: '@@cart/CART_ITEM_DELETED',
  CART_ITEM_SEAT_DELETE: '@@cart/CART_ITEM_SEAT_DELETE',
  CART_DESTROYED: '@@cart/CART_DESTROYED',

  APPLY_DISCOUNT: '@@cart/APPLY_DISCOUNT',
  CLEAR_DISCOUNT: '@@cart/CLEAR_DISCOUNT',
  GET_COUNTRIES: '@@cart/GET_COUNTRIES',
  SET_COUNTRIES: '@@cart/SET_COUNTRIES',
  GET_CITIES: '@@cart/GET_CITIES',
  SET_CITIES: '@@cart/SET_CITIES',
  ADD_SHIPPING_ADDRESSES: '@@cart/ADD_SHIPPING_ADDRESSES',
  SET_SELECTED_ADDRESS: '@@cart/SET_SELECTED_ADDRESS',
  CHECKOUT: '@@cart/CHECKOUT',
  FINALIZATION: '@@cart/FINALIZATION',
  ORDERSCHECK: '@@cart/ORDERSCHECK',
  SET_PAYMENT_INFO: '@@cart/SET_PAYMENT_INFO',
  SET_INSTRUCTION: '@@cart/SET_INSTRUCTION',

  SER_SELECTED_CARRIER_ID: '@@cart/SER_SELECTED_CARRIER_ID',
  SET_SELECTED_PAYMENT_ID: '@@cart/SET_SELECTED_PAYMENT_ID',
};

export const cartActionKeys = {
  CART: 'CART',
  ADD_TO_CART: 'ADD_TO_CART',
  UPDATE_CART: 'UPDATE_CART',
  DELETE_CART_IEM: 'DELETE_CART_IEM',
};

export const cartManagementActions = {
  CHANGE_STEP: '@@cart_management/CHANGE_STEP',
  DYANMIC_CART_REQUEST: '@@cart_management/CART_REQUEST',
};

export type CartManagementActionTypes = '@@cart_management/CHANGE_STEP';

/**
 * Chnage cart step payload
 *
 * @export
 * @interface ChangeCartStepPlayload
 */
export interface ChangeCartStepPlayload {
  step: number;
}

/**
 * Set Payment Info payload
 *
 * @export
 * @interface SetPaymentInfoPlayload
 */
export interface SetPaymentInfoPlayload {
  fpayUrl: any;
  orderId: any;
  referenceId: any;
  trackId: any;
}

/**
 *Set instruction payload
 *
 * @export
 * @interface SetInstructionPlayload
 */
export interface SetInstructionPlayload {
  instruction: string;
}

/**
 * Set selected address payload
 *
 * @export
 * @interface SetSelectedAddressPayload
 */
export interface SetSelectedAddressPayload {
  delivery_address_id: number;
}

/**
 * Change cart step action
 *
 * @export
 * @interface ChangeCartStepAction
 * @extends {Action<CartManagementActionTypes>}
 */
export interface ChangeCartStepAction
  extends Action<CartManagementActionTypes> {
  type: '@@cart_management/CHANGE_STEP';
  payload: ChangeCartStepPlayload;
}

export type CartManagementActions = ChangeCartStepAction;

export type CartManagementPayloads = ChangeCartStepPlayload;
