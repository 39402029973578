import { GlobalResponse } from './types';
import { GlobalResponseRecord } from './models';

/**
 * Serialize global response
 *
 * @param {GlobalResponse} globalResponse
 * @returns {GlobalResponseRecord}
 */
export const serializeGlobalResponse = (
  globalResponse: GlobalResponse,
): GlobalResponseRecord => {
  return new GlobalResponseRecord(globalResponse);
};
