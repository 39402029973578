import React, { FunctionComponent } from 'react';
import { List } from 'immutable';
import { EventRecord } from '../models';
import Card from './Card';
import BlocHeader from './BlocHeader';

interface EventsGridProps {
  events?: List<EventRecord> | null;
  hasHeader?: boolean;
}

const EventsGrid: FunctionComponent<EventsGridProps> = ({
  events,
  hasHeader = true,
}: EventsGridProps) => {
  if (!events || events?.size <= 0) {
    return null;
  }

  return (
    <>
      <section className="container mt-5 mb-5">
        {hasHeader && (
          <BlocHeader
            title="À ne pas manquer"
            subtitle="Agenda exceptionnel"
            iconType="G"
          />
        )}
        <div className="row">
          {events.toArray().map(event => (
            <div
              className="col-lg-3 col-md-6"
              key={`events-item-${event.get('id')}`}
            >
              <Card event={event} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default EventsGrid;
