import { Record } from 'immutable';
import {
  CartManagement,
  CartItemForRec,
  CartResponseForRec,
  CartForRec,
  CartData,
} from '../types';

/**
 * Cart record
 *
 * @export
 * @class CartRecord
 * @extends {Record<Cart>()}
 */
export class CartRecord extends Record<CartForRec>(
  {
    carriers: null,
    discount: null,
    count: 0,
    subTotal: null,
    total: null,
    totalShipping: null,
    items: null,
    paymentMethods: null,
    totalDiscount: null,
    totalDiscountString: null,
    hasPlan: false,
  },
  'cart',
) {}

/**
 * Cart data record
 *
 * @export
 * @class CartManagementRecord
 * @extends {Record<CartManagement>()}
 */
export class CartDataRecord extends Record<CartData>(
  {
    cart: null,
    loading: false,
    loadingPage: false,
    error: null,
    successMessage: null,
    countries: null,
    cities: null,
  },
  'cart-data',
) {}

/**
 * Cart management record
 *
 * @export
 * @class CartManagementRecord
 * @extends {Record<CartManagement>()}
 */
export class CartManagementRecord extends Record<CartManagement>(
  {
    step: 1,
    selectedAddressId: null,
    paymentInfo: null,
    instruction: null,

    selectedCarrierId: null,
    selectedPaymentId: null,
  },
  'cart-management',
) {}

/**
 * Cart item record
 *
 * @export
 * @class CartItemRecord
 * @extends {Record<CartItemForRec>()}
 */
export class CartItemRecord extends Record<CartItemForRec>(
  {
    itemId: null,
    quantity: 0,
    total: null,
    fields: null,
    product: {
      id: null,
      title: null,
      image: null,
      price: null,
      seatsChartId: null,
      isPlan: false,
      type: null,
    },
    attributes: null,
    suggestions: null,
    multiplier: 1,
  },
  'cart-item',
) {
  [x: string]: any;
}

/**
 * Cart response record
 *
 * @export
 * @class CartResponseRecord
 * @extends {Record<CartResponseForRec>()}
 */
export class CartResponseRecord extends Record<CartResponseForRec>(
  {
    success: false,
    message: null,
    cart: null,
  },
  'cart-response',
) {}
