import React, { FunctionComponent, useEffect } from 'react';
import { MagazineGrid } from '../../containers';
import AppMeta from '../common/AppMeta';

interface MagazinePageProps {
  topic: any;
}

const MagazinePage: FunctionComponent<MagazinePageProps> = ({
  topic,
}: MagazinePageProps) => {
  useEffect(() => {
    if (topic) {
      window.fbq('track', 'ViewContent', {
        content_type: 'topic',
        content_name: topic.name,
      });
    }
  }, [topic]);

  return (
    <>
      <AppMeta
        title="Actualités des festivals et recommandations"
        prefix="Guichet Magazine"
      />
      <MagazineGrid topic={topic} />
    </>
  );
};

export default MagazinePage;
