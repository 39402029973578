import { Reducer } from 'redux';
import { handleActions, Action, createAction } from 'redux-actions';
import { CartDataRecord, CartRecord } from '../../models';
import { cartActions } from '../../types';

const createReducer = (): Reducer<CartDataRecord, any> => {
  const setCartData = createAction<any>(cartActions.SET_CART_DATA).toString();
  const destroyCart = createAction<any>(cartActions.CART_DESTROYED).toString();
  const setCountries = createAction<any>(cartActions.SET_COUNTRIES).toString();
  const setCities = createAction<any>(cartActions.SET_CITIES).toString();

  const cartLoadingStart = createAction<any>(
    cartActions.CART_LOADING_START,
  ).toString();
  const cartLoadingEnd = createAction<any>(
    cartActions.CART_LOADING_END,
  ).toString();
  const cartFailed = createAction<any>(cartActions.CART_FAILED).toString();
  const cartSuccess = createAction<any>(cartActions.CART_SUCCESS).toString();

  const cartLoadingPageStart = createAction<any>(
    cartActions.CART_LOADING_PAGE_START,
  ).toString();
  const cartLoadingPageEnd = createAction<any>(
    cartActions.CART_LOADING_PAGE_END,
  ).toString();

  const newCart = new CartDataRecord({
    cart: new CartRecord(),
    loading: false,
    error: null,
    successMessage: null,
    countries: null,
    cities: null,
  });

  const reducer: Reducer<CartDataRecord, any> = handleActions<
    CartDataRecord,
    any
  >(
    {
      [setCartData]: (state: CartDataRecord, { payload }: Action<any>) => {
        return state.set('cart', new CartRecord(payload));
      },
      [destroyCart]: () => {
        return newCart;
      },
      [setCountries]: (state: CartDataRecord, { payload }: Action<any>) => {
        return state.set('countries', payload);
      },
      [setCities]: (state: CartDataRecord, { payload }: Action<any>) => {
        return state.set('cities', payload);
      },
      [cartLoadingStart]: (state: CartDataRecord) => state.set('loading', true),
      [cartLoadingEnd]: (state: CartDataRecord) => state.set('loading', false),
      [cartFailed]: (state: CartDataRecord, { payload }: Action<any>) =>
        state.set('error', payload),
      [cartSuccess]: (state: CartDataRecord, { payload }: Action<any>) =>
        state.set('successMessage', payload),

      [cartLoadingPageStart]: (state: CartDataRecord) =>
        state.set('loadingPage', true),
      [cartLoadingPageEnd]: (state: CartDataRecord) =>
        state.set('loadingPage', false),
    },
    newCart,
  );

  return reducer;
};

export default createReducer();
