import React from 'react';
import AppMeta from '../common/AppMeta';
import { CartConfirmation } from '../../containers';

export default function CartConfirmationPage() {
  return (
    <>
      <AppMeta title="Confirmation du panier" prefix="" />
      <CartConfirmation />
    </>
  );
}
