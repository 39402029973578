import { createSelector } from 'reselect';
import { SimpleFetchStateRecord, GlobalResponseRecord } from '../helpers';
import { AppState } from '../types';
import { Error } from '../providers';

export const getContactState = (
  appState: AppState,
): SimpleFetchStateRecord<GlobalResponseRecord> => appState.contact;

//* ************************* BEGIN SLIDER DATA *************************** */

export const getContactLoading = createSelector<AppState, any, boolean>(
  [getContactState],
  (contactState: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    contactState.get('loading'),
);

export const getContactRefreshing = createSelector<AppState, any, boolean>(
  [getContactState],
  (contactState: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    contactState.get('refreshing'),
);

export const getContactSuccess = createSelector<
  AppState,
  any,
  boolean | undefined
>([getContactState], (contact: SimpleFetchStateRecord<GlobalResponseRecord>) =>
  contact.get('data')?.get('success'),
);

export const getContactSuccessMessage = createSelector<
  AppState,
  any,
  string | undefined | null
>([getContactState], (contact: SimpleFetchStateRecord<GlobalResponseRecord>) =>
  contact.get('successMessage'),
);

export const getContactError = createSelector<AppState, any, Error | null>(
  [getContactState],
  (contactState: SimpleFetchStateRecord<GlobalResponseRecord>) =>
    contactState.get('error'),
);

//* ************************* END SLIDER DATA *************************** */
