import { ActionCreator } from 'redux';
import {
  SimpleActions,
  simpleFetchRequest,
  simpleFetchReset,
  simpleFetchResetMessages,
} from '../helpers';
import { ConfigCallApi, ApiRoutes, CampaignsParams } from '../providers';
import { campaignActionsKeys } from '../types/app/campaign/actions-types';

/**
 * Fetch Campaigns action creator
 *
 * @param {boolean} [refresh]
 * returns {SimpleActions}
 */
export const fetchCampaigns: ActionCreator<SimpleActions> = (
  themeId?: number,
): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.campaigns,
    callGlobalError: false,
    params: {
      theme_id: themeId,
    } as CampaignsParams,
  };
  return simpleFetchRequest(campaignActionsKeys.CAMPAIGNS, confiApi);
};

/**
 * Reset Campaigns action creator
 *
 * returns {SimpleActions}
 */
export const resetCampaigns: ActionCreator<SimpleActions> = (): SimpleActions =>
  simpleFetchReset(campaignActionsKeys.CAMPAIGNS);

/**
 * Reset Campaigns messages action creator
 *
 * returns {SimpleActions}
 */
export const resetCampaignsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(campaignActionsKeys.CAMPAIGNS);

/**
 * Fetch Home Campaigns action creator
 *
 * @param {boolean} [refresh]
 * returns {SimpleActions}
 */
export const fetchHomeCampaigns: ActionCreator<
  SimpleActions
> = (): SimpleActions => {
  const confiApi: ConfigCallApi = {
    url: ApiRoutes.campaigns,
    callGlobalError: false,
  };
  return simpleFetchRequest(campaignActionsKeys.HOME_COMPAIGNS, confiApi);
};

/**
 * Reset Home Campaigns action creator
 *
 * returns {SimpleActions}
 */
export const resetHomeCampaigns: ActionCreator<
  SimpleActions
> = (): SimpleActions => simpleFetchReset(campaignActionsKeys.HOME_COMPAIGNS);

/**
 * Reset Home Campaigns messages action creator
 *
 * returns {SimpleActions}
 */
export const resetHomeCampaignsMessages: ActionCreator<
  SimpleActions
> = (): SimpleActions =>
  simpleFetchResetMessages(campaignActionsKeys.HOME_COMPAIGNS);
